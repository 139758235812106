import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

import { axios } from 'configurations/axios';
import { useSessionStore } from 'session/useSessionStore';
import { LineType } from 'features/seismic-3d/models/enums/LineType';
import { usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IErrorResponse } from 'features/seismic/models/interfaces/IErrorResponse';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { useSnackbarStore } from 'components/geopostSnackbar/store/useSnackbarStore';
import { CacheSystem } from 'features/seismic/models/enums/CacheSystem';

export type PrepareTilesRenderRequest = {
    lineType: LineType,
    lineNumber: number,
    volumeToken: string,
    surveyLineStart: number,
    surveyLineEnd: number,
    lineStartSample: number,
    lineEndSample: number,
    skipTraceCount: number,
    sampleInterval: number,
    filePath: string,
    byteSize: number,
    volumeSamplesPerTrace: number,
    lineIncrement: number,
    traceRangeStart: number,
    traceRangeEnd: number,
    parallelProcesses: number,
    surveyInlineIncrement: number,
    surveyXlineIncrement: number,
    isIndexedVolume: boolean,
    surveyInlineEnd: number,
    surveyInlineStart: number,
    surveyXlineStart: number,
    surveyXlineEnd: number,
    inlineDimensionName: string,
    xlineDimensionName: string,
    sampleDimensionName: string,
    cdpDimensionName: string,
    prepareLineStart: number,
    prepareLineEnd: number,
    cacheSystem: CacheSystem,
    storeGainCache: boolean
};

export type SamplesReference = {
    MinSampleValue: number,
    MaxSampleValue: number
};

export const usePrepareTilesRender = () => {
    const host = useSessionStore(state => state.tenantConfiguration?.endpoints.render);
    const addPrepareRenderPerformanceData = usePerformanceMonitoringStore(state => state.addPrepareRenderPerformanceData);

    const { showSnackbar } = useSnackbarStore(state => ({
        showSnackbar: state.showSnackbar
    }));
    const urlParams = getParamsFromUrl();
    return useMutation({
        mutationFn: async (data: PrepareTilesRenderRequest) : Promise<SamplesReference> => {
            const response = await axios.post<SamplesReference>(
                host + '/seismic/render/prepareRender',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    'axios-retry': {
                        retries: 3,
                        retryCondition: () => true
                    }
                }
            );

            if (urlParams.performanceMonitoring){
                const getSampleTime = response.headers['X-Gp-Debug-Api-Get-Sample-Time'.toLowerCase()];
                const getGenerateCacheTime = response.headers['X-Gp-Debug-Api-Get-Generate-Cache-Time'.toLowerCase()];
                const note = response.headers['X-Gp-Debug-Api-Get-Generate-Note'.toLowerCase()];

                addPrepareRenderPerformanceData({
                    getGenerateCacheTime: getGenerateCacheTime,
                    getSampleTime: getSampleTime,
                    notes: note,
                    error: '-'
                });
            }

            return response.data;
        },
        retry: 3,
        onError: (error) => {
            let errorMessage = 'Error on get samples.';

            if (urlParams.performanceMonitoring){
                let errorMessagePrepareRender = '';

                let getSampleTime = '';
                let getGenerateCacheTime = '';
                let note = '';

                if (error instanceof AxiosError) {
                    const axiosError = error as AxiosError<IResponseAPI<IErrorResponse>>;

                    if (axiosError.response && axiosError.response.data && axiosError.response.data.Result){
                        getSampleTime = axiosError.response.headers['X-Gp-Debug-Api-Get-Sample-Time'.toLowerCase()];
                        getGenerateCacheTime = axiosError.response.headers['X-Gp-Debug-Api-Get-Generate-Cache-Time'.toLowerCase()];
                        note = axiosError.response.headers['X-Gp-Debug-Api-Get-Generate-Note'.toLowerCase()];

                        const errorResponse = axiosError.response.data.Result;
                        errorMessagePrepareRender = `${errorResponse['ErrorName']} - ${errorResponse.Error} - ${errorResponse.Traceback}`;

                        const messageLower = errorResponse.Error.toLowerCase();
                        if (messageLower.indexOf('discern access pattern') > -1 || messageLower.indexOf('permission error') > -1){
                            errorMessage = errorResponse.Error;
                        }
                    }
                }
                if (!errorMessagePrepareRender) {
                    let commonError = error as Error;
                    let stack = (commonError.stack)?` - ${commonError.stack}`:'';
                    errorMessagePrepareRender = commonError.message + stack;
                }

                addPrepareRenderPerformanceData({
                    getGenerateCacheTime: getGenerateCacheTime,
                    getSampleTime: getSampleTime,
                    notes: note,
                    error: errorMessagePrepareRender
                });
            }

            showSnackbar('error', errorMessage);
        }
    });
};