import { css, Paper, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import React from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';

import { useAPI } from 'hooks/useAPI';
import { useSeismicStore } from '../stores/useSeismicStore';
import { useMapStore } from '../stores/useMapStore';
import { ApiType } from 'models/enums/ApiType';

export function TraceHeaderTable() {
    const { surveyMetadata, rangeTrace } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata,
        rangeTrace: state.rangeTrace
    }));
    const { map } = useMapStore(state => ({
        map: state.map
    }));
    const [lastTrace, setlastTrace] = useState(0);
    const { execute, data } = useAPI<TraceHeader[]>('/Seismic/SeismicTraces/GetTracesHeaders');
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const rowVirtualizer = useVirtualizer({
        count: (data) ? data.length : 0,
        horizontal: true,
        getScrollElement: () => tableContainerRef.current,
        estimateSize: () => 150,
    });

    const theme = useTheme();

    const styles = css({
        '& .row:first-child': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    });
    //backgroundColor: (index === 0 ? '#cae8ca' : ''),

    useEffect(() => {
        const samplesBytePerTrace = surveyMetadata ? surveyMetadata.Header.SamplesPerTrace * surveyMetadata.Header.ByteSize : 0;

        execute({
            apiType: ApiType.SeismicApi,
            params: {
                minTrace: rangeTrace.initial,
                maxTrace: rangeTrace.end,
                samplesBytePerTrace: samplesBytePerTrace,
                path: surveyMetadata?.VolumePath,
            }
        });
    }, [rangeTrace.initial, rangeTrace.end]);

    useEffect(() => {
        if (map) {
            map.onMouseMove((x, y) => {
                setlastTrace(parseInt(x.toString()));
                scrollTo(x, y);
            });
        }
    }, [map?.geopostKey, rangeTrace.initial, rangeTrace.end]);

    const rows = getRowsWithoutZero(data, rangeTrace.initial, rangeTrace.end);
    function scrollTo(x: number, y: number) {
        const totalTraces = (rangeTrace.end - rangeTrace.initial);
        const cellSize = rowVirtualizer.getTotalSize() / totalTraces;
        let elementsPerRow = 6;
        if (tableContainerRef && tableContainerRef.current) {
            elementsPerRow = tableContainerRef.current?.clientWidth / cellSize;
            const rowInitialPosition = cellSize;
            const middleRowOffset = (elementsPerRow / 2) * cellSize;
            const p = (x * rowInitialPosition - middleRowOffset) - rangeTrace.initial * cellSize;
            tableContainerRef.current?.scrollTo(p, y);
        }
    }

    return (
        <div css={styles}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <div
                    ref={tableContainerRef}
                    style={{
                        height: `400px`,
                        overflow: 'auto',
                    }}
                >
                    {rows.map((x, index) =>
                        <div
                            className='row'
                            key={index}
                            style={{
                                width: `${rowVirtualizer.getTotalSize()}px`,
                                height: 20,
                                position: (index === 0 ? 'sticky' : 'relative'),
                                top: (index === 0 ? 0 : ''),
                                zIndex: (index === 0 ? 1 : ''),
                                padding: (index === 0 ? 10 : ''),
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                alignItems: 'center'
                            }}
                        >
                            {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                                const isSelected = (index === 0 && lastTrace.toString() === x[virtualItem.index]);
                                return (
                                    <div
                                        className='cell'
                                        key={virtualItem.key}
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            width: `${virtualItem.size}px`,
                                            transform: `translateX(${virtualItem.start}px)`,
                                            backgroundColor: isSelected ? theme.palette.secondary.main : '',
                                            color: isSelected ? theme.palette.secondary.contrastText : 'inherit'
                                        }}
                                    >

                                        {index === 0 ? <Typography variant='h6'>{virtualItem.index === 0 ? 'Trace#' : x[virtualItem.index]}</Typography> : x[virtualItem.index]}

                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

            </Paper>
        </div>);
}

function getRowsWithoutZero(data: TraceHeader[] | null, rangeTraceStart: number, rangeTraceEnd: number) {
    const numberTraceArray = ['Trace#', ...Array.from({ length: (rangeTraceEnd - rangeTraceStart) + 1 }, (_, i) => (i + rangeTraceStart).toString())];
    const rows: string[][] = [numberTraceArray];
    if (data) {
        const keys = Object.keys(data[0].Headers);
        keys.forEach((key, idx) => {
            let row: string[] = [];
            row.push(key);
            data.forEach(trace => {
                row.push(trace.Headers[key]);
            });
            let zeroCount = 0;
            for (let i = 1; i < row.length; i++) {
                if (Number(row[i]) === 0) {
                    zeroCount++;
                }
            }
            if (zeroCount !== row.length - 1) {
                rows.push(row);
            }
        });
    }
    return rows;
}

type TraceHeader = {
    StartTraceByte: number,
    EndTraceByte: number,
    TraceNumber: number,
    Headers: { [key: string]: string }
};