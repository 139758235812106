import { Box, Button, css, TextField, Tooltip, Zoom } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { createRef, Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import alertify from 'alertifyjs';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';

import { ISubRule } from '../interfaces/ITaxonomy';
import { useDeleteDataSubGroup, useDeleteDataSubRule, useGetAllDataGroups, useGetDataSubGroupAndChildGroupsByDataGroupToken, useGetDataSubRuleBySubGroupToken, useGetOneDataSubGroup, useGetOneDataSubRule } from '../useTaxonomy';
import { RegexRuleForm } from './RegexRuleForm';
import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';

const style = css({
    width: '100% !important',
    margin: '0 !important',
    '& .table-header': {
        flexDirection: 'column',
        marginBottom: '16px',
        alignItems: 'flex-start !important',
        boxShadow: 'none !important',
        border: 'none !important',
        padding: '0px !important',
    },
    '& .search': {
        height: '40px',
        padding: '0px !important',
    },
});

export const RegexRuleTable = ({ setOpenModal, refetchTableData, headerTitle, dataToken }: Props) => {
    const [openRuleModal, setOpenRuleModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<ISubRule | null>(null);

    const [editToken, setEditToken] = useState<string>('');
    const [deleteToken, setDeleteToken] = useState<string>('');

    const [filteredData, setFilteredData] = useState<ISubRule[]>([]);
    const [filter, setFilter] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const [pagination] = useState<{ pageSize: number, page: number; }>({
        pageSize: 10,
        page: 0,
    });

    const { data: subRuleData, isLoading: subRuleDataLoading, refetch } = useGetDataSubRuleBySubGroupToken(dataToken);
    const { data: subRuleGetByToken } = useGetOneDataSubRule(editToken);
    const { data: deleteSubRuleData } = useDeleteDataSubRule(deleteToken);

    const handleOpenModal = () => setOpenRuleModal(true);
    const handleCloseModal = () => setOpenRuleModal(false);

    const handleOpenEdit = (rowData: ISubRule) => {
        setEditToken(rowData.Token);
    };

    const handleDelete = (name: string, token: string) => {
        alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
            setDeleteToken(token);
        });
    };

    // reseta as informações preenchidas no modal
    useEffect(() => {
        if (!openRuleModal) {
            setModalData(null);
        }
    }, [openRuleModal]);

    // seta as informações para edit
    useEffect(() => {
        if (subRuleGetByToken) {
            setModalData(subRuleGetByToken);
            handleOpenModal();
            setEditToken('');
        }
    }, [subRuleGetByToken]);

    // salva as informações de todas as linhas recebidas
    useEffect(() => {
        if (subRuleData) {
            setFilteredData(subRuleData);
        }
    }, [subRuleData]);

    // realiza o filtro
    useEffect(() => {
        if (subRuleData) {
            if (filter === '') {
                setFilteredData(subRuleData);
            }
            else {
                setFilteredData(subRuleData.filter(x => x.Rule?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Priority?.toString() === filter.toLowerCase()));
            }
        }
    }, [filter]);

    // deleta o contract
    useEffect(() => {
        if (deleteSubRuleData === 'Success') {
            refetch();
        }
    }, [deleteSubRuleData]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'Rule',
            headerAlign: 'left',
            headerName: 'Rule Name',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Priority',
            headerAlign: 'left',
            headerName: 'Priority',
            minWidth: 120,
            flex: 0.5,
        },
        {
            field: 'Options',
            headerName: 'Options',
            headerAlign: 'center',
            sortable: false,
            minWidth: 180,
            flex: 1,
            renderCell: (params) => (
                <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Tooltip title='Edit rule' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='primary' className='edit-icon' onClick={() => handleOpenEdit(params.row)}>
                            <EditNoteIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Delete rule' placement='top' arrow TransitionComponent={Zoom}>
                        <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Rule, params.row.Token)}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            ),
        },
    ];

    const getRowId = (row: ISubRule) => row.Token;

    return (
        <Fragment>
            <Box css={[style, MainTableCss]}>
                <Box className='table-header' style={{ flexDirection: 'column' }}>
                    <Box className='search'>
                        <TextField label='Search Rule' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        <Button variant='contained' color='primary' className='add-icon' onClick={() => { handleOpenModal() }}>
                            <AddIcon />
                        </Button>
                    </Box>
                </Box>
                <GeopostTable<ISubRule> filteredData={filteredData} loading={subRuleDataLoading || loading} columns={columns} getRowId={getRowId} pagination={pagination} />
            </Box>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openRuleModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={410}
                height={395}
                padding={'16px'}
                title={modalData ? `Edit ${modalData.Rule} Rule` : 'Add a new Rule'}
            >
                <RegexRuleForm setOpenModal={setOpenRuleModal} refetchTableData={refetch} setLoading={setLoading} formData={modalData} dataSubGroupToken={dataToken} />
            </GeopostPopup>
        </Fragment>
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    headerTitle: string;
    dataToken: string;
}