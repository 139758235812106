import Axios, { AxiosError, AxiosResponse } from 'axios';
import alertify from 'alertifyjs';

import { useSessionStore } from 'session/useSessionStore';

const axios = Axios.create({});

axios.interceptors.request.use((config) => {
    // Do something before request is sent
    /*const token = window.localStorage.getItem('token') //do not store token on localstorage!!!
    config.headers.Authorization = token
    return config */
    //console.log('request legal');
    const jwt = useSessionStore.getState().jwtToken;
    if (!!jwt && jwt !== ''){
        config.headers.Authorization = 'Bearer ' + jwt;
    }
    return config;
}, (error) => {
    console.log('asasas');
    if (error) {
        // Do something with request error
        return Promise.reject(error);
    }
}
);

axios.interceptors.response.use((config) => {
    // Do something before request is sent
    /*const token = window.localStorage.getItem('token') //do not store token on localstorage!!!
    config.headers.Authorization = token
    return config */
    if (config.status === 404) {
        //console.log('response 404');
    }
    //console.log('response request', config);

    return config;
}, (error: AxiosError) => {
    if (error.response?.status === 401) {
        const isOpen = alertify.alert().isOpen();

        if (isOpen){
            return;
        }

        alertify.alert('Viewer', 'You aren\'t authenticated', () => {
            const host = useSessionStore.getState().tenantConfiguration?.parentApplicationHost;

            if (host){
                window.location.href = host;
            }
        });

        return;
    }

    console.error('Error', error);

    //parentApplicationHost
    //console.log('deu erro', error);
    // Do something with request error
    return Promise.reject(error);
}
);

export { axios };