import { useQuery } from 'react-query';
import { GridSortModel } from '@mui/x-data-grid';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IExportPathOptions, IFile, IIndexFiltered, IMapObjectsFromBucketByExtensions, ISendFilter } from './interfaces/IFiles';
import { useSessionStore } from 'session/useSessionStore';

export function useGetCountTable(sendFilters: ISendFilter | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getCountTable' + sendFilters?.LocationType + sendFilters?.Filter + sendFilters?.EntityTypeFilter.id + sendFilters?.DataGroupFilter + sendFilters?.DataSubGroupFilter + sendFilters?.TransmittalFilter + sendFilters?.WithoutSubgroupFilter + sendFilters?.WithoutEntitiesFilter + sendFilters?.TagFilter + sendFilters?.MediaFilter + sendFilters?.DataSourceFilter, async () => {
        const response = await axios.request<IResponseAPI<number>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/FilesData/GetCountTable',
            params: {
                locationType: sendFilters?.LocationType,
                name: sendFilters?.Filter,
                entityName: sendFilters?.EntityTypeFilter.id,
                groupToken: sendFilters?.DataGroupFilter,
                subGroupToken: sendFilters?.DataSubGroupFilter,
                transmittalToken: sendFilters?.TransmittalFilter,
                withoutSubGroup: sendFilters?.WithoutSubgroupFilter,
                withoutEntity: sendFilters?.WithoutEntitiesFilter,
                tag: sendFilters?.TagFilter,
                mediaToken: sendFilters?.MediaFilter,
                dataSourceToken: sendFilters?.DataSourceFilter
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
    });
}

export function useGetFileList(sendFilters: ISendFilter | null, limit: number, page: number, sort: GridSortModel) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    let sortField = sort[0].field;
    let descending = sort[0].sort === 'desc' ? true : false;

    if (sortField === 'ReceivedDate') {
        sortField = 'receivedDate';
    }
    else if (sortField === 'TransmittalName') {
        sortField = 'TransmittalName';
    }
    else if (sortField === 'SubGroupName') {
        sortField = 'subgroup';
    }
    else if (sortField === 'MediaName') {
        sortField = 'media';
    }
    else if (sortField === 'DataEntityName') {
        sortField = 'entity';
    }
    else if (sortField === 'Tags') {
        sortField = 'tag';
    }
    else if (sortField === 'Size') {
        sortField = 'size';
    }
    else {
        sortField = 'name';
    }

    return useQuery('getListTable' + sendFilters?.LocationType + sendFilters?.Filter + sendFilters?.EntityTypeFilter.id + sendFilters?.DataGroupFilter + sendFilters?.DataSubGroupFilter + sendFilters?.TransmittalFilter + sendFilters?.WithoutSubgroupFilter + sendFilters?.WithoutEntitiesFilter + sendFilters?.TagFilter + sendFilters?.MediaFilter + sendFilters?.DataSourceFilter + limit + page + sortField + descending, async () => {
        const response = await axios.request<IResponseAPI<IFile[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/FilesData/GetListTable',
            params: {
                locationType: sendFilters?.LocationType,
                name: sendFilters?.Filter,
                entityName: sendFilters?.EntityTypeFilter.id,
                groupToken: sendFilters?.DataGroupFilter,
                subGroupToken: sendFilters?.DataSubGroupFilter,
                transmittalToken: sendFilters?.TransmittalFilter,
                withoutSubGroup: sendFilters?.WithoutSubgroupFilter,
                withoutEntity: sendFilters?.WithoutEntitiesFilter,
                tag: sendFilters?.TagFilter,
                mediaToken: sendFilters?.MediaFilter,
                dataSourceToken: sendFilters?.DataSourceFilter,
                limit,
                page,
                sortField,
                descending,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: sendFilters !== null,
        cacheTime: 0,
    });
}

export function useGetIndexedFiles(sendFilters: ISendFilter, limit: number = 0) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getIndexedFiles' + sendFilters.LocationType + sendFilters.Filter + sendFilters.EntityTypeFilter.id + sendFilters.DataGroupFilter + sendFilters.DataSubGroupFilter + sendFilters.TransmittalFilter + sendFilters.WithoutSubgroupFilter + sendFilters.WithoutEntitiesFilter + sendFilters.TagFilter + sendFilters.MediaFilter + sendFilters.DataSourceFilter, async () => {
        const response = await axios.request<IResponseAPI<IIndexFiltered>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/FilesData/GetIndexedFiles',
            params: {
                locationType: sendFilters.LocationType,
                name: sendFilters.Filter,
                entityName: sendFilters.EntityTypeFilter.id,
                groupToken: sendFilters.DataGroupFilter,
                subGroupToken: sendFilters.DataSubGroupFilter,
                transmittalToken: sendFilters.TransmittalFilter,
                withoutSubGroup: sendFilters.WithoutSubgroupFilter,
                withoutEntity: sendFilters.WithoutEntitiesFilter,
                dataSourceToken: sendFilters.DataSourceFilter,
                limit: limit,
                tag: sendFilters.TagFilter,
                mediaToken: sendFilters.MediaFilter,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useReIndexFiles(reindexType: number, sendFilters: ISendFilter, connectionId: string, forceReindex: boolean, send: boolean, limit: number = 0) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('reIndexFiles' + reindexType + sendFilters.LocationType + sendFilters.Filter + sendFilters.EntityTypeFilter.id + sendFilters.DataGroupFilter +
        sendFilters.DataSubGroupFilter + sendFilters.TransmittalFilter + sendFilters.WithoutSubgroupFilter + sendFilters.WithoutEntitiesFilter + sendFilters.TagFilter +
        sendFilters.MediaFilter + sendFilters.DataSourceFilter + connectionId + forceReindex, async () => {
        const response = await axios.request<IResponseAPI<boolean>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/FilesData/ReIndexFiles',
            params: {
                reindexType,
                locationType: sendFilters.LocationType,
                name: sendFilters.Filter,
                entityName: sendFilters.EntityTypeFilter.id,
                groupToken: sendFilters.DataGroupFilter,
                subGroupToken: sendFilters.DataSubGroupFilter,
                transmittalToken: sendFilters.TransmittalFilter,
                withoutSubGroup: sendFilters.WithoutSubgroupFilter,
                withoutEntity: sendFilters.WithoutEntitiesFilter,
                dataSourceToken: sendFilters.DataSourceFilter,
                limit,
                connectionId,
                tag: sendFilters.TagFilter,
                mediaToken: sendFilters.MediaFilter,
                forceReindex,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: send,
        cacheTime: 0,
    });
}

export function useSave(data: IFile | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    console.log(data);

    return useQuery('save' + data?.Token + data?.SubgroupToken + data?.TransmittalToken + data?.MediaToken, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/FilesData/SaveFile',
            data: {
                Token: data?.Token,
                SubgroupToken: data?.SubgroupToken,
                TransmittalToken: data?.TransmittalToken,
                MediaToken: data?.MediaToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: data !== null,
        cacheTime: 0,
    });
}

export function useGetFileByToken(token: string = '') {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getFileByToken' + token, async () => {
        const response = await axios.request<IResponseAPI<IFile>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/FilesData/GetFileByToken',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

// export function getFileByEntity(token, subgroupToken) {
//   return request.get('FilesData/GetFilesByEntity', {
//     params: {
//       token,
//       subgroupToken,
//     },
//   });
// }

export function useDeleteFileByToken(fileToken: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteFileByToken' + fileToken, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/FilesData/DeleteFile',
            data: {
                Token: fileToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: fileToken !== null && fileToken !== undefined && fileToken !== '',
        cacheTime: 0,
    });
}

// export function useGetExportPathOptions(projectToken: string = 'name') {
//     const { tenantConfig, jwtToken } = useSessionStore(state => ({
//         tenantConfig: state.tenantConfiguration,
//         jwtToken: state.jwtToken
//     }));

//     return useQuery('getExportPathOptions' + projectToken, async () => {
//         const response = await axios.request<IResponseAPI<IExportPathOptions[]>>({
//             method: 'get',
//             url: tenantConfig?.endpoints.fileIndexing + '/AWSIndexer/GetExportPathOptions',
//             params: {
//                 projectToken,
//             },
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${jwtToken}`
//             },
//         });

//         return response.data.Result;
//     }, {
//         refetchOnWindowFocus: false,
//         enabled: projectToken !== null && projectToken !== undefined && projectToken !== '',
//         cacheTime: 0,
//     });
// }

export function useMapObjectsFromBucketByExtensions(data: IMapObjectsFromBucketByExtensions | null, checkSum: boolean = false) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('mapObjectsFromBucketByExtensions' + data + checkSum, async () => {
        try {
            const response = await axios.request<IResponseAPI<string>>({
                method: 'post',
                url: tenantConfig?.endpoints.fileIndexing + '/AWSIndexer/MapObjectsFromBucketByExtensions',
                data: {
                    dataSourceToken: data?.dataSourceToken,
                    connectionId: data?.connectionId,
                    withChecksum: checkSum,
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwtToken}`
                },
            });

            return response.data.Result;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any) {
            if (error.message === 'Network Error') {
                return 'Network error: Unable to connect to the server.';
            }
            return await error.response.data.Result.Message;
        }
    }, {
        refetchOnWindowFocus: false,
        enabled: data !== null,
        cacheTime: 0,
        retry: false,
        refetchOnReconnect: false,
    });
}