import { css } from '@emotion/react';
import { useRef } from 'react';

import { useMousePosition } from '../hooks/useMousePosition';
import { ILineString } from '../models/interfaces/ILineString';

const style = css({
    margin : '0px',
    '& .geopost-seismic-mouse-position': {
        //width: 150,
        fontSize: 12,
        position: 'absolute',
        right: 35,
        top: 20
    },
    '& .x, .y': {
        margin: 0
    }
});

export function SeismicShowCoordinates (){
    const ref = useRef<HTMLDivElement>(null);
    useMousePosition(ref);

    return (<div id='mouse-position' ref={ref} css={style}></div>);
}