import {
    Box,
    Checkbox,
    CircularProgress,
    css,
    FormControl,
    FormControlLabel,
    IconButton,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useRef, useState } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import { AmplitudeDomain } from '../../models/enums/AmplitudeDomain';
import { IWellLayers, useSeismicStore } from '../../stores/useSeismicStore';
import { useMapStore } from '../../stores/useMapStore';
import { useGetCrossingPoint } from './hooks/useGetCrossingPoint';
import { useGetAnnotation } from './hooks/useGetAnnotation';
import { AnnotationAuthors, Author } from './AnnotationAuthors';
import { useGetTimeDepth } from './hooks/useGetTimeDepth';
import { InfoIconMenu } from './InfoIconMenu';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { useRelatedData } from './hooks/useRelatedData';
import { GenericCutsCheckbox } from './GenericCutsCheckbox';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { IWellGenericCuts } from 'features/seismic/models/interfaces/IWellGenericCuts';
import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';

const datum = 0;

const containerStyle = css({
    display: 'flex',
    '& .MuiBox-root': {
        justifyContent: 'space-between',
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiSvgIcon-root': {
            fill: 'rgba(0, 0, 0)',
            width: '20px',
            height: '20px',
        },
        '& .MuiInputBase-root': {
            marginRight: '10px',
            display: 'flex',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
                width: '15px',
                height: '15px',
                marginTop: '4px',
                '& path': {
                    fill: 'rgb(255, 255, 255)',
                },
            },
        },
        '& .MuiFormControl-root': {
            width: '130px',
        },
        '& .MuiNativeSelect-select': {
            padding: '3px 20px 3px 5px !important',
            fontSize: '14px',
            backgroundColor: 'rgb(0, 30, 58)',
            color: 'rgb(255, 255, 255)',
            '& option': {
                padding: '5px',
                minHeight: '0',
                width: '100px',
                fontSize: '11px',
                color: 'rgb(255, 255, 255)',
                fontFamily: 'Arial, Verdana, sans-serif',
                backgroundColor: 'rgb(0, 30, 58)',
            },
        },
        '& .info-icon': {
            cursor: 'default',
            '& .info-box': {
                position: 'absolute',
                alignItems: 'flex-start',
                zIndex: '1',
                top: '100%',
                left: '0',
                padding: '8px',
                width: '250px',
                backgroundColor: 'rgb(0, 30, 58)',
                '& .MuiTypography-root': {
                    color: 'rgb(255, 255, 255)',
                    textAlign: 'left',
                    fontSize: '12px',
                    fontFamily: 'Arial, Verdana, sans-serif',
                },
            },
        },
    },
    '& .close-icon': {
        fill: 'rgb(255, 0, 0) !important',
    },
    '& .checkboxes-well': {
        '& .MuiSvgIcon-root': {
            fill: 'rgba(14, 149, 196, 0.75)',
            width: 24,
            height: 24,
        },
        '& .Mui-disabled': {
            cursor: 'not-allowed',
        },
        '& .MuiFormControlLabel-root': {
            width: 'fit-content',
        },
        '& .MuiIconButton-root': {
            display: 'flex',
            position: 'relative',
            left: '-30px',
        },
    },
    '& .align-left': {
        justifyContent: 'flex-start',
    },
    '& .align-left .MuiFormControlLabel-root': {
        position: 'relative',
        left: '-24px',
    },
});

export function Well({
    domain,
    data,
    filterOn,
    dataGeometryLine,
    layersToRecreate,
    surveyType,
    crossingPointChecked = false,
    onCrossingPointCheckChange = () => { },
    isCrossingPointLoading = false,
    formationTopChecked = false,
    isFormationTopLoading = false,
    onFormationTopCheckChange = () => { },
    onChangeFormationTopAuthorVisbility = () => {},
    formationTopAuthors = [],
    timeDepthChecked = false,
    isTimeDepthLoading = false,
    onTimeDepthCheckChange = () => {},
    hydrocarbonChecked = false,
    isHydrocarbonLoading = false,
    onHydrocarbonCheckChange = () => {},
    onChangeHydrocarbonAuthorVisibility = () => {},
    hydrocarbonAuthors = [],
    formationTestChecked = false,
    isFormationTestLoading = false,
    onFormationTestCheckChange = () => { },
    onChangeFormationTestAuthorVisbility = () => {},
    formationTestAuthors = [],
    lithologyChecked = false,
    isLithologyLoading = false,
    onGenericCutCheckChange = () => {},
    showNoTimeDepthIcon
}: Props) {
    const { t } = useTranslation();

    useEffect(() => {
        console.log('a');
    }, []);

    const { wellsLayers, wellRelatedData } = useSeismicStore((state) => ({
        wellsLayers: state.wellsLayers,
        wellRelatedData: state.wellRelatedData,
    }));

    const { map } = useMapStore((state) => ({
        map: state.map,
    }));

    const [formationTopAuthorsOpen, setFormationTopAuthorsOpen] =
        useState<boolean>(false);
    const [hydrocarbonAuthorsOpen, setHydrocarbonAuthorsOpen] =
        useState<boolean>(false);
    const [formationTestAuthorsOpen, setFormationTestAuthorsOpen] =
        useState<boolean>(false);
    const [menuInfo, setMenuInfo] = useState<boolean>(false);
    const [genericCutsToRecreate, setGenericCutsToRecreate] = useState<string[]>(
        []
    );

    const loadingCrossingPoint = useRef<boolean>(false);
    const loadingTimeDepth = useRef<boolean>(false);
    const loadingFormationTop = useRef<boolean>(false);
    const loadingHydrocarbon = useRef<boolean>(false);
    const loadingFormationTest = useRef<boolean>(false);
    const loadingRelationData = useRef<boolean>(false);

    const { crossingPointLine } = useGetCrossingPoint(
        data,
        domain,
        datum,
        dataGeometryLine!
    );
    const { getTimeDepth } = useGetTimeDepth(
        data,
        domain,
        datum,
        dataGeometryLine!
    );
    const { annotations, annotation, setAnnotation, authorFilter } =
        useGetAnnotation(data, domain, datum, dataGeometryLine!);
    const { getRelatedData } = useRelatedData(
        data,
        domain,
        datum,
        dataGeometryLine!
    );

    const filterStyle = filterOn ? 'flex' : 'none';

    /*const {
          isCrossingPointVisible,
          setIsCrossingPointVisible
      } = use3DWellGroup(data, geopost3DScene, seismic3DCountTimeDepth, seismic3DSamplesPerTrace, main3DFeatureCentroidX, main3DFeatureCentroidY, crossingPoint3DColor);*/

    const [checkbox, setCheckbox] = useState<{ [key: string]: boolean }>(
        surveyType === SurveyType.Seismic2D
            ? {
                crossingPoint: false,
                timeDepth: false,
                formationTop: false,
                hydrocarbon: false,
                formationTest: false,
                relatedData: false,
            }
            : {
                crossingPoint: crossingPointChecked,
                timeDepth: timeDepthChecked,
                formationTop: formationTopChecked,
                hydrocarbon: hydrocarbonChecked,
                formationTest: formationTestChecked,
                relatedData: false,
            }
    );

    const [showAnnotationPopover, setShowAnnotationPopover] =
        useState<boolean>(false);

    const adjustDistance = (distanceValue: number) => {
        let distance = distanceValue.toFixed(1);
        let distanceType = 'm';

        if (distanceValue / 1000 > 1) {
            distance = (distanceValue / 1000).toFixed(1);
            distanceType = 'km';
        }

        return '(' + distance + distanceType + ')';
    };

    const handleChange = (name: string, checked: boolean) => {
        setCheckbox((prevState) => ({ ...prevState, [name]: checked }));
    };

    const handleCheckboxes = (checked: boolean, type: string) => {
        if (surveyType === SurveyType.Seismic2D) {
            handleCheckboxes2D(checked, type);
        } else {
            handleCheckboxes3D(checked, type);
        }
    };

    const handleCheckboxes2D = (
        checked: boolean,
        type: string,
        zoom: boolean = false,
        recreate: boolean = false
    ) => {
        switch (type) {
        case 'crossingPoint':
            handleChange(type, checked);
            break;
        case 'timeDepth':
            handleChange(type, checked);
            break;
        case 'formationTop':
            handleChange(type, checked);
            break;
        case 'hydrocarbon':
            handleChange(type, checked);
            break;
        case 'formationTest':
            handleChange(type, checked);
            break;
        case 'relatedData':
            handleChange(type, checked);
            break;
        }
        create2DLayers(checked, type, zoom, recreate);
    };

    const handleCheckboxes3D = (checked: boolean, type: string) => {
        handleChange(type, checked);
        create3DMeshes(checked, type);
    };

    const create3DMeshes = (checked: boolean, type: string) => {
        switch (type) {
        case 'crossingPoint':
            onCrossingPointCheckChange(checked);
            break;
        case 'timeDepth':
            onTimeDepthCheckChange(checked);
            break;
        case 'formationTop':
            onFormationTopCheckChange(checked);
            break;
        case 'hydrocarbon':
            onHydrocarbonCheckChange(checked);
            break;
        case 'formationTest':
            onFormationTestCheckChange(checked);
        }
    };

    const { scale } = useSeismicStore((state) => ({
        scale: state.scale,
    }));

    const create2DLayers = (
        checked: boolean,
        type: string,
        zoom: boolean,
        recreate: boolean
    ) => {
        switch (type) {
        case 'crossingPoint':
            crossingPointLine(
                checked,
                loadingCrossingPoint,
                !checkbox.crossingPoint ? true : false,
                recreate,
                zoom
            );
            break;
        case 'timeDepth':
            getTimeDepth(checked, loadingTimeDepth, recreate);
            break;
        case 'formationTop':
            annotations(checked, type, loadingFormationTop, true, recreate);
            break;
        case 'hydrocarbon':
            annotations(checked, type, loadingHydrocarbon, true, recreate);
            break;
        case 'formationTest':
            annotations(checked, type, loadingFormationTest, true, recreate);
            break;
        case 'relatedData':
            getRelatedData(checked, loadingRelationData);
            break;
        }
    };

    const closeIconRef = useRef<HTMLElement>(null);

    const handleFormationTopAuthorsOpen = () => {
        setFormationTopAuthorsOpen(!formationTopAuthorsOpen);
    };

    const handleFormationTopAuthorsCheckbox = (index: number) => {
        const newAuthors = annotation['formationTop'];
        newAuthors.authors[index].selected = !newAuthors.authors[index].selected;
        setAnnotation((prevState) => ({ ...prevState, newAuthors }));
        authorFilter(
            index,
            'formationTop',
            newAuthors.authors[index].selected,
            checkbox.formationTop
        );
    };

    const handle3DFormationTopAuthorsCheckbox = (index: number) => {
        onChangeFormationTopAuthorVisbility(formationTopAuthors[index]);
    };

    const handleHydrocarbonAuthorsOpen = () => {
        setHydrocarbonAuthorsOpen(!hydrocarbonAuthorsOpen);
    };

    const handleHydrocarbonAuthorsCheckbox = (index: number) => {
        const newAuthors = annotation['hydrocarbon'];
        newAuthors.authors[index].selected = !newAuthors.authors[index].selected;
        setAnnotation((prevState) => ({ ...prevState, newAuthors }));
        authorFilter(
            index,
            'hydrocarbon',
            newAuthors.authors[index].selected,
            checkbox.hydrocarbon
        );
    };

    const handle3DHydrocarbonAuthorsCheckbox = (index: number) => {
        const author = hydrocarbonAuthors[index];
        onChangeHydrocarbonAuthorVisibility(author);
    };

    const handleFormationTestAuthorsOpen = () => {
        setFormationTestAuthorsOpen(!formationTestAuthorsOpen);
    };

    const handleFormationTestAuthorsCheckbox = (index: number) => {
        const newAuthors = annotation['formationTest'];
        newAuthors.authors[index].selected = !newAuthors.authors[index].selected;
        setAnnotation((prevState) => ({ ...prevState, newAuthors }));
        authorFilter(
            index,
            'formationTest',
            newAuthors.authors[index].selected,
            checkbox.formationTest
        );
    };

    const handle3DFormationTestAuthorsCheckbox = (index: number) => {
        const author = formationTestAuthors[index];
        onChangeFormationTestAuthorVisbility(author);
    };

    const handleChangeCompositeProfile = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        window.open(
            `/Well/Viewer?token=${selectedValue}`,
            '_blank'
        );
    };

    useEffect(() => {
        if (Object.keys(wellRelatedData).includes(data.Id.toString())) {
            if (!wellRelatedData[data.Id].opened) {
                setCheckbox({ ...checkbox, relatedData: false });
            }
        }
    }, [wellRelatedData]);

    useEffect(() => {
        if (
            layersToRecreate[data.Id.toString()] &&
            surveyType === SurveyType.Seismic2D
        ) {
            let checked: { [key: string]: boolean } = {
                crossingPoint: false,
                timeDepth: false,
                formationTop: false,
                hydrocarbon: false,
                formationTest: false,
                relatedData: false,
            };

            let genericCuts: string[] = [];

            for (let key in layersToRecreate[data.Id.toString()]) {
                let type = key.split('_')[0];
                if (checked[type]) {
                    continue;
                }

                if (wellsLayers[data.Id.toString()][key].checked) {
                    if (
                        type === 'Lithology' ||
                        type === 'Log Curve' ||
                        type === 'Other'
                    ) {
                        genericCuts.push(key.split('_')[1]);
                    } else {
                        checked[type] = true;
                        create2DLayers(true, type, false, true);
                    }
                } else {
                    create2DLayers(false, type, false, true);
                }
            }

            setCheckbox(checked);
            if (genericCuts.length !== 0) {
                setGenericCutsToRecreate(genericCuts);
            }
        }
    }, [layersToRecreate]);

    return (
        <Box
            css={containerStyle}
            className='column'
            style={{ display: filterStyle }}
        >
            <Box className='row align-items'>
                <Box className='row'>
                    <Fragment>
                        <IconButton
                            className='info-icon'
                            onMouseEnter={() => setMenuInfo(true)}
                            onMouseLeave={() => setMenuInfo(false)}
                            color='primary'
                        >
                            <InfoIcon />
                            <Box
                                className='info-box column'
                                style={{ display: menuInfo ? 'flex' : 'none' }}
                            >
                                <InfoIconMenu
                                    menuOpen={menuInfo}
                                    data={data}
                                    domain={domain}
                                    datum={datum}
                                />
                            </Box>
                        </IconButton>
                    </Fragment>
                    {!data.TimeDepth && <CloseIcon className='close-icon' />}
                    <Typography className='bold'>{data.Name}</Typography>
                    <Fragment>
                        <IconButton
                            color='primary'
                            onClick={() => {
                                handleCheckboxes2D(true, 'crossingPoint', true, false);
                            }}
                            title={t('goToWell')}
                        >
                            <ZoomInIcon />
                        </IconButton>
                    </Fragment>
                    <Typography>{adjustDistance(data.Distance)}</Typography>
                </Box>
                {data.Profiles !== '' && (
                    <FormControl>
                        <Select
                            native
                            displayEmpty
                            value={t('openProfile')}
                            onChange={handleChangeCompositeProfile}
                        >
                            <option key={0} label={t('openProfile')} hidden></option>
                            {data.Profiles.split(';').map((item: string, index: number) => (
                                <option key={index + 1} value={`${item.split('|')[1]}`}>
                                    Composite Profile [{index + 1}]
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Box>
            <Box className='checkboxes-well'>
                <Box className='column'>
                    <FormControlLabel
                        control={
                            <GeopostCheckbox
                                id='wells-crossing-point-checkbox'
                                loading={loadingCrossingPoint.current || isCrossingPointLoading}
                                checked={surveyType === SurveyType.Seismic2D ? checkbox.crossingPoint : crossingPointChecked}
                                onChange={checked => handleCheckboxes(checked, 'crossingPoint')}
                            />
                        }
                        label={
                            <Typography>
                                {data.HasDirectionalData && surveyType === SurveyType.Seismic3D? 'Directional path' : t('crossingPoint')}{' '}
                                {loadingCrossingPoint.current ? (
                                    <CircularProgress style={{ width: '15px', height: '15px' }} />
                                ) : (
                                    ''
                                )}
                            </Typography>
                        }
                    />
                    {data.TimeDepth && (
                        <FormControlLabel
                            control={
                                <GeopostCheckbox
                                    id='wells-time-depth-checkbox'
                                    loading={loadingTimeDepth.current || isTimeDepthLoading}
                                    checked={surveyType === SurveyType.Seismic2D ? checkbox.timeDepth : timeDepthChecked}
                                    onChange={(checked) => {
                                        handleCheckboxes(checked, 'timeDepth');
                                    }}
                                />
                            }
                            label={
                                <Typography>
                                    {t('timeDepth')}{' '}
                                    {loadingTimeDepth.current ? (
                                        <CircularProgress
                                            style={{ width: '15px', height: '15px' }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Typography>
                            }
                        />
                    )}
                    {data.FormationTop && (
                        <Box>
                            <Box className='row align-left'>
                                <Fragment>
                                    <IconButton
                                        color='primary'
                                        onClick={() => {
                                            handleFormationTopAuthorsOpen();
                                            annotations(
                                                !checkbox.formationTop,
                                                'formationTop',
                                                loadingFormationTop
                                            );
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Fragment>
                                <FormControlLabel
                                    control={
                                        <GeopostCheckbox
                                            id='wells-formation-top-checkbox'
                                            loading={loadingFormationTop.current || isFormationTopLoading}
                                            checked={surveyType === SurveyType.Seismic2D ? checkbox.formationTop : formationTopChecked}
                                            onChange={(checked) => {
                                                handleCheckboxes(
                                                    checked,
                                                    'formationTop'
                                                );
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography>
                                            {t('formationTop')}{' '}
                                            {loadingFormationTop.current ? (
                                                <CircularProgress
                                                    style={{ width: '15px', height: '15px' }}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Typography>
                                    }
                                />
                            </Box>
                            <AnnotationAuthors
                                authors={
                                    surveyType === SurveyType.Seismic2D
                                        ? annotation['formationTop']
                                        : {
                                            authors: formationTopAuthors,
                                            loading: false
                                        }
                                }
                                handleCheckbox={
                                    surveyType === SurveyType.Seismic2D
                                        ? handleFormationTopAuthorsCheckbox
                                        : handle3DFormationTopAuthorsCheckbox
                                }
                                open={formationTopAuthorsOpen}
                            />
                        </Box>
                    )}
                    {data.Hydrocarbon && (
                        <Box>
                            <Box className='row align-left'>
                                <Fragment>
                                    <IconButton
                                        color='primary'
                                        onClick={() => {
                                            handleHydrocarbonAuthorsOpen();
                                            annotations(
                                                !checkbox.hydrocarbon,
                                                'hydrocarbon',
                                                loadingHydrocarbon
                                            );
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Fragment>
                                <FormControlLabel
                                    control={
                                        <GeopostCheckbox
                                            id='wells-hydrocarbon-checkbox'
                                            checked={surveyType === SurveyType.Seismic2D ? checkbox.hydrocarbon : hydrocarbonChecked}
                                            onChange={checked => {
                                                handleCheckboxes(
                                                    checked,
                                                    'hydrocarbon'
                                                );
                                            }}
                                            loading={loadingHydrocarbon.current || isHydrocarbonLoading}
                                        />
                                    }
                                    label={
                                        <Typography>
                                            {t('hydrocarbon')}{' '}
                                            {loadingHydrocarbon.current ? (
                                                <CircularProgress
                                                    style={{ width: '15px', height: '15px' }}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Typography>
                                    }
                                />
                            </Box>
                            <AnnotationAuthors
                                authors={
                                    surveyType === SurveyType.Seismic2D
                                        ? annotation['hydrocarbon']
                                        : {
                                            authors: hydrocarbonAuthors,
                                            loading:  false
                                        }
                                }
                                handleCheckbox={
                                    surveyType === SurveyType.Seismic2D
                                        ? handleHydrocarbonAuthorsCheckbox
                                        : handle3DHydrocarbonAuthorsCheckbox
                                }
                                open={hydrocarbonAuthorsOpen}
                            />
                        </Box>
                    )}
                    {data.FormationTest && (
                        <Box>
                            <Box className='row align-left'>
                                <Fragment>
                                    <IconButton
                                        color='primary'
                                        onClick={() => {
                                            handleFormationTestAuthorsOpen();
                                            annotations(
                                                !checkbox.formationTest,
                                                'formationTest',
                                                loadingFormationTest
                                            );
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Fragment>
                                <FormControlLabel
                                    control={
                                        <GeopostCheckbox
                                            id='wells-formation-test-checkbox'
                                            loading={loadingFormationTest.current || isFormationTestLoading}
                                            checked={surveyType === SurveyType.Seismic2D ? checkbox.FormationTest : formationTestChecked}
                                            onChange={checked => {
                                                handleCheckboxes(checked, 'formationTest');
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography>
                                            {t('formationTest')}{' '}
                                            {loadingFormationTest.current ? (
                                                <CircularProgress
                                                    style={{ width: '15px', height: '15px' }}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Typography>
                                    }
                                />
                            </Box>
                            <AnnotationAuthors
                                authors={
                                    surveyType === SurveyType.Seismic2D
                                        ? annotation['formationTest']
                                        : {
                                            authors: formationTestAuthors,
                                            loading: false
                                        }
                                }
                                handleCheckbox={
                                    surveyType === SurveyType.Seismic2D
                                        ? handleFormationTestAuthorsCheckbox
                                        : handle3DFormationTestAuthorsCheckbox
                                }
                                open={formationTestAuthorsOpen}
                            />
                        </Box>
                    )}
                    {data.RelatedInfo && (
                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={loadingRelationData.current}
                                        checked={checkbox.relatedData}
                                        onChange={(event) => {
                                            handleCheckboxes(event.target.checked, event.target.name);
                                        }}
                                        name='relatedData'
                                    />
                                }
                                label={
                                    <Typography>
                                        {t('relatedData')}{' '}
                                        {loadingRelationData.current ? (
                                            <CircularProgress
                                                style={{ width: '15px', height: '15px' }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </Typography>
                                }
                            />
                        </Box>
                    )}
                    {data.GenericCuts !== '' &&
                        data.GenericCuts.split(';').map((item: string, index: number) => (
                            <GenericCutsCheckbox
                                key={index}
                                wellId={data.Id}
                                trace={data.Trace}
                                item={item}
                                domain={domain}
                                datum={datum}
                                dataGeometryLine={dataGeometryLine!}
                                genericCutsToRecreate={genericCutsToRecreate}
                                surveyType={surveyType}
                                onChange={onGenericCutCheckChange}
                                checked={surveyType === SurveyType.Seismic2D ? undefined : lithologyChecked}
                                isGenericCutLoading={isLithologyLoading}
                            />
                        ))}
                </Box>
            </Box>
        </Box>
    );
}

interface Props {
    domain: AmplitudeDomain;
    data: IWell;
    filterOn: boolean;
    dataGeometryLine: ILineString | null;
    layersToRecreate: IWellLayers;
    surveyType: SurveyType;
    crossingPointChecked?: boolean;
    onCrossingPointCheckChange?: (checked: boolean) => void;
    isCrossingPointLoading?: boolean;
    timeDepthChecked?: boolean;
    isTimeDepthLoading?: boolean;
    onTimeDepthCheckChange?: (checked: boolean) => void;
    hydrocarbonChecked?: boolean;
    onHydrocarbonCheckChange?: (checked: boolean) => void;
    onChangeHydrocarbonAuthorVisibility?: (author: Author) => void;
    hydrocarbonAuthors?: Author[];
    isHydrocarbonLoading?: boolean;
    formationTopChecked?: boolean;
    isFormationTopLoading?: boolean;
    onFormationTopCheckChange?: (checked: boolean) => void;
    onChangeFormationTopAuthorVisbility?: (author: Author) => void;
    formationTopAuthors?: Author[];
    formationTestChecked?: boolean;
    isFormationTestLoading?: boolean;
    onFormationTestCheckChange?: (checked: boolean) => void;
    onChangeFormationTestAuthorVisbility?: (author: Author) => void;
    formationTestAuthors?: Author[];
    lithologyChecked?: boolean;
    isLithologyLoading?: boolean;
    onGenericCutCheckChange?: (checked: boolean, elementToken: string) => void;
    showNoTimeDepthIcon: boolean
}
