import { Fragment, useEffect, useRef, useState } from 'react';
import { Checkbox, CircularProgress, FormControlLabel, IconButton, Menu, MenuItem, TextField, css } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from 'session/useSessionStore';
import { Grid } from 'components/icons/components';
import { useSeismicGraticule } from '../hooks/useSeismicGraticule';
import { ILineString } from '../models/interfaces/ILineString';

const inputStyle = css({
    '& .MuiFormControl-root': {
        '& .MuiOutlinedInput-notchedOutline': {
            '& legend': {
                width: '48px',
                borderWidth: '1px'
            }
        }
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            borderWidth: '1px',
            borderRadius: '5px'
        }
    }
});

export function SeismicGraticule({ dataGeometryLine }:Props) {
    const { isEnable, toggle, line, stepX, stepY } = useSeismicGraticule(dataGeometryLine);
    const [ openDetail, setOpenDetail ] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const iconButtonRef = useRef(null);
    const {tenantConfig} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration
    }));
    const { t } = useTranslation();

    const className = (isEnable) ? 'active' : '';

    const handleClose = () => setOpenDetail(false);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setOpenDetail(true);
        setAnchorEl(iconButtonRef.current);
    };

    return <Fragment>
        <IconButton
            className={className}
            style={{color:tenantConfig?.main.palette.primary.contrastText}}
            title='Enable graticule grid lines. Right click for extra options'
            onClick={toggle}
            onContextMenu={handleContextMenu}
            ref={iconButtonRef}
            id='graticule'
        >
            <Grid />
        </IconButton>

        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDetail}
            onClose={handleClose}
        >
            <MenuItem>
                <FormControlLabel
                    label={t('graticule.enableLines')}
                    style={{color: 'white'}}
                    control={<Checkbox checked={line.value} onChange={evt => {line.set(evt.target.checked)}}/>} />

            </MenuItem>
            <div css={inputStyle} >
                <MenuItem>
                    <TextField label='X Axis' value={stepX.value} onChange={(evt) => stepX.set(parseInt(evt.target.value))} color='primary'/>
                </MenuItem>
                <MenuItem>
                    <TextField label='Y Axis' value={stepY.value} onChange={(evt) => stepY.set(parseInt(evt.target.value))} color='primary'/>
                </MenuItem>
            </div>
        </Menu>
    </Fragment>;
}

interface Props {
    dataGeometryLine: ILineString | null;
}