import { useEffect } from 'react';

import { ICrossSection } from '../models/interfaces/ICrossSection';
import { IWellAnnotation } from '../models/interfaces/IWellAnnotation';
import { useCrossSectionAnnotationsStore } from '../stores/useCrossSectionAnnotationsStore';
import { useCrossSectionConnectionStore } from '../stores/useCrossSectionConnectionStore';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { buildView, calculateCenter } from '../utils/wellLaneMapUtils';

export function useMainViewCreation(crossSection: ICrossSection | null){
    const { connectionViews } = useCrossSectionConnectionStore(state => ({
        connectionViews: state.connectionViews
    }));

    const { crossSectionData, setView, dataDepthRange,  laneWidth, factorVertical, displacementVertical } = useCrossSectionStore(state => ({
        crossSectionData: state.crossSectionData,
        displacementVertical: state.displacementVertical,
        setView: state.setView,
        dataDepthRange: state.dataDepthRange,
        laneWidth: state.laneWidth,
        factorVertical: state.factorVertical
    }));

    const { flatAnnotation, annotations } = useCrossSectionAnnotationsStore(state => ({
        annotations: state.annotations,
        flatAnnotation: state.flatAnnotation
    }));

    useEffect(() => {
        // let displacement =
        let displacementTop = 0;
        let displacementBottom = 0;

        if (Object.keys(displacementVertical).length > 0){
            const lessThanZero = Object.values(displacementVertical).filter(x=> x < 0);
            const greaterThanZero = Object.values(displacementVertical).filter(x=> x > 0);

            if (lessThanZero.length > 0){
                displacementTop = Math.max(...lessThanZero);
            }

            if (greaterThanZero.length > 0){
                displacementBottom = Math.max(...greaterThanZero);
            }
        }
        let start = crossSection ? crossSection.WellStart : 2000;
        let end = crossSection ? crossSection.WellEnd : 6000;
        // let minY = (((crossSection ? crossSection.WellEnd : 6000) /*+ displacementBottom */) * -1) * factorVertical;.
        console.log(displacementBottom);
        let minY = (start + (end - start)*factorVertical) * -1;
        let maxY = (start)* -1;

        minY -= displacementBottom;
        maxY += displacementBottom;
        //console.log();

        const extentView = [0, minY, laneWidth, maxY];
        console.log(extentView);

        let forceCenterY = 0;
        if (crossSection && crossSection.Itens && crossSection.Itens.length > 0 && flatAnnotation) {
            const firstItemCrossSection = crossSection.Itens[0];
            const annotation = annotations[firstItemCrossSection.WellId!].find(x=> x.Annotation === flatAnnotation);

            if (annotation){
                forceCenterY = -annotation.Depths[0];
            }
        }

        const center = calculateCenter(extentView, dataDepthRange, forceCenterY);
        const view = buildView(center, extentView);

        view.on('change:center', (event) => {
            const center = event.target.getCenter();
            for (let key in connectionViews){
                connectionViews[key].setCenter(center);
            }
        });

        setView(view);
        console.log('entrando no use view creation');
    // }, [crossSection, laneWidth, factorVertical, dataDepthRange, displacementVertical]);
    }, [crossSection, laneWidth, factorVertical, dataDepthRange, displacementVertical]);
}