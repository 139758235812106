import { css } from '@mui/material';
import {useEffect, useRef} from 'react';
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { View } from 'ol';
import { Style } from 'ol/style';

import { ICrossSectionConnection } from '../models/interfaces/ICrossSectionItem';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { HEIGHT_HEADER_LANE, HEIGHT_HEADER_TITLE_LANE } from '../consts/layotsConsts';
import { buildExtent, buildFeatures, buildMap, buildStyle } from '../utils/wellLaneConnectionMapUtils';
import { useCrossSectionConnectionStore } from '../stores/useCrossSectionConnectionStore';
import { buildView } from '../utils/wellLaneMapUtils';
import { useCrossSectionAnnotationsStore } from '../stores/useCrossSectionAnnotationsStore';

const style = css({
    marginRight: '0px',
    borderStyle:'solid',
    borderWidth: 0,
    paddingLeft: 0,
    borderColor: 'rgb(4, 31, 60)',
    borderRadius: 3,
    paddingTop: 10,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',
    '& .container-map': {
        flexGrow: 1,
        position: 'relative'
    },
    '& .well-distance': {
        fontSize:'12px',
        width: '100%',
        backgroundColor: 'rgb(3, 60, 122)',
        color: 'white',
        borderRadius: 0,
        textAlign: 'center',
        height: HEIGHT_HEADER_TITLE_LANE,
        marginTop: '1px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '& .well-info': {
        fontSize:'16px',
        padding: '3px',
    },
    '& .mapa': {
        position: 'absolute',
        top: HEIGHT_HEADER_LANE - HEIGHT_HEADER_TITLE_LANE,
        height: 'calc(100% - ' + (HEIGHT_HEADER_LANE - HEIGHT_HEADER_TITLE_LANE) + 'px)'
    },
});

export function CrossSectionLaneConnection (props: { connection?: ICrossSectionConnection }){
    const { view: wellView, factorVertical, dataDepthRange, perfectScrollbar, displacementVertical,crossSectionData } = useCrossSectionStore(state => ({
        dataDepthRange: state.dataDepthRange,
        displacementVertical: state.displacementVertical,
        factorVertical: state.factorVertical,
        perfectScrollbar: state.perfectScrollbar,
        view: state.view,
        crossSectionData: state.crossSectionData,

    }));

    const { connectionWidth, setConnectionView,connectionStartDestination } = useCrossSectionConnectionStore(state => ({
        connectionWidth: state.connectionWidth,
        setConnectionView: state.setConnectionView,
        connectionStartDestination : state.connectionStartDestination,
    }));

    const { visibleSettings } = useCrossSectionAnnotationsStore(state => ({
        visibleSettings: state.visibleSettings
    }));

    const layer = useRef<VectorLayer<VectorSource>>();
    const map = useRef<Map>();
    const container = useRef<HTMLDivElement>(null);
    const currentView = useRef<View>();

    const updateStyles = () => {
        if (Object.keys(visibleSettings).length > 0 && props.connection?.ConnectionItens){
            const features = layer.current?.getSource()?.getFeatures();

            if (!features || features.length === 0){
                return;
            }
            for (let annotations in visibleSettings){
                const data = visibleSettings[annotations];
                const connectionItem = props.connection.ConnectionItens.find(x => x.SourceFormationName);

                if (!connectionItem){
                    continue;
                }

                const featuresFiltered = features.filter(x=>x.get('groupKey') === annotations);

                if (!featuresFiltered || featuresFiltered.length === 0){
                    continue;
                }

                for (let feature of featuresFiltered){
                    if (data.visible){
                        feature.setStyle(buildStyle());
                    }
                    else {
                        feature.setStyle(new Style());
                    }
                }
            }
        }
    };

    useEffect(() => {
        updateStyles();
    }, [visibleSettings]);

    useEffect(() => {
        if (!wellView || !props.connection || !container.current){
            return;
        }

        if (!map.current){
            map.current = buildMap(props.connection.WellNameSource, props.connection.WellNameDestination);
        }

        const mapExtent = buildExtent(wellView, [connectionWidth, container.current.getBoundingClientRect().height]);
        //const center = calculateCenter(mapExtent, dataDepthRange); //TODO -> REMOVER?

        currentView.current = buildView(wellView.getCenter()!, mapExtent);

        map.current.setView(currentView.current);
        console.log(props.connection);
        console.log(connectionStartDestination);
        let start = connectionStartDestination[props.connection.WellNameSource].start;
        let destination = connectionStartDestination[props.connection.WellNameDestination].destination;
        const features:Feature[] = buildFeatures(props.connection.ConnectionItens, mapExtent, factorVertical, displacementVertical,start,destination);

        if (!layer.current){
            let annotationLayer = new VectorLayer({
                visible: true,
                source: new VectorSource(),
            });

            layer.current = annotationLayer;

            map.current.addLayer(annotationLayer);
        }

        map.current.updateSize();

        const source = layer.current.getSource()!;
        source.clear();
        source.addFeatures(features);

        const id = `${props.connection.WellNameSource}_${props.connection.WellNameDestination}`;
        setConnectionView(id, currentView.current);
        updateStyles();

    }, [wellView, factorVertical, connectionWidth, dataDepthRange, displacementVertical,connectionStartDestination]);

    useEffect(() => {
        if (perfectScrollbar){
            perfectScrollbar.update();
        }
    }, [connectionWidth]);

    useEffect(() => {
        if (currentView.current && wellView){
            currentView.current.setCenter(wellView.getCenter());
        }
    }, [wellView, connectionWidth]);

    return (
        <div css={style} style={{width: connectionWidth}} className='well-lane-container' key={'lane_connection_' + props.connection?.WellNameSource + '_' + props.connection?.WellNameDestination}>
            <div className='well-name-header'>
                <div className='well-distance'>
                    <span>{props?.connection && props?.connection?.DistanceKmBeteenWells < 1000 ? props.connection?.DistanceKmBeteenWells.toFixed(0) + ' m' : ((props.connection?.DistanceKmBeteenWells ? props.connection?.DistanceKmBeteenWells : 0) / 1000).toFixed(0) + ' km'}</span>
                </div>
            </div>
            <div className='container-map'>
                <div ref={container} id={`map${props.connection?.WellNameSource + '_' + props.connection?.WellNameDestination}`} style={{width: connectionWidth}} className='mapa'></div>
            </div>
        </div>
    );
}