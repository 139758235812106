import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { MutableRefObject, useEffect, useRef } from 'react';
import { Point } from 'ol/geom';
import { Feature } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { createCrossingPoint, getXValue, getYValue } from 'features/seismic/utils/wellsUtils';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { LineType } from 'features/seismic/models/enums/LineType';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { IWellCrossingPointViewModel } from 'features/seismic/models/interfaces/IWellCrossingPointViewModel';
import { useAPI } from 'hooks/useAPI';
import { FetchType } from 'models/enums/FetchType';
import { ApiType } from 'models/enums/ApiType';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { getXByTrace } from 'features/seismic/utils/calculateScreenValuesUtils';

export function useGetCrossingPoint(data: IWell, domain: AmplitudeDomain, datum: number, dataGeometryLine: ILineString) {
    // const { data: dataCrossingPoint } = useGetCrossingPointForSeismic(data.Id, data.Trace, domain, datum);
    const { execute: executeCrossingPoint, data: dataCrossingPoint } = useAPI<IWellCrossingPointViewModel>('/Wells/SeismicWells/NewGetCrossingPointForSeismic');

    const { calculator, surveyMetadata, scale, wellLayers, setWellLayers, miniMapWells, setMiniMapWells, setCreateWellInMiniMap, setRemoveWellInMiniMap } = useSeismicStore(state => ({
        calculator: state.calculator,
        scale: state.scale,
        surveyMetadata: state.surveyMetadata,
        wellLayers: state.wellsLayers,
        setWellLayers: state.setWellsLayers,
        miniMapWells: state.miniMapWells,
        setMiniMapWells: state.setMiniMapWells,
        setCreateWellInMiniMap: state.setCreateWellInMiniMap,
        setRemoveWellInMiniMap: state.setRemoveWellInMiniMap
    }));

    const { lineType } = useLine3DNavigationStore(state => ({
        lineType: state.lineType
    }));

    const { map } = useMapStore(state => ({
        map: state.map,
    }));

    const verifyScale = useRef<{ x: number, y: number }>({x: scale.x, y: scale.y});

    useEffect(() => {
        verifyScale.current = { x: scale.x, y: scale.y };
    }, [scale]);

    const createCrossingPointLayer = async (loading: MutableRefObject<boolean>, zoom: boolean = false) => {
        loading.current = true;

        const dataCrossingPointResponse = await executeCrossingPoint({
            type: FetchType.Post,
            apiType: ApiType.WebApi,
            body: {
                wellId: data.Id,
                trace: data.Trace,
                domain: domain,
                datum: datum,
                samplesPerTrace: surveyMetadata?.Header.SamplesPerTrace
            }
        });

        if (dataCrossingPointResponse && verifyScale.current.x === scale.x && verifyScale.current.y === scale.y) {
            if (dataCrossingPointResponse.YPosition[0] > 0 || dataCrossingPointResponse.YPosition[1] > 0) {
                dataCrossingPointResponse.YPosition[0] *= -1;
                dataCrossingPointResponse.YPosition[1] *= -1;
            }

            const increment = lineType === LineType.Inline ? surveyMetadata!.Survey3DInfo.XlineIncrement : surveyMetadata!.Survey3DInfo.InlineIncrement;
            const start = calculator!.getMinTrace(surveyMetadata!);
            const crossingPointX =  getXByTrace(dataGeometryLine.Min + data.Trace * increment, scale.x, start, increment) + 1;
            const crossingPointY = [getYValue(dataCrossingPointResponse.YPosition[0], scale.y, calculator!.calculateSampleInterval(surveyMetadata!)), getYValue(dataCrossingPointResponse.YPosition[1], scale.y, calculator!.calculateSampleInterval(surveyMetadata!))];

            const token = `crossingPoint-${data.Id}`;

            let layer = new VectorLayer({ source: new VectorSource() });

            map?.addLayer(layer);

            layer.getSource()?.clear();

            let newLayer = createCrossingPoint(crossingPointX, crossingPointY, dataCrossingPointResponse.Labels, data.ProfundidadeMedida, data.MesaRotativa, layer, token);
            setWellLayers((data.Id).toString(), { crossingPoint: { layer: newLayer, checked: true, zoom: [crossingPointX, -850] } });

            if (zoom) {
                map?.getView().setCenter([crossingPointX, -850]);
            }
        }

        loading.current = false;
    };

    const crossingPointLine = (checked: boolean, loading: MutableRefObject<boolean>, changed: boolean = false, recreate: boolean = false, zoom: boolean = false) => {
        if ((domain !== null || domain !== undefined) && checked && surveyMetadata) {
            if (!wellLayers[(data.Id).toString()]) {
                createCrossingPointLayer(loading, zoom);
                createPointInMiniMap(checked);
            }
            else if (!wellLayers[(data.Id).toString()]['crossingPoint'] || recreate) {
                createCrossingPointLayer(loading, zoom);
            }
            else {
                let layer = wellLayers[(data.Id).toString()]['crossingPoint'].layer;
                setWellLayers((data.Id).toString(), { crossingPoint: { layer: layer, checked: true, zoom: wellLayers[(data.Id).toString()]['crossingPoint'].zoom } });
                if (changed) {
                    map?.addLayer(layer);
                    setCreateWellInMiniMap([miniMapWells[(data.Id).toString()]]);
                }

                if (zoom) {
                    map?.getView().setCenter(wellLayers[(data.Id).toString()]['crossingPoint'].zoom);
                }
            }
        }
        else if ((domain !== null || domain !== undefined) && !checked && surveyMetadata) {
            let layer = wellLayers[(data.Id).toString()]?.['crossingPoint']?.layer;
            if (layer) {
                map?.removeLayer(wellLayers[(data.Id).toString()]['crossingPoint'].layer);
                setWellLayers((data.Id).toString(), { crossingPoint: { layer: layer, checked: false, zoom: wellLayers[(data.Id).toString()]['crossingPoint'].zoom } });
                setRemoveWellInMiniMap([miniMapWells[(data.Id).toString()]]);
            }
        }
    };

    const createPointInMiniMap = (checked: boolean) => {
        let circleGeometry = new Point([data.XPosition, data.YPosition]);

        let circleFeature = new Feature({
            geometry: circleGeometry
        });

        circleFeature.setStyle(new Style({
            image: new CircleStyle({
                radius: 2,
                stroke: new Stroke({
                    color: '#000000',
                    width: 2
                }),
                fill: new Fill({
                    color: '#000000'
                }),
            })
        }));

        const circleLayer = new VectorLayer({ source: new VectorSource({ features: [circleFeature] }) });

        circleLayer.setZIndex(9999994);
        setMiniMapWells({ [(data.Id).toString()]: circleLayer });
        setCreateWellInMiniMap([circleLayer]);
    };

    return { crossingPointLine: crossingPointLine };
}