import { IconButton, css } from '@mui/material';
import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ExposureIcon from '@mui/icons-material/Exposure';

import { IAbsoluteGain, useSeismicStore } from '../stores/useSeismicStore';
import { AbsoluteGainMenu } from './AbsoluteGainMenu';
import { GeopostPopover } from 'components/GeopostPopover';

export const GainMenu2D = () => {
    const { absoluteGain, setAbsoluteGain } = useSeismicStore(state => ({
        absoluteGain: state.absoluteGain,
        setAbsoluteGain: state.setAbsoluteGain
    }));

    const [ gainFactor, setGainFactor ] = useState(0);
    const lastGainFactorRef = useRef(gainFactor);

    const [gainMenuAnchor, setGainMenuAnchor] = useState<HTMLElement | null>(null);

    const { current: defaultGain } = useRef({...absoluteGain});

    const handleAbsoluteGainChange = useCallback((value: IAbsoluteGain) => {
        console.log(value.min, value.max);
        setAbsoluteGain({
            ...absoluteGain,
            min: value.min,
            max: value.max
        });
    }, [absoluteGain]);

    return (
        <React.Fragment>
            <IconButton id='gains' color='inherit' onClick={(event) => setGainMenuAnchor(current => !current? event.currentTarget : null)}>
                <ExposureIcon color='inherit' titleAccess='Click to show gain options' style={{ cursor: 'pointer', fontSize: 26 }}></ExposureIcon>
            </IconButton>
            <GeopostPopover anchorEl={gainMenuAnchor} onClose={() => setGainMenuAnchor(null)}>
                <AbsoluteGainMenu absoluteGain={absoluteGain} onChange={handleAbsoluteGainChange} defaultAbsoluteGain={defaultGain}/>
            </GeopostPopover>
        </React.Fragment>

    );
};