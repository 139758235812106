import { useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { Metadata } from '../models/types/Metadata';
import { Metadata3D } from '../models/types/Metadata3D';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';
import { IMetadata3DViewerRequest } from '../models/interfaces/requests/IMetadata3DViewerRequest';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { ISurveyMetadata } from 'features/seismic/models/interfaces/ISurveyMetadata';
import { usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';
import { useSeismic3DFeatureFlags } from '../hooks/useSeismic3DFeatureFlags';
import { getMetadataPerformanceDataFromAxiosResponse, getPerformanceDataFromAxiosResponse } from 'features/seismic/utils/performanceUtils';
import { axios } from 'configurations/axios';

export function useMetadata3DViewer(request : IMetadata3DViewerRequest | undefined){
    const setMetadataPerformanceData = usePerformanceMonitoringStore(state => state.setMetadataPerformanceData);
    const seismicFlags = useSeismic3DFeatureFlags();
    const urlParams = getUrlParams();

    const {tenantConfig, jwtToken} = useSessionStore((state) => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));
    const {setSurveyMetadata} = useSeismicStore((state) => ({
        setSurveyMetadata: state.setSurveyMetadata
    }));

    return useQuery<Metadata<Metadata3D>>(
        ['Metadata3DViewer', request],
        async () => {
            const requestSendAt = new Date();

            const response = await axios.get<IResponseAPI<Metadata<Metadata3D>>>(
                tenantConfig?.endpoints.metadata /*'http://localhost:5000'*/ + '/Seismic/Volume/Metadata',
                {
                    params: {
                        ...request
                    },
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                        external_sso_access_token: 'Bearer ' + getUrlParams().externalSSOAccessToken,
                        'x-gp-debug-active': urlParams.performanceMonitoring
                    }
                });

            //temporario pois o well content tem dependencia do seismic store, entao estou alimentando o seismic store do 2D apenas para satisfazer o well content
            setSurveyMetadata({
                VolumePath: response.data.Result.Metadata.VolumePath,
                Type: response.data.Result.Metadata.Type,
                Domain: response.data.Result.Metadata.Domain,
                Size: response.data.Result.Metadata.Size,
                VolumeToken: response.data.Result.Metadata.VolumeToken,
                EBCDIC: '',
                Header: {
                    AverageTraceDistance: response.data.Result.Metadata.Header.AverageTraceDistance,
                    ByteSize: response.data.Result.Metadata.Header.ByteSize,
                    FormatCode: response.data.Result.Metadata.Header.FormatCode,
                    Length: response.data.Result.Metadata.Header.Length,
                    NumberOfTraces: 0,
                    SampleInterval: response.data.Result.Metadata.Header.SampleInterval,
                    SamplesPerTrace: response.data.Result.Metadata.Header.SamplesPerTrace,
                    SampleIntervalUnit: 0,
                    TotalY: 0
                },
                LoadingParams: {
                    CoordinateX: 0,
                    CoordinateY: 0,
                    Inline: 0,
                    ScalarCoordinateX: 0,
                    ScalarCoordinateY: 0,
                    ScalarX: 0,
                    ScalarY: 0,
                    SRID: 0,
                    Xline: 0
                },
                Survey3DInfo: {
                    EntitledInlines: [],
                    EntitledXlines: [],
                    InlineEnd: 0,
                    InlineStart: 0,
                    InlineIncrement: 0,
                    TotalInlines: 0,
                    TotalXlines: 0,
                    XlineEnd: 0,
                    XlineIncrement: 0,
                    XlineStart: 0,
                },
                MinSampleValue: response.data.Result.Metadata.MinSampleValue,
                MaxSampleValue: response.data.Result.Metadata.MaxSampleValue,
                IsIndexed: true,
                IndexDate: '',
                DisplayName: response.data.Result.Metadata.DisplayName,
                SurveyToken: '',
                Attributes: [],
                DimensionNames: {
                    Inline: 'inline',
                    Xline: 'xline',
                    Sample: 'sample',
                    Cdp: 'cdp'
                }
            });

            if (urlParams.performanceMonitoring) {
                const performanceData = getMetadataPerformanceDataFromAxiosResponse(response, requestSendAt, request!.volumeToken);
                if (!!performanceData) {
                    setMetadataPerformanceData(performanceData);
                }
            }

            return response.data.Result;
        },
        {
            enabled: !!request,
            retry: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );
}