import {  css } from '@mui/material';

import { HEIGHT_HEADER_LANE, HEIGHT_HEADER_TITLE_LANE } from 'features/cross-section/consts/layotsConsts';
import { WellTrackingSelect } from './WellTrackingSelect';
import { ICrossSectionItem } from 'features/cross-section/models/interfaces/ICrossSectionItem';

const style = css({
    marginRight: '0px',
    borderStyle:'solid',
    borderWidth: 1,
    paddingLeft: 0,
    borderColor: 'rgb(219 225 231)',
    borderRadius: 3,
    paddingTop: 10,
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'column',

    '& .well-container': {
        position: 'relative',
        flexGrow: 1,
    },
    '& .well-name-header':{
        height: HEIGHT_HEADER_LANE
    },
    '& .well-lanes':{
        display: 'flex',
        flexDirection: 'row',
    },
    '& .well-name': {
        fontSize:'18px',
        width: '100%',
        backgroundColor: 'rgb(4, 31, 60)',
        color: 'white',
        borderRadius: 4,
        textAlign: 'center',
        height: HEIGHT_HEADER_TITLE_LANE
    },
    '& .well-info': {
        fontSize:'16px',
        padding: '3px',
    },
    '& .mapa': {
        height: '100%',
    },
    '& .label-depth': {
        fontSize: 14
    },'& .close-lane':{
        float: 'right',
        height: '100%'
    },'& .makeStyles-root-1':{
        width:'15%'
    },'& .color-unit-div':{
        display:'flex',
        alignItems:'center'
    }
});
export function WellTrackLane({crossSectionItem} : IProps){
    console.log(crossSectionItem);

    return (
        <div css={style} key={`teste`} style={{width: 200}}>
            <div className='well-name-header'>
                <div className='well-name'>
                    {/* <span>{crossSectionItem?.WellName}</span>
                        <button className='close-lane' onClick={closeLane}>X</button> */}
                </div>

                <div className='well-info'>

                </div>
                {/* <div>
                    <MnemonicSelect></MnemonicSelect>
                </div> */}
                <div>
                    <WellTrackingSelect crossSectionItemToken={crossSectionItem.Token}></WellTrackingSelect>
                </div>

            </div>
            <div className='well-container'>
                {/* <LoadingBlock open={isLoadingAnnotations || isLayerLoading}/> */}

                {/* <div id={`map${crossSectionItem?.Token}`} className='mapa' style={{width: laneWidth}} ref={containerMap}></div> */}
            </div>
        </div>
    );
}
interface IProps{
    crossSectionItem: ICrossSectionItem;
};