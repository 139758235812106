import { create } from 'zustand';

import { Seismic3DData } from '../models/classes/Seismic3DData';
import { Survey3DInfo } from 'features/seismic-3d/models/types/Survey3DInfo';

export interface IUse3DGridStore {
    gridSelectedSeismic: Seismic3DData | null,
    gridSelectedSeismicCentroidX: number,
    gridSelectedSeismicCentroidY: number,
    selectedSeismic3DInfo: Survey3DInfo | null,
    initialLoadingRange : number,
    currentLoadingIteration: number,
    totalLoadingIterations: number,
    totalOfLinesToLoadInCurrentIteration: number,
    totalInlineNumbers: number[],
    totalXlineNumbers: number[],
    selectedInlineNumber: number,
    selectedXlineNumber: number,
    skipInlineFactor: number,
    skipXlineFactor: number,
    divisionFactor: number,
    changingDivisionFactor: number,
    screenHeightFactor: number,
    tileWidth: number,
    isInlineSearchLoading: boolean,
    isXlineSearchLoading: boolean,
    searchedInlineNumber: number | null,
    searchedXlineNumber: number | null,
    setTotalInlineNumbers: (totalInlineNumbers: number[]) => void,
    addInlineNumber: (inline: number) => void,
    addInlineNumberRange: (totalInlineNumbers: number[]) => void
    clearInlines: () => void
    setSelectedInlineNumber: (inline: number) => void,
    setTotalXlineNumbers: (totalXlineNumbers: number[]) => void,
    setSelectedXlineNumber: (xline: number) => void,
    clearXlines: () => void
    newGridSelectedSeismic: (seismic3DData: Seismic3DData) => void,
    removeGridSelectedSeismic: () => void,
    setCurrentLoadingIteration: (iterationNumber: number) => void,
    increaseLoadingIteration: () => void,
    setTotalLoadingIterations: (totalLoadingIterations: number) => void,
    setInitialLoadingRange: (initialLoadingRange : number) => void,
    setTotalOfLinesToLoadInCurrentIteration: (total : number) => void,
    setSelectedSeismic3DInfo: (seismic3DInfo: Survey3DInfo) => void,
    setIsXLineSearchLoading: (isLoading: boolean) => void,
    setIsInlineSearchLoading: (isLoading: boolean) => void,
    setSearchedInlineNumber: (value: number | null) => void,
    setSearchedXlineNumber: (value: number | null) => void,
};

export const use3DGridStore = create<IUse3DGridStore>((set) => ({
    gridSelectedSeismic: null,

    gridSelectedSeismicCentroidX: 0,

    gridSelectedSeismicCentroidY: 0,

    selectedSeismic3DInfo: null,

    initialLoadingRange: 10,

    currentLoadingIteration: -1,

    totalLoadingIterations: 3,

    totalOfLinesToLoadInCurrentIteration: 0,

    totalInlineNumbers: [],

    totalXlineNumbers: [],

    selectedInlineNumber: 0,

    selectedXlineNumber: 0,

    skipInlineFactor: 60,

    skipXlineFactor: 60,

    divisionFactor: 30000,

    changingDivisionFactor: 3000,

    screenHeightFactor: 1,

    tileWidth: 1,

    isInlineSearchLoading: false,

    isXlineSearchLoading: false,

    searchedInlineNumber: null,

    searchedXlineNumber: null,

    setTotalInlineNumbers: (totalInlineNumbers) => set({ totalInlineNumbers: totalInlineNumbers }),

    addInlineNumber: inlineNumber => set(state => ({ totalInlineNumbers: [...state.totalInlineNumbers, inlineNumber] })),

    addInlineNumberRange: totalInlineNumbers => set(state => ({ totalInlineNumbers: [...state.totalInlineNumbers, ...totalInlineNumbers] })),

    clearInlines: () => set({ totalInlineNumbers: [] }),

    setSelectedInlineNumber: inlineNumber => set({ selectedInlineNumber: inlineNumber }),

    setTotalXlineNumbers: totalXlineNumbers => set({ totalXlineNumbers: totalXlineNumbers }),

    setSelectedXlineNumber: xlineNumber => set({ selectedXlineNumber: xlineNumber }),

    clearXlines: () => set({ totalXlineNumbers: [] }),

    newGridSelectedSeismic: (seismic3DData) => {
        return set({
            gridSelectedSeismic: seismic3DData
        });
    },

    removeGridSelectedSeismic: () => set({ gridSelectedSeismic: null }),

    setCurrentLoadingIteration: iterationNumber => set({ currentLoadingIteration: iterationNumber }),

    setTotalLoadingIterations: totalLoadingIterations => set({ totalLoadingIterations: totalLoadingIterations }),

    increaseLoadingIteration: () => set((state) => ({ currentLoadingIteration: state.currentLoadingIteration + 1})),

    setInitialLoadingRange: initialLoadingRange => set({ initialLoadingRange: initialLoadingRange }),

    setTotalOfLinesToLoadInCurrentIteration: total => set({ totalOfLinesToLoadInCurrentIteration: total }),

    setSelectedSeismic3DInfo: (seismic3DInfo) => set({ selectedSeismic3DInfo: seismic3DInfo }),

    setIsInlineSearchLoading: (isLoading) => set({isInlineSearchLoading: isLoading}),

    setIsXLineSearchLoading: (isLoading) => set({ isXlineSearchLoading: isLoading }),

    setSearchedInlineNumber: (value) => set({ searchedInlineNumber: value }),

    setSearchedXlineNumber: (value) => set({ searchedXlineNumber: value }),
}));
