import { Alert, CircularProgress, Collapse, IconButton, Snackbar, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMemo, useRef, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { getUrlParams } from '../utils/Seismic3DUrlUtils';
import { useGetGeomByVolumeToken, useGetSeismicDataByVolumeToken} from '../api/useSeismic3DInfoController';
import { useGetGeomByLayerAndFeature } from './projections/api/use3DFeatureInfoController';
import './Seismic3DPanel.css';
import { Seismic3DData } from '../models/classes/Seismic3DData';
import { MeshData } from '../models/types/MeshData';
import { use3DViewerStore } from '../stores/use3DViewerStore';
import { use3DGridStore } from '../stores/use3DGridStore';
import { useSeismic3DFeatureFlags } from '../hooks/useSeismic3DFeatureFlags';
import { Seismic3DNavbar } from './Seismic3DNavbar/Seismic3DNavbar';
import { getCentroidByWKT } from '../utils/OpenLayersUtils';
import { use3DSceneStore } from '../stores/use3DSceneStore';
import { GeopostSceneContainer } from './GeopostSceneContainer';
import { Grid3D } from './Grid3D';
import { useGetGridByToken } from '../api/useSeismic3DGridController';
import { GridXyz3D } from './GridXyz3D';
import { getCountTimeDepth } from '../threejs/utils/ScaleUtils';

function FeatureViewer3DPanel(){

    const [ isLoading, setIsLoading ] = useState(true);

    const [displayGridError, setDisplayGridError] = useState<boolean>(false);

    const [mainFeatureGridToken, setMainFeatureGridToken] = useState<string>();

    const [ selectedSeismics, setSelectedSeismics ] = useState<Seismic3DData[]>([]);

    const [ showAuthenticationError, setShowAuthenticationError ] = useState(false);

    const [canvasHeight, setCanvasHeight] = useState<number>(window.innerHeight);
    const [canvasWidth, setCanvasWidth] = useState<number>(window.innerWidth);

    const { current: urlParams } = useRef(getUrlParams());

    const { current: layerId } = useRef(parseInt(urlParams.layerId));
    const { current: featureId }= useRef(parseInt(urlParams.featureId));
    const { current: featureType } = useRef(urlParams.type);
    const mainFeatureGeomSrid = useRef(3857);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    //const [selectedLayers, setSelectedLayers] = useState<IntersectingLayer[]>([]);
    const [isIntersectingLayersLoading, setIsIntersectingLayersLoading] = useState<boolean>(false);

    const geopostScene = use3DSceneStore(state => state.scene);

    const { mainFeatureVolumeToken, setMainFeatureVolumeToken } = use3DViewerStore(state => ({
        mainFeatureVolumeToken: state.mainFeatureVolumeToken,
        setMainFeatureVolumeToken: state.setMainFeatureVolumeToken
    }), shallow);

    const seismic3DFeatureFlags = useSeismic3DFeatureFlags();

    const { setMainFeatureCentroidX, setMainFeatureCentroidY, setCountTimeDepth, setHeightPixelFactor } = use3DViewerStore(state =>
        ({
            setMainFeatureCentroidX: state.setFeatureCentroidX,
            setMainFeatureCentroidY: state.setFeatureCentroidY,
            setCountTimeDepth: state.setCountTimeDepth,
            setHeightPixelFactor: state.setHeightPixelFactor,
        }), shallow);

    useEffect(() => {
        const type = getUrlParams().type;
        if (type === 'volume') {
            setMainFeatureVolumeToken(getUrlParams().volumeToken);
            const totalLoadingIterationsParam = getUrlParams().totalLoadingIterations;
            if (totalLoadingIterationsParam > 0) {
                setTotalGridLoadingIterations(totalLoadingIterationsParam);
            }
        }
        else if (type === 'grid') {
            setMainFeatureGridToken(getUrlParams().gridToken);
        }

    }, []);

    /*useEffect(() => {
        if (metadata3DSeismicError) {
            setDisplayGridError(metadata3DSeismicError);
        }
    }, [metadata3DSeismicError]);*/

    const {data: mainFeatureLayerGeomData, refetch: fetchGeomByLayerAndFeature, error: layerError} = useGetGeomByLayerAndFeature(layerId, featureId,
        featureType, mainFeatureGeomSrid.current);

    const { data: mainFeatureGridData, error: gridError } = useGetGridByToken(mainFeatureGridToken, mainFeatureGeomSrid.current);

    //const previousIntersectingSeismics = useRef<IntersectingShape<Seismic3DData>[]>([]);
    //const previousIntersectingLayers = useRef<IntersectingShape<IntersectingLayer>[]>([]);
    const intersectingLayers = use3DViewerStore((state) => state.selectedLayers);

    const isSeismicSelected = (seismicData : Seismic3DData) =>
        selectedSeismics.find((seismic) => seismic.volumeToken === seismicData.volumeToken) !== undefined ? true : false;

    const { data: mainFeatureSeismicData, error: seismicDataError } = useGetSeismicDataByVolumeToken(mainFeatureVolumeToken);
    const { data: mainFeatureSeismicGeomData } = useGetGeomByVolumeToken(mainFeatureVolumeToken);

    const mainFeatureGeomData = use3DViewerStore(state => state.mainFeatureGeomData);
    const setMainFeatureGeomData = use3DViewerStore(state => state.setMainFeatureGeomData);

    useEffect(() => {
        if (mainFeatureGeomData) {
            const [ mainFeatureCentroidX, mainFeatureCentroidY ] = getCentroidByWKT(mainFeatureGeomData?.CentroidWKT);
            setMainFeatureCentroidX(mainFeatureCentroidX);
            setMainFeatureCentroidY(mainFeatureCentroidY);
        }
    }, [mainFeatureGeomData]);

    const setTotalGridLoadingIterations = use3DGridStore(state => state.setTotalLoadingIterations);

    const connectionError = useMemo(() => seismicDataError as AxiosError | undefined ?? gridError as AxiosError | undefined ?? layerError  as AxiosError | undefined, [seismicDataError, gridError, layerError]);

    useEffect(() => {
        setMainFeatureGeomData(mainFeatureLayerGeomData ?? mainFeatureSeismicGeomData);
    }, [mainFeatureLayerGeomData, mainFeatureSeismicGeomData]);

    useEffect(() => {
        if (mainFeatureSeismicData) {
            setGridSelectedSeismic(mainFeatureSeismicData);
            setCountTimeDepth(getCountTimeDepth(mainFeatureSeismicData.sampleInterval, mainFeatureSeismicData.sampleIntervalUnit, mainFeatureSeismicData.samplesPerTrace));
            setHeightPixelFactor(mainFeatureSeismicData.samplesPerTrace);
        }
    }, [mainFeatureSeismicData]);

    useEffect(() => {
        if (intersectingLayers) {
            setIsIntersectingLayersLoading(false);
        }
    }, [intersectingLayers]);
    const gridSelectedSeismic = use3DGridStore((state) => state.gridSelectedSeismic);
    const setGridSelectedSeismic = use3DGridStore((state) => state.newGridSelectedSeismic);
    const previousGridSelectedSeismic = useRef<Seismic3DData | null>(gridSelectedSeismic);

    useEffect(() => {
        if (connectionError?.response?.status === 401) {
            setShowAuthenticationError(true);
        }
    }, [connectionError]);

    useEffect(() => {
        if (mainFeatureGeomData) {
            if (!mainFeatureGridData) {
                const meshData : MeshData = {
                    geomWKT: mainFeatureGeomData.GeomWKT,
                    centroidWKT: mainFeatureGeomData.CentroidWKT,
                    meshColor: '#0352ff',
                    meshToken: 'main-feature'
                };
                const mesh = geopostScene?.addMainMesh(meshData);
                geopostScene.add(mesh!);
            }
        }
    }, [mainFeatureGeomData]);

    const sceneContainerDivRef = useRef<HTMLDivElement>(null);

    const updateWindowDimensions = () => {
        setCanvasHeight(window.innerHeight);
        setCanvasWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
    }, []);

    useEffect(() => {
        if (mainFeatureGridData) {
            setMainFeatureCentroidX(mainFeatureGridData.CentroidX);
            setMainFeatureCentroidY(mainFeatureGridData.CentroidY);
            setCountTimeDepth(Math.abs(mainFeatureGridData.Max - mainFeatureGridData.Min));
            setHeightPixelFactor(1000);
            setMainFeatureGeomData({
                GeomWKT: mainFeatureGridData.GeomWkt,
                CentroidWKT: mainFeatureGridData.CentroidWkt
            });
        }
    }, [mainFeatureGridData]);

    return (
        <React.Fragment>
            <Seismic3DNavbar/>
            {
                mainFeatureGridData
                &&
                <GridXyz3D
                    onLoadStart={() => setIsLoading(true)}
                    onLoadEnd={() => setIsLoading(false)}
                    grid={mainFeatureGridData}
                    mainFeatureSrid={mainFeatureGeomSrid.current}
                    opacity={1}
                    visible={true}
                />
            }
            {/*
                isGridLoading
                &&
                <Box
                    sx={{
                        position: 'absolute',
                        top: '10vh',
                        left: '40vw',
                        width: '200px',
                        height: '50px',
                        backgroundColor: 'white',
                        alignItems: 'center',
                        display: 'flex',
                        borderRadius: 10,
                        justifyContent: 'center',
                        zIndex: 9999,
                        boxShadow: 5
                    }}
                >
                    <CircularProgress size={25}></CircularProgress>
                    <Stack sx={{marginLeft: '20px'}}> Loading grid... </Stack>
                </Box>*/
            }
            <Grid3D
                onLoadStart={() => {}}
                onLoadEnd={() => setIsLoading(false)}
            />
            <Collapse
                sx={{position: 'absolute', top: '10vh', left:'40vw', zIndex: 9999}}
                in={displayGridError}
            >
                <Alert
                    severity='error'
                    action={
                        <IconButton
                            aria-label='close'
                            color='inherit'
                            size='small'
                            onClick={
                                () => setDisplayGridError(false)
                            }
                        >
                            <CloseIcon fontSize='inherit'/>
                        </IconButton>
                    }
                >
                    <strong> Can not load seismic data for the selected volume. </strong>
                </Alert>
            </Collapse>
            <Stack direction='row' spacing={5}>
                <Stack width={canvasWidth}>
                    <div id='seismic-3d-canvas-container' ref={sceneContainerDivRef} style={{width: canvasWidth}}>
                        <GeopostSceneContainer height={canvasHeight - 64} width={canvasWidth}/>
                    </div>
                </Stack>
            </Stack>
            <Snackbar open={isLoading} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert
                    severity='info'
                    variant='filled'
                    iconMapping={{
                        info: <CircularProgress size={17}/>
                    }}
                >
                    Loading contents...
                </Alert>
            </Snackbar>
            <Snackbar open={!!connectionError} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert
                    variant='filled'
                    severity='error'
                >
                    Authentication failed.
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
};

export {FeatureViewer3DPanel};