import { useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { SeismicAttribute } from 'features/seismic/models/interfaces/ISurveyMetadata';

export function useGetSeismicAttributes(volumeToken: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('listVolumeAttributes', async () => {
        const response = await axios.request<IResponseAPI<SeismicAttribute[]>>({
            method: 'get',
            params: {
                volumeToken: volumeToken
            },
            url: tenantConfig?.endpoints?.attributes + '/Seismic/Attributes/All',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        enabled: volumeToken !== ''
    }
    );
}