import { Box, Button, Grid } from '@mui/material';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';
import { Fragment, useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDropdownDataSubGroup } from 'fileIndexing/otherComponents/GeopostDropdownDataSubGroup';
import { GeopostDropdownDataGroup } from 'fileIndexing/otherComponents/GeopostDropdownDataGroup';
import { GeopostCheckbox } from 'fileIndexing/otherComponents/GeopostCheckbox';
import { GeopostAutocompleteEntities } from 'fileIndexing/otherComponents/GeopostAutocompleteEntities';
import { GeopostDropdownDataSource } from 'fileIndexing/otherComponents/GeopostDropdownDataSource';

import { ISendFilter } from '../interfaces/IFiles';

export const FilesFilterHeader = ({ formData, handleTable, handleOpenCloudBucketModal, handleOpenIndexFilteredModal }: Props) => {
    const formPropData = useForm<ISendFilter>({ defaultValues: formData });

    const dataGroupToken = formPropData.watch('DataGroupFilter');

    useEffect(() => {
        let groupToken = formPropData.getValues('DataGroupFilter');
        if (groupToken !== formData.DataGroupFilter) {
            formPropData.setValue('DataGroupFilter', formData.DataGroupFilter);
        }

        let subGroupToken = formPropData.getValues('DataSubGroupFilter');
        if (subGroupToken !== formData.DataSubGroupFilter) {
            formPropData.setValue('DataSubGroupFilter', formData.DataSubGroupFilter);
        }

        let entityTerm = formPropData.getValues('EntityTypeFilter');
        if (entityTerm !== formData.EntityTypeFilter) {
            formPropData.setValue('EntityTypeFilter', formData.EntityTypeFilter);
            // setEntityTermDropdown(formData.EntityTypeFilter.label);
        }

        let dataSourceToken = formPropData.getValues('DataSourceFilter');
        if (dataSourceToken !== formData.DataSourceFilter) {
            formPropData.setValue('DataSourceFilter', formData.DataSourceFilter);
        }
    }, [formData]);

    const locationTypeItems = [
        {
            id: -1,
            label: 'All',
        },
        {
            id: 0,
            label: 'Stagging',
        },
        {
            id: 1,
            label: 'Internal Network',
        },
        {
            id: 2,
            label: 'External Medias',
        },
    ];

    const transmittalItems = [
        {
            id: '',
            label: 'None',
        }
    ];

    const tagsItems = [
        {
            id: '',
            label: 'None',
        }
    ];

    const mediaItems = [
        {
            id: '',
            label: 'None',
        }
    ];

    return (
        <Fragment>
            <Box style={{ width: '100%' }}>
                <FormContainer<ISendFilter> defaultValues={formData ?? {}} formContext={formPropData} onSuccess={(data) => handleTable(data)}>
                    <Grid spacing={2} container>
                        <Grid spacing={5} container item direction="row">
                            <Grid xs={2} item>
                                <GeopostDropdown fieldName={'LocationType'} label={'Location Type'} items={locationTypeItems} />
                            </Grid>
                            <Grid xs={2} item>
                                <GeopostDropdownDataGroup fieldName={'DataGroupFilter'} label={'Data Group Filter'} noneItem={true} required={false} />
                            </Grid>
                            <Grid xs={2} item>
                                <GeopostDropdownDataSubGroup fieldName={'DataSubGroupFilter'} label={'Data SubGroup Filter'} dataGroupToken={dataGroupToken} noneItem={true} required={false} disabled={dataGroupToken === undefined || dataGroupToken === '' ? true : false} />
                            </Grid>
                            <Grid xs={2} item>
                                <GeopostTextField fieldName={'Filter'} label={'Search file'} required={false} />
                            </Grid>
                            <Grid xs={2} item>
                                <GeopostAutocompleteEntities fieldName={'EntityTypeFilter'} label={'Entity'} required={false} />
                            </Grid>
                            <Grid xs={2} item>
                                <GeopostDropdownDataSource fieldName={'DataSourceFilter'} label={'Data Source'} noneItem={true} required={false} />
                            </Grid>
                            {/* <Grid xs={2} item>
                                <GeopostDropdown fieldName={'TransmittalFilter'} label={'Transmittals'} items={transmittalItems} required={false} />
                            </Grid> */}
                        </Grid>
                        <Grid spacing={5} container item direction="row" alignItems='center'>
                            <Grid xs={1.6} item>
                                <GeopostDropdown fieldName={'TagFilter'} label={'Tags'} items={tagsItems} required={false} />
                            </Grid>
                            <Grid xs={1.6} item>
                                <GeopostDropdown fieldName={'MediaFilter'} label={'Media'} items={mediaItems} required={false} />
                            </Grid>
                            <Grid xs={1.6} item>
                                <GeopostCheckbox fieldName={'WithoutSubgroupFilter'} label={'Without Subgroup'} />
                            </Grid>
                            <Grid xs={1.6} item>
                                <GeopostCheckbox fieldName={'WithoutEntitiesFilter'} label={'Without Entities'} />
                            </Grid>
                            <Grid xs={2.2} item>
                                <Button fullWidth variant='contained' color='primary' onClick={() => handleOpenCloudBucketModal()}>
                                    SCAN DATA SOURCE
                                </Button>
                            </Grid>
                            <Grid xs={2} item>
                                <Button fullWidth variant='contained' color='primary' onClick={() => handleOpenIndexFilteredModal()}>
                                    INDEX FILTERED FILES
                                </Button>
                            </Grid>
                            <Grid xs={1.4} item>
                                <Button fullWidth variant='contained' color='primary' type='submit'>
                                    FILTER TABLE
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormContainer>
            </Box>
        </Fragment>
    );
};

interface Props {
    formData: ISendFilter;
    handleTable: (value: ISendFilter) => void;
    handleOpenCloudBucketModal: () => void;
    handleOpenIndexFilteredModal: () => void;
}