import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';

import { IAnnotation } from 'features/seismic/models/interfaces/IAnnotation';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { useAPI } from 'hooks/useAPI';
import { ApiType } from 'models/enums/ApiType';
import { FetchType } from 'models/enums/FetchType';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { WellAnnotationType } from 'features/seismic/models/enums/WellAnnotationType';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { createPointFeature, createLineFeature, getXValue, getYValue } from 'features/seismic/utils/wellsUtils';
import { LineType } from 'features/seismic/models/enums/LineType';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';

export function useGetAnnotation(data: IWell, domain: AmplitudeDomain, datum: number, dataGeometryLine: ILineString) {
    const { calculator, surveyMetadata, scale, wellLayers, setWellsLayers } = useSeismicStore(state => ({
        calculator: state.calculator,
        scale: state.scale,
        surveyMetadata: state.surveyMetadata,
        wellLayers: state.wellsLayers,
        setWellsLayers: state.setWellsLayers,
    }));

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const { lineType } = useLine3DNavigationStore(state => ({
        lineType: state.lineType
    }));

    const { execute: executeAnnotation } = useAPI<IAnnotation>('Wells/WellAnnotationItem/GetAllAnnotationForSeismic');

    const getAnnotationType = (type: string) => {
        let typeChosen;

        switch (type) {
        case 'formationTop':
            typeChosen = WellAnnotationType.FormationTop;
            break;
        case 'hydrocarbon':
            typeChosen = WellAnnotationType.Hydrocarbon;
            break;
        case 'formationTest':
            typeChosen = WellAnnotationType.FormationTest;
            break;
        }

        return typeChosen;
    };

    const zIndex: { [key: string]: number } = {
        formationTop: 99999992,
        hydrocarbon: 99999993,
        formationTest: 99999994,
    };

    const [annotation, setAnnotation] = useState<{ [type: string]: { authors: { name: string, authorId: number, selected: boolean; permission: boolean }[]; loading: boolean; } }>({
        formationTop: { authors: [], loading: true },
        hydrocarbon: { authors: [], loading: true },
        formationTest: { authors: [], loading: true }
    });

    const getAnnotation = (annotationType: string) => {
        return executeAnnotation({
            type: FetchType.Post,
            apiType: ApiType.WebApi,
            body: {
                wellId: data.Id,
                type: getAnnotationType(annotationType),
                trace: data.Trace,
                domain: domain,
                datum: datum
            }
        });
    };

    const createLayers = (dataRequest: IAnnotation, annotationType: string, addRemoveLayer: boolean) => {
        let layersWithAuthor: { [typeKey: string]: { layer: VectorLayer<VectorSource>, checked: boolean } } = {};
        let authors: { name: string, authorId: number, selected: boolean, permission: boolean }[] = [];

        for (let i = 0; i < dataRequest.items.length; i++) {
            let author: { name: string, authorId: number, selected: boolean, permission: boolean };

            if (dataRequest.items[i].Permission) {
                let annotationFeatures: Feature<Point | LineString>[] = [];

                for (let j = 0; j < dataRequest.items[i].Items.length; j++) {
                    // possivelmente irá mudar
                    const token = `${annotationType}-${data.Id}-${dataRequest.items[i].Items[j].Id}`;
                    let dateConverted = new Date(dataRequest.items[i].Items[j].Date);
                    let date = `${dateConverted.getDate()}-${dateConverted.getMonth() + 1}-${dateConverted.getFullYear()}`;

                    if (dataRequest.items[i].Items[j].YPosition[0] > 0 || dataRequest.items[i].Items[j].YPosition[1] > 0) {
                        dataRequest.items[i].Items[j].YPosition[0] *= -1;
                        dataRequest.items[i].Items[j].YPosition[1] *= -1;
                    }

                    if (annotationType === 'formationTop') {
                        const increment = lineType === LineType.Inline ? surveyMetadata!.Survey3DInfo.XlineIncrement : surveyMetadata!.Survey3DInfo.InlineIncrement;
                        const start = calculator!.getMinTrace(surveyMetadata!);
                        const pointx = getXValue(dataRequest.items[i].Items[j].XPosition, scale.x, dataGeometryLine.Min, start, increment);
                        const pointY = getYValue(dataRequest.items[i].Items[j].YPosition[0], scale.y, calculator!.calculateSampleInterval(surveyMetadata!));

                        let feature = createPointFeature(pointx, pointY, dataRequest.items[i].Items[j].Labels[0], annotationType, token, Math.round(dataRequest.items[i].Items[j].Depths[0]), data.MesaRotativa, date);
                        annotationFeatures.push(feature);
                    }
                    else if (annotationType === 'hydrocarbon' || annotationType === 'formationTest') {
                        const increment = lineType === LineType.Inline ? surveyMetadata!.Survey3DInfo.XlineIncrement : surveyMetadata!.Survey3DInfo.InlineIncrement;
                        const start = calculator!.getMinTrace(surveyMetadata!);
                        const pointx = getXValue(dataRequest.items[i].Items[j].XPosition, scale.x, dataGeometryLine.Min, start, increment);
                        const pointY = [getYValue(dataRequest.items[i].Items[j].YPosition[0], scale.y, calculator!.calculateSampleInterval(surveyMetadata!)), getYValue(dataRequest.items[i].Items[j].YPosition[0], scale.y, calculator!.calculateSampleInterval(surveyMetadata!))];

                        let md = [Math.round(dataRequest.items[i].Items[j].Depths[0]), Math.round(dataRequest.items[i].Items[j].Depths[1])];
                        let tvd = [Math.round(dataRequest.items[i].Items[j].YPosition[0]) * -1, Math.round(dataRequest.items[i].Items[j].YPosition[1]) * -1];
                        let feature = createLineFeature(pointx, pointY, dataRequest.items[i].Items[j].Labels[0], annotationType, token, md, tvd, data.MesaRotativa, date);
                        annotationFeatures.push(feature);
                    }
                }

                author = { name: dataRequest.items[i].User, authorId: dataRequest.items[i].UserId, selected: true, permission: dataRequest.items[i].Permission };

                let newLayer = new VectorLayer({ source: new VectorSource({ features: annotationFeatures }) });

                layersWithAuthor = { ...layersWithAuthor, [`${annotationType}_${dataRequest.items[i].UserId}`]: { layer: newLayer, checked: true } };

                newLayer.setZIndex(zIndex[annotationType]);
                if (addRemoveLayer) {
                    map?.addLayer(newLayer);
                }
            }
            else {
                author = { name: dataRequest.items[i].User, authorId: dataRequest.items[i].UserId, selected: false, permission: dataRequest.items[i].Permission };
            }
            authors.push(author);
        }

        if (layersWithAuthor) {
            setWellsLayers((data.Id).toString(), layersWithAuthor);
        }

        setAnnotation(prevState => ({ ...prevState, [annotationType]: { authors: authors, loading: false } }));
    };

    const showLayer = (cheked: boolean, annotationType: string) => {
        if (annotation[annotationType]) {
            for (let i = 0; i < annotation[annotationType].authors.length; i++) {
                if (cheked && annotation[annotationType].authors[i].selected && wellLayers[(data.Id).toString()][`${annotationType}_${annotation[annotationType].authors[i].authorId}`]) {
                    let layer = wellLayers[(data.Id).toString()][`${annotationType}_${annotation[annotationType].authors[i].authorId}`].layer;
                    map?.addLayer(layer);
                    setWellsLayers((data.Id).toString(), { [`${annotationType}_${annotation[annotationType].authors[i].authorId}`]: { layer: layer, checked: true } });
                }
                else if ((!cheked || !annotation[annotationType].authors[i].selected) && wellLayers[(data.Id).toString()][`${annotationType}_${annotation[annotationType].authors[i].authorId}`]) {
                    let layer = wellLayers[(data.Id).toString()][`${annotationType}_${annotation[annotationType].authors[i].authorId}`].layer;
                    map?.removeLayer(layer);
                    setWellsLayers((data.Id).toString(), { [`${annotationType}_${annotation[annotationType].authors[i].authorId}`]: { layer: layer, checked: false } });
                }
            }
        }
    };

    const verifyScale = useRef<{ x: number, y: number }>({x: scale.x, y: scale.y});

    useEffect(() => {
        verifyScale.current = { x: scale.x, y: scale.y };
    }, [scale]);

    const annotations = async (checked: boolean, annotationType: string, loading: MutableRefObject<boolean>, addRemoveLayer: boolean = false, recreate: boolean = false) => {
        if ((domain !== null || domain !== undefined) && surveyMetadata && checked) {
            if (annotation[annotationType].authors.length === 0 || recreate) {
                loading.current = true;

                const dataRequest = await getAnnotation(annotationType);

                // console.log(scale);
                if (dataRequest && verifyScale.current.x === scale.x && verifyScale.current.y === scale.y) {
                    createLayers(dataRequest, annotationType, addRemoveLayer);
                }

                loading.current = false;
            }
        }

        if (addRemoveLayer && annotation[annotationType].authors.length > 0 && !recreate) {
            showLayer(checked, annotationType);
        }
    };

    const authorFilter = (index: number, annotationType: string, chekedAuthor: boolean, chekedAnnotation: boolean) => {
        if (annotation[annotationType]) {
            if (chekedAuthor && chekedAnnotation) {
                map?.addLayer(wellLayers[(data.Id).toString()][`${annotationType}_${annotation[annotationType].authors[index].authorId}`].layer);
            }
            else {
                map?.removeLayer(wellLayers[(data.Id).toString()][`${annotationType}_${annotation[annotationType].authors[index].authorId}`].layer);
            }
        }
    };

    return {
        annotations: annotations, annotation: annotation, setAnnotation: setAnnotation, authorFilter: authorFilter
    };
}