import { IconButton } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useTranslation } from 'react-i18next';

import { useWebTourCheckUserRead, useWebTourRegisterUserRead } from './api/useWebTourController';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';

export function SeismicWebTour() {
    const initialStep = 0;
    const { t } = useTranslation();

    const [runTour, setRunTour] = useState<boolean>(false);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status, type } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status) && type === 'tour:end' ) {
            setRunTour(false);
            if (firstTime.current) {
                firstTime.current = false;
            }
        }
    };

    const featureFlags = useFeatureFlags();

    const steps: Step[] = [
        {
            target: '#help',
            content: t('webTour.clickToReview'),
            disableBeacon: true,
        },
        {
            target: '#metadata',
            content: t('webTour.metadata'),
            disableBeacon: true
        },
        {
            target: '#colorbar',
            content: t('webTour.colorbar'),
            disableBeacon: true
        },
        {
            target: '#colorbar_inv',
            content: t('webTour.polarity'),
            disableBeacon: true
        },
        {
            target: '#scalebar',
            content: t('webTour.scale'),
            disableBeacon: true
        },
        {
            target: '#invert_direction',
            content: t('webTour.invertDirection'),
            disableBeacon: true
        },
        {
            target: '#apply_antialias',
            content: t('webTour.applyAntialias'),
            disableBeacon: true
        },
        {
            target: '#line-selector-type',
            content: t(!featureFlags.featureFlags?.seismic2D.TimeSlice ? 'webTour.lineSelectorType' : 'webTour.lineSelectorTypeWithZSlice') + (featureFlags.featureFlags?.seismic2D.Shortcuts ? t('webTour.lineSelectorType_shortcut') : ''),
            disableBeacon: true
        },
        {
            target: '#line-selector-3d-skip',
            content: t(!featureFlags.featureFlags?.seismic2D.Shortcuts ? 'webTour.lineSelector3DSkip' : 'webTour.lineSelector3DSkip_shortcut') + (featureFlags.featureFlags?.seismic2D.Shortcuts ? t('webTour.lineSelector3DSkip_shortcut') : ''),
            disableBeacon: true
        },
        {
            target: '#gains',
            content: t('webTour.gain') + (featureFlags.featureFlags?.seismic2D.Shortcuts ? t('webTour.gain_shortcut') : ''),
            disableBeacon: true
        },
        {
            target: '#trace-header',
            content: t('webTour.traceHeader'),
            disableBeacon: true
        },
        {
            target: '#graticule',
            content: t('webTour.graticule'),
            disableBeacon: true
        },
        {
            target: '#projections',
            content: t('webTour.projections'),
            disableBeacon: true
        },
        {
            target: '#attributes',
            content: t('webTour.attributes'),
            disableBeacon: true
        },
        {
            target: '#wells',
            content: t('webTour.wells'),
            disableBeacon: true
        },
        {
            target: '#interpretation',
            content: t('webTour.interpretations'),
            disableBeacon: true
        },
        {
            target: '#miniMap',
            content: t('webTour.miniMap'),
            disableBeacon: true
        },
        {
            target: '#map',
            content: t('webTour.viewerPanel'),
            disableBeacon: true,
            placement: 'center'
        },
    ];

    const firstTime = useRef<boolean>(false);

    const webTourType = 'seismic-viewer-2d';
    const { data: dataCheckUserRead } = useWebTourCheckUserRead(webTourType);
    const { data: dataRegisterUserRead } = useWebTourRegisterUserRead(webTourType, firstTime.current);

    useEffect(() => {
        if (dataCheckUserRead === false) {
            setRunTour(true);
            firstTime.current = true;
        }
    }, [dataCheckUserRead]);

    return (
        <Fragment>
            <Joyride
                callback={handleJoyrideCallback}
                continuous
                locale={{
                    back: t('webTour.back'),
                    last: t('webTour.last'),
                    next: t('webTour.next'),
                    skip: t('webTour.skip')
                }}
                hideCloseButton
                run={runTour}
                scrollToFirstStep
                showProgress={false}
                showSkipButton
                steps={steps}
                styles={{
                    options: {
                        arrowColor: 'rgb(4, 31, 60)',
                        primaryColor: '#000',
                        width: 'fit-content',
                        zIndex: 1000,
                    }
                }}
            />
            <Fragment>
                <IconButton
                    id='help'
                    style={{color: 'rgba(255, 255, 255, 1)'}}
                    onClick={() => setRunTour(true)}
                    title='Click to start the guide tour'>
                    <HelpCenterIcon style={{fontSize:'24px'}}/>
                </IconButton>
            </Fragment>
        </Fragment>
    );
}