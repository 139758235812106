import { Fragment } from 'react';
import { Navbar } from 'fileIndexing/otherComponents/Navbar';
import { NavigationMenu } from 'fileIndexing/otherComponents/NavigationMenu';

import { DataSourceTable } from '../components/dataSourceTable';

export function DataSourceMainPage () {
    return (
        <Fragment>
            <Navbar />
            <NavigationMenu />
            <DataSourceTable />
        </Fragment>
    );
}