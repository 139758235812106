import React, { useEffect, useState } from 'react';

import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { IUseGetIntersectingFeaturesShapeByLayerAndGeomParams } from 'features/seismic-3d/models/interfaces/requests/IUseGetIntersectingFeaturesShapeByLayerAndGeomParams';
import { IntersectingLayer } from 'features/seismic-3d/models/types/IntersectingLayer';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { useGetIntersectingFeaturesShapeByLayerAndGeom, useGetLayersIntersectingInGeom } from '../projections/api/use3DFeatureInfoController';

interface IIntersectingLayerCheckboxProps {
    layerData: IntersectingLayer,
    checked: boolean,
    selectCallback: (layerData: IntersectingLayer, layerGeom : FeatureGeom) => void,
    unselectCallback : (layerData: IntersectingLayer) => void
}

export const IntersectingLayerCheckbox = ({ layerData, checked, selectCallback, unselectCallback } : IIntersectingLayerCheckboxProps) => {
    const [params, setParams] = useState<IUseGetIntersectingFeaturesShapeByLayerAndGeomParams>();
    const srid = use3DViewerStore(state => state.mainFeatureSrid);
    const mainFeatureGeomData = use3DViewerStore(state => state.mainFeatureGeomData);

    const { data: layerGeomData, isLoading } = useGetIntersectingFeaturesShapeByLayerAndGeom(params);

    useEffect(() => {
        if (layerGeomData) {
            selectCallback(layerData, layerGeomData);
        }
    }, [layerGeomData]);

    const handleSelect = () => {
        if (mainFeatureGeomData) {
            setParams({
                layerId: layerData.Id,
                geomWkt: mainFeatureGeomData.GeomWKT,
                geomSrid: srid,
                resultSrid: srid
            });
        }
    };

    const handleUnSelect = () => {
        unselectCallback(layerData);
        setParams(undefined);
    };

    return (
        <>
            <GeopostCheckbox
                checked={checked}
                loading={isLoading}
                onSelect={handleSelect}
                onUnselect={handleUnSelect}
            />
        </>
    );
};