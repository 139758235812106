import * as THREE from 'three';

export class GeopostTileGeometry extends THREE.PlaneGeometry {
    public constructor (firstCoordinate: number[], nextTileFirstCoordinate: number[], planeTopY: number, planeBottomY: number) {
        const points : THREE.Vector3[] = [];
        points.push(new THREE.Vector3(firstCoordinate[0], -planeTopY, firstCoordinate[1]));
        points.push(new THREE.Vector3(nextTileFirstCoordinate[0], -planeTopY, nextTileFirstCoordinate[1]));
        points.push(new THREE.Vector3(firstCoordinate[0], -planeBottomY, firstCoordinate[1]));
        points.push(new THREE.Vector3(nextTileFirstCoordinate[0], -planeBottomY, nextTileFirstCoordinate[1]));

        super();
        this.setFromPoints(points);
    };
}