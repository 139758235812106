import { useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import { useSessionStore } from 'session/useSessionStore';

export const ViewerSecureRoute = () => {

    const tenantConfig = useSessionStore(state => state.tenantConfiguration);

    const oktaAuth = useMemo(() => {
        const oktaAuthConfig = tenantConfig?.oktaAuthConfiguration;
        if (!!oktaAuthConfig) {
            return new OktaAuth({
                issuer: oktaAuthConfig.issuerUri,
                clientId: oktaAuthConfig.clientId,
                redirectUri: window.location.origin + '/logincallback',
                scopes: ['openid', 'profile', 'email', 'offline_access', 'TgsSeismic.Services'],
                pkce: true,
                responseType: ['id_token', 'token'],
            });
        } else {
            return null;
        }
    }, [tenantConfig]);

    /*
    const oktaAuth = useMemo(() => new OktaAuth({
        issuer: 'https://trial-6285149.okta.com/oauth2/default',
        clientId: '0oa9l8sk61nHV1Cua697',
        redirectUri: window.location.origin + '/login/callback',
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        responseType: ['id_token', 'token']
    }), []);
    */

    const navigate = useNavigate();

    const restoreOriginalUri = (_oktaAuth: object, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        !!tenantConfig?.oktaAuthConfiguration ?
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Outlet/>
            </Security>
            :
            <Outlet/>
    );
};