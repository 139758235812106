import { Fragment } from 'react';
import { Navbar } from 'fileIndexing/otherComponents/Navbar';
import { NavigationMenu } from 'fileIndexing/otherComponents/NavigationMenu';

import { InstituitionsTable } from '../components/InstitutionsTable';

export function InstitutionMainPage () {
    return (
        <Fragment>
            <Navbar />
            <NavigationMenu />
            <InstituitionsTable />
        </Fragment>
    );
}