import { useGetAllDataGroups } from 'fileIndexing/taxonomies/useTaxonomy';
import { useEffect, useState } from 'react';

import { GeopostDropdown } from './GeopostDropdown';

export function GeopostDropdownDataGroup({ fieldName, label, noneItem = false, required = false }: Props) {
    const [dataGroupItems, setDataGroupItems] = useState<{ id: string; label: string; }[]>([]);

    const { data: dataGroup, isLoading } = useGetAllDataGroups();

    useEffect(() => {
        if (dataGroup) {
            let createDataGroupItems: {id: string, label: string}[] = [];

            if (noneItem) {
                createDataGroupItems.push({
                    id: '',
                    label: 'None'
                });
            }

            let getAllDataGroups = dataGroup.map(dataGroup => ({
                id: dataGroup.Token,
                label: dataGroup.Name
            }));

            getAllDataGroups.forEach((item) => createDataGroupItems.push(item));

            setDataGroupItems(createDataGroupItems);
        }
    }, [dataGroup]);

    return (
        <GeopostDropdown fieldName={fieldName} label={label} items={dataGroupItems} loading={isLoading} required={required} />
    );
}

interface Props {
    fieldName: string;
    label: string;
    noneItem?: boolean;
    required?: boolean;
}