import { css } from '@mui/material';
import { LoadError } from '@react-pdf-viewer/core';

const style = css({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    '& .container-label': {
        backgroundColor: '#e53e3e',
        borderRadius: '0.25rem',
        color: '#fff',
        padding: '0.5rem'
    }
});

export function RenderError(error: LoadError) {
    let message = '';

    console.log(error);

    switch (error.name) {
    case 'InvalidPDFException':
        message = 'The document is invalid or corrupted';
        break;
    case 'MissingPDFException':
        message = 'The document is missing';
        break;
    case 'UnexpectedResponseException':
        message = 'Unexpected server response';
        break;
    default:
        message = 'Cannot load the document';
        break;
    }

    return (
        <div css={style}>
            <div className='container-label'>
                {message}
            </div>
        </div>
    );
};