import { Fragment } from 'react';
import { Navbar } from 'fileIndexing/otherComponents/Navbar';
import { NavigationMenu } from 'fileIndexing/otherComponents/NavigationMenu';

import { FilesTable } from '../components/FilesTable';

export function FilesMainPage() {
    return (
        <Fragment>
            <Navbar />
            <NavigationMenu />
            <FilesTable />
        </Fragment>
    );
}