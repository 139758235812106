import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

export class SignalR {
    private url: string;
    private connected: boolean;
    private connection: HubConnection;

    constructor(url: string, jwtToken: string) {
        this.connected = false;
        this.url = url;
        this.connection = new HubConnectionBuilder()
            .withUrl(this.url, {
                withCredentials: false,
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            }).withAutomaticReconnect([1000, 3000, 6000])
            .build();
        this.start();
    }

    async start() {
        await this.connection.start();
        this.connected = true;
    }

    async getConnectionId(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const intervalId = setInterval(() => {

                if (this.connected) {
                    clearInterval(intervalId);
                    const connectionId = this.connection.connectionId;
                    if (connectionId) {
                        resolve(connectionId);
                    } else {
                        reject(new Error('Connection ID is null'));
                    }
                }
            }, 300);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listen(event: string, callback: (...args: any[]) => void) {
        this.connection.on(event, callback);
    }
}
