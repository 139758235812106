import * as THREE from 'three';
import { Geometry, LineString, MultiLineString, MultiPolygon, Point, Polygon } from 'ol/geom';
import WKT from 'ol/format/WKT';
import { Coordinate } from 'ol/coordinate';
import { getCenter } from 'ol/extent';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import * as olExtent from 'ol/extent';
import { scale } from 'ol/size';

import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { MeshData } from 'features/seismic-3d/models/types/MeshData';
import { lineRenderOrder } from 'features/seismic-3d/threejs/scene/GeopostScene';

const coordinateDivisionFactor = 30000;

let mainFeatureCentroid : Coordinate = [0, 0];

function transformDivideFactorToSTScalar(divisionFactor : number){
    return (12.5 / divisionFactor);
}

export function getOlGeometryFromWKT(geomWkt: string,centroidWkt: string | null = null) : Geometry {
    const format = new WKT();
    const geometry = format.readGeometry(geomWkt, {
        dataProjection: '3857'
    });
    let centroidCoordinates;
    /*if (centroidWkt !== null) {
        const centroidGeometry = format.readGeometry(centroidWkt, {
            dataProjection: '3857'
        });
        centroidCoordinates = (centroidGeometry as Point).getCoordinates();
    } else {
        centroidCoordinates = olExtent.getCenter(geometry.getExtent());
    }*/

    return geometry;
}

/*function getScaledGeometryFromWKT(geomWkt: string, scaleAnchor : Coordinate, centroidWkt: string | null = null) {
    const format = new WKT();
    const geometry = format.readGeometry(geomWkt, {
        dataProjection: '3857'
    });
    let centroidCoordinates;
    if (centroidWkt !== null) {
        const centroidGeometry = format.readGeometry(centroidWkt, {
            dataProjection: '3857'
        });
        centroidCoordinates = (centroidGeometry as Point).getCoordinates();
    } else {
        centroidCoordinates = olExtent.getCenter(geometry.getExtent());
    }
    const scaleFactor = transformDivideFactorToSTScalar(coordinateDivisionFactor);
    (-1 * centroidCoordinates[0]), ( -1 * centroidCoordinates[1])
    mainFeatureCentroid = centroidCoordinates;
    const centroidScaledCoordinates =  [Math.abs(centroidCoordinates[0] * scaleFactor), Math.abs(centroidCoordinates[1] * scaleFactor)];
    geometry.scale(scaleFactor, scaleFactor, );
    //geometry.translate((-1 * scaledCentroid[0]), (-1 * scaledCentroid[1]));
    return geometry;
}*/

export function scaleGeometry(geometry : Geometry) {
    const scaleFactor = transformDivideFactorToSTScalar(coordinateDivisionFactor);
    geometry.translate(-(mainFeatureCentroid[0]), -(mainFeatureCentroid[1]));
    geometry.scale(-scaleFactor, scaleFactor, [0, 0]);
}

export function build2DPointsFromCoordinates(coordinates: Coordinate[]) {
    let points = [];
    for (let i = 0; i < coordinates.length; i++) {
        let x = coordinates[i][0];
        let z = coordinates[i][1];

        points.push(new THREE.Vector3(x, 0, z));
    }

    return points;
}

export function build3DPointsFromCoordinates(coordinates: number[][]) {
    let points = [];

    for (let i = 0; i < coordinates.length; i++) {
        let x = coordinates[i][0];
        let z = coordinates[i][1];
        let y = coordinates[i][2];

        points.push(new THREE.Vector3(x, y, z));
    }

    return points;
}

export function buildMultiPointsFromMultiCoordinates(multiCoordinates: Coordinate[][]){
    let multipoints : THREE.Vector3[][] = [];
    multiCoordinates.forEach((coordinates) => {
        const points = build2DPointsFromCoordinates(coordinates);
        multipoints.push(points);
    });
    return multipoints;
}

export function buildMeshByPoints(points : THREE.Vector3[], color : string){
    const material = new THREE.LineBasicMaterial({color: color, transparent: false});
    material.polygonOffset = true;
    material.depthTest = true;
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const line = new THREE.Line(geometry, material);
    return line;
}

export function getGeometryByPoints(points : THREE.Vector3[]) {
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    return geometry;
}

export function buildMeshMultiGeometryByPoints(multiPoints : THREE.Vector3[][], color : string){
    const geometriesToMerge : THREE.BufferGeometry<THREE.NormalBufferAttributes>[] = [];
    multiPoints.forEach((points) => {
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        geometriesToMerge.push(geometry);
    });
    const finalGeometry = BufferGeometryUtils.mergeGeometries(geometriesToMerge);
    const material = new THREE.LineBasicMaterial({color: color, transparent: false});
    material.polygonOffset = true;
    const mesh = new THREE.Line(finalGeometry, material);
    return mesh;
}

export function getThreeJSGeometryByMultiPoints(multiPoints : THREE.Vector3[][]) {
    const geometriesToMerge : THREE.BufferGeometry<THREE.NormalBufferAttributes>[] = [];
    multiPoints.forEach((points) => {
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        geometriesToMerge.push(geometry);
    });
    const finalGeometry = BufferGeometryUtils.mergeGeometries(geometriesToMerge);
    return finalGeometry;
}

export function buildMainMeshByGeomWKT(geomWKT : string, geomCentroidWKT: string, color : string) {
    const olGeometry = getOlGeometryFromWKT(geomWKT);
    const centroidGeometry = getOlGeometryFromWKT(geomCentroidWKT);
    const centroid = (centroidGeometry as Point).getCoordinates();
    mainFeatureCentroid = [(centroid[0]), (centroid[1])];
    scaleGeometry(olGeometry);
    return buildMeshByOlGeom(olGeometry, color);
}

export function buildMeshByGeomWKT(geomWkt : string, centroidWkt : string | null, color : string){
    const olGeometry = getOlGeometryFromWKT(geomWkt);
    scaleGeometry(olGeometry);
    return buildMeshByOlGeom(olGeometry, color);
}

export function buildMergedMesh(wktGeometries : string[], meshName : string, meshColor : string) {
    const geometriesToMerge : THREE.BufferGeometry[] = [];
    wktGeometries.forEach(wktGeometry => {
        const olGeometry = getOlGeometryFromWKT(wktGeometry);
        scaleGeometry(olGeometry);
        const threeJsGeometry = getThreeJsGeometryByOlGeometry(olGeometry);
        geometriesToMerge.push(threeJsGeometry);
    });

    const finalGeometry = BufferGeometryUtils.mergeGeometries(geometriesToMerge);

    return buildMeshByThreeJsGeometry(finalGeometry, meshColor, meshName);
}

function buildMeshByOlGeom(geometry : Geometry, color : string) {
    let mesh : THREE.Line;

    if (geometry instanceof Polygon || geometry instanceof LineString || geometry instanceof Point){
        let coordinates = getOlGeometryCoordinates(geometry);
        mesh = buildMeshByPoints(build2DPointsFromCoordinates(coordinates), color);
    }
    else {
        let multiCoordinates = getOlGeometryMultiCoordinates(geometry);
        const multiPoints = buildMultiPointsFromMultiCoordinates(multiCoordinates);
        mesh = buildMeshMultiGeometryByPoints(multiPoints, color);
    }

    return mesh;
}

function buildMeshByThreeJsGeometry(geometry: THREE.BufferGeometry, color: string, meshName: string) {
    const material = new THREE.LineBasicMaterial({ color: color });
    const mesh = new THREE.Line(geometry, material);
    mesh.name = meshName;
    return mesh;
}

function getThreeJsGeometryByOlGeometry(olGeometry : Geometry) {
    let geometry : THREE.BufferGeometry;

    if (olGeometry instanceof Polygon || olGeometry instanceof LineString || olGeometry instanceof Point){
        let coordinates = getOlGeometryCoordinates(olGeometry);
        geometry = getGeometryByPoints(build2DPointsFromCoordinates(coordinates));
    }
    else {
        let multiCoordinates = getOlGeometryMultiCoordinates(olGeometry);
        const multiPoints = buildMultiPointsFromMultiCoordinates(multiCoordinates);
        geometry = getThreeJSGeometryByMultiPoints(multiPoints);
    }

    return geometry;
}

function getOlGeometryCoordinates(geometry : Geometry) {
    let coordinates : Coordinate[] = [];
    if (geometry instanceof Polygon) {
        coordinates = geometry.getCoordinates()[0];
    }
    else if (geometry instanceof LineString){
        coordinates = geometry.getCoordinates();
    }
    else if (geometry instanceof Point){
        coordinates = [geometry.getCoordinates()];
    }
    return coordinates;
}

function getOlGeometryMultiCoordinates(geometry : Geometry) {
    let multiCoordinates : Coordinate[][] = [];
    if (geometry instanceof MultiPolygon) {
        geometry.getCoordinates().forEach( multiPolygonCoordinate =>
            multiCoordinates.push(multiPolygonCoordinate[0])
        );
    }
    else if (geometry instanceof MultiLineString) {
        multiCoordinates = geometry.getCoordinates();
    }
    return multiCoordinates;
}

export const getFirstAndLastCoordinatesFromGeomWkt = (geomWkt: string) => {
    const wktReader = new WKT();
    const geometry = wktReader.readGeometry(geomWkt);
    scaleGeometry(geometry);
    let coordinates : Coordinate[] = [];
    if (geometry instanceof Polygon || geometry instanceof LineString || geometry instanceof Point) {
        coordinates = [...coordinates, ...getOlGeometryCoordinates(geometry)];
    }
    const firstCoordinate = coordinates[0];
    const lastCoordinate = coordinates[coordinates.length - 1];
    return [firstCoordinate, lastCoordinate];
};

export const interpolate = (coordinateA: Coordinate, coordinateB: Coordinate, frac: number) => {
    const interpolatedCoordinate: number[] = [];
    interpolatedCoordinate[0] = coordinateA[0] + (coordinateB[0] - coordinateA[0]) * frac;
    interpolatedCoordinate[1] = coordinateA[1] + (coordinateB[1] - coordinateA[1]) * frac;
    return interpolatedCoordinate;
};