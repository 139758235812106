import { useQuery } from 'react-query';

import { useSessionStore } from 'session/useSessionStore';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { AlineWeight } from '../models/IAlineWeight';
import { IRunAlineRequest } from '../models/IRunAlineRequest';
import { axios } from 'configurations/axios';

const baseUrl = 'https://localhost:44357';
// const baseUrl = 'https://processing.geopostenergy.com';

export function useGetAllAlineWeights() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery(`alineWeightsGetAll`, async () => {
        const response = await axios.request<IResponseAPI<AlineWeight[]>>({
            method: 'get',
            // url: tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/GetAll',
            url: baseUrl + '/ProjectCustomization/Eneva/GetAlineWeights',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data.Result;
    });
}
export function useRunAline(){
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));
    return useQuery(`runAline`, async ()=>{
        const response = await axios.request<IResponseAPI<IRunAlineRequest>>({
            method: 'post',
            // url: tenantConfig?.endpoints.interpretation.MainUrl + '/Interpretations/Interpretations/GetAll',
            url: baseUrl + '/ProjectCustomization/Eneva/ExecuteAline',
            params: {
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });
    });
}
