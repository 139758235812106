import { Box, Button, css, TextField, Typography } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { MainTableCss } from 'fileIndexing/envVar/envVar';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';

import { IRule } from '../interfaces/ITaxonomy';
import { useTestRules } from '../useTaxonomy';

const style = css({
    margin: '0 !important',
    width: '100% !important',
    '& .title': {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px',
    },
    '& .table': {
        display: 'flex',
        flexDirection: 'column',
    },
    '& .header-button': {
        marginBottom: '2rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    '& .lowest-priority, .lowest-priority:hover': {
        backgroundColor: '#035518',
        color: '#ffffff',
    },
});

export const TestRulesTable = ({ filterText }: Props) => {
    const [rulesData, setRulesData] = useState<IRule[]>([]);
    const [filteredData, setFilteredData] = useState<IRule[]>([]);
    const [filter, setFilter] = useState<string>('');

    const [checkTest, setCheckTest] = useState<string>(filterText ?? '');
    const [sendCheck, setSendCheck] = useState<boolean>(false);

    const { data: testRulesData, isLoading: testRulesLoading } = useTestRules(checkTest, sendCheck);

    // realiza o filtro
    useEffect(() => {
        if (rulesData) {
            if (filter === '') {
                setFilteredData(rulesData);
            }
            else {
                setFilteredData(rulesData.filter(x => x.EntityName?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Group?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Subgroup?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Tags?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Rule?.toLowerCase().startsWith(filter.toLowerCase()) ||
                    x.Priority?.toString() === filter));
            }
        }
    }, [filter]);

    // adiciona um token para poder indexar na tabela
    useEffect(() => {
        if (testRulesData) {
            debugger;
            setRulesData(testRulesData.map((item, index) => ({
                ...item,
                Token: index + 1,
            })));
            setSendCheck(false);
        }
    }, [testRulesData]);

    // adiciona o estado de filtro
    useEffect(() => {
        if (rulesData) {
            setFilteredData(rulesData);
        }
    }, [rulesData]);

    useEffect(() => {
        if (filterText !== '' && filterText) {
            setSendCheck(true);
        }
    }, [filterText]);

    // conteúdo da tabela
    const columns: GridColDef[] = [
        {
            field: 'EntityName',
            headerAlign: 'left',
            headerName: 'Name',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Group',
            headerAlign: 'left',
            headerName: 'Group',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Subgroup',
            headerAlign: 'left',
            headerName: 'Subgroup',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Tags',
            headerAlign: 'left',
            headerName: 'Tags',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Rule',
            headerAlign: 'left',
            headerName: 'Rules',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Priority',
            headerAlign: 'left',
            headerName: 'Priority',
            minWidth: 120,
            flex: 1,
        },
    ];

    const getRowId = (row: IRule) => row.Token;

    // filtro para ficar verde a regra com maior prioridade
    const minPriority = useMemo(() => {
        return Math.min(...filteredData.map((item) => item.Priority));
    }, [filteredData]);

    const getRowClassName = (params: GridRowParams<IRule>) => {
        return params.row.Priority === minPriority ? 'lowest-priority' : '';
    };

    return (
        <Fragment>
            <Box css={[style, MainTableCss]}>
                <Box className='title'>
                    <TextField label='Enter a phrase' variant='outlined' size='small' color='primary' fullWidth value={checkTest} onChange={(event) => { setCheckTest(event.target.value); setSendCheck(false) }} />
                    <Button variant='contained' color='primary' className='reindex' style={{ marginLeft: '16px', height: '40px' }} onClick={() => setSendCheck(true)}>Check</Button>
                </Box>
                <Box className='table'>
                    <Box className='table-header'>
                        <Typography className='table-header-title'>Rules</Typography>
                        <Box className='search'>
                            <TextField label='Search rules' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        </Box>
                    </Box>
                    <GeopostTable<IRule> filteredData={filteredData} loading={testRulesLoading} columns={columns} getRowId={getRowId} getRowClassName={getRowClassName} />
                </Box>
            </Box>
        </Fragment>
    );
};

interface Props {
    filterText?: string;
}