import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { useMetadata3DViewer } from '../api/useVolume';
import { LineType } from '../models/enums/LineType';
import { IMetadata3DViewerRequest } from '../models/interfaces/requests/IMetadata3DViewerRequest';
import { use3DGridStore } from '../stores/use3DGridStore';
import { use3DSceneStore } from '../stores/use3DSceneStore';
import { use3DViewerStore } from '../stores/use3DViewerStore';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';
import { getDeeperRelativeLinesToLoad, getRealLineValue, getRelativeLinesToLoadInFirstIteration } from '../utils/TileUtils';
import { Lines3D } from './Lines3D';
import { VolumeType } from '../models/enums/VolumeType';
import { Line2D } from './Line2D';
import { getCountTimeDepth } from '../threejs/utils/ScaleUtils';

export type Grid3DProps = {
    onLoadStart: () => void,
    onLoadEnd: () => void
};

export const Grid3D = ({ onLoadStart, onLoadEnd } : Grid3DProps) => {
    const geopostScene = use3DSceneStore(state => state.scene);
    const mainFeatureSrid = use3DViewerStore(state => state.mainFeatureSrid);

    const setHeightPixelFactor = use3DViewerStore(state => state.setHeightPixelFactor);
    const setCountTimeDepth = use3DViewerStore(state => state.setCountTimeDepth);

    const gridSelectedSeismic = use3DGridStore(state => state.gridSelectedSeismic);

    const currentDeepeningIterationRef = useRef(-1);

    const {
        divisionFactor,
        changingDivisionFactor,
        screenHeightFactor,
        tileWidth,
        skipInlineFactor,
        skipXlineFactor,
        initialDeepeningRange,
        deepeningsTotal,
        setSearchedInlineNumber,
        setSearchedXlineNumber,
        setTotalInlineNumbers,
        setTotalXlineNumbers,
        setSelectedInlineNumber,
        setSelectedXlineNumber,
        setSelectedSeismic3DInfo,
        setIsXLineSearchLoading,
        setIsInlineSearchLoading,
    } = use3DGridStore(state => ({
        divisionFactor: state.divisionFactor,
        changingDivisionFactor: state.changingDivisionFactor,
        screenHeightFactor: state.screenHeightFactor,
        tileWidth: state.tileWidth,
        skipInlineFactor: state.skipInlineFactor,
        skipXlineFactor: state.skipXlineFactor,
        initialDeepeningRange: state.initialLoadingRange,
        deepeningsTotal: state.totalLoadingIterations,
        setSearchedInlineNumber: state.setSearchedInlineNumber,
        setSearchedXlineNumber: state.setSearchedXlineNumber,
        setTotalInlineNumbers: state.setTotalInlineNumbers,
        setTotalXlineNumbers: state.setTotalXlineNumbers,
        setSelectedInlineNumber: state.setSelectedInlineNumber,
        setSelectedXlineNumber: state.setSelectedXlineNumber,
        setSelectedSeismic3DInfo: state.setSelectedSeismic3DInfo,
        setIsXLineSearchLoading: state.setIsXLineSearchLoading,
        setIsInlineSearchLoading: state.setIsInlineSearchLoading,
    }), shallow);

    const searchedInlineNumber = use3DGridStore(state => state.searchedInlineNumber);
    const searchedXlineNumber = use3DGridStore(state => state.searchedXlineNumber);

    const [ gridLoadingError, setGridLoadingError ] = useState<boolean>(false);

    const [metadata3DViewerRequest, setMetadata3DViewerRequest] = useState<IMetadata3DViewerRequest>();
    const { data: metadata3DViewer, isError: metadata3DViewerError } = useMetadata3DViewer(metadata3DViewerRequest);
    const survey3DInfo = metadata3DViewer?.Metadata.Survey3DInfo;

    const [ inlineRelativeNumbersToLoad, setInlineRelativeNumbersToLoad ] = useState<number[]>([]);

    const totalOfDeepeningIterations = useMemo(() => getUrlParams().totalLoadingIterations, []);

    const lineLoadingStepRef = useRef<number>(getUrlParams().lineLoadingStep);

    const inlineNumbersToLoad = useMemo(() => {
        if (survey3DInfo) {
            return inlineRelativeNumbersToLoad.map(relativeNumber => getRealLineValue(relativeNumber, survey3DInfo.InlineStart, survey3DInfo.InlineIncrement));
        } else {
            return [];
        }
    }, [inlineRelativeNumbersToLoad]);

    const [ geometryLoadedInlinesNumbers, setGeometryLoadedInlineNumbers ] = useState<number[]>([]);

    const [ imageLoadedInlinesTotal, setImageLoadedInlinesTotal ] = useState<number>(0);

    const [ inlineNumbersWithError, setInlineNumbersWithError ] = useState<number[]>([]);
    const [ xlineNumbersWithError, setXlineNumbersWithError ] = useState<number[]>([]);

    const [ geometryLoadedXlinesNumbers, setGeometryLoadedXlineNumbers ] = useState<number[]>([]);

    const [ imageLoadedXlinesTotal, setImageLoadedXlinesTotal ] = useState<number>(0);

    const [ xlineRelativeNumbersToLoad, setXlineRelativeNumbersToLoad ] = useState<number[]>([]);

    const mainFeatureGeomData = use3DViewerStore(state => state.mainFeatureGeomData);

    const volumeType = useMemo<SurveyType>(() => metadata3DViewer?.Metadata.Type ?? SurveyType.Seismic3D, [metadata3DViewer]);

    const xlineNumbersToLoad = useMemo(() => {
        if (survey3DInfo) {
            return xlineRelativeNumbersToLoad.map(relativeNumber => getRealLineValue(relativeNumber, survey3DInfo.XlineStart, survey3DInfo.XlineIncrement));
        } else {
            return [];
        }
    }, [xlineRelativeNumbersToLoad]);

    const startFirstDeepeningIteration = useCallback(() => {
        if (!survey3DInfo) {
            return;
        }
        const inlineRelativeNumbers = getRelativeLinesToLoadInFirstIteration(initialDeepeningRange, survey3DInfo?.TotalInlines);
        const xlineRelativeNumbers = getRelativeLinesToLoadInFirstIteration(initialDeepeningRange, survey3DInfo?.TotalXlines);
        setInlineRelativeNumbersToLoad(inlineRelativeNumbers);
        setXlineRelativeNumbersToLoad(xlineRelativeNumbers);
        currentDeepeningIterationRef.current = 1;
    }, [survey3DInfo, initialDeepeningRange]);

    const increaseDeepeningIteration = useCallback(() => {
        currentDeepeningIterationRef.current += 1;

        if (currentDeepeningIterationRef.current > totalOfDeepeningIterations) {
            return;
        }

        const updatedInlineNumbers = getDeeperRelativeLinesToLoad(inlineRelativeNumbersToLoad);
        const updatedXlineNumbers = getDeeperRelativeLinesToLoad(xlineRelativeNumbersToLoad);
        setInlineRelativeNumbersToLoad(updatedInlineNumbers);
        setXlineRelativeNumbersToLoad(updatedXlineNumbers);
    }, [survey3DInfo, inlineRelativeNumbersToLoad, xlineRelativeNumbersToLoad]);

    const handleInlineError = (inlineNumbers: number[]) => setInlineNumbersWithError(current => [...current, ...inlineNumbers]);
    const handleXlineError = (xlineNumbers: number[]) => setXlineNumbersWithError(current => [...current, ...xlineNumbers]);

    useEffect(() => {
        if (gridSelectedSeismic) {
            setSearchedInlineNumber(null);
            setSearchedXlineNumber(null);
            onLoadStart();
            setMetadata3DViewerRequest({
                volumeToken: gridSelectedSeismic.volumeToken,
                factorDivide: divisionFactor,
                factorToChangeDivide: changingDivisionFactor,
                screenHeight: screenHeightFactor,
                tileWidth: tileWidth,
                skipInline: skipInlineFactor,
                skipXLine: skipXlineFactor
            });
            geopostScene.addGrid(gridSelectedSeismic.volumeToken);
            setHeightPixelFactor(gridSelectedSeismic.samplesPerTrace);
            setCountTimeDepth(getCountTimeDepth(gridSelectedSeismic.sampleInterval, gridSelectedSeismic.sampleIntervalUnit, gridSelectedSeismic.samplesPerTrace));
        }
    }, [gridSelectedSeismic]);

    useEffect(() => {
        if (metadata3DViewer) {
            setGridLoadingError(false);
            startFirstDeepeningIteration();
            setSelectedSeismic3DInfo(metadata3DViewer.Metadata.Survey3DInfo);
        }
    }, [metadata3DViewer]);

    useEffect(() => {
        if (metadata3DViewerError) {
            onLoadEnd();
        }
        setGridLoadingError(metadata3DViewerError);
    }, [metadata3DViewerError]);

    useEffect(() => {
        const imageToLoadLinesTotal = geometryLoadedInlinesNumbers.length + geometryLoadedXlinesNumbers.length;
        const loadedImageLinesTotal = imageLoadedInlinesTotal + imageLoadedXlinesTotal + inlineNumbersWithError.length + xlineNumbersWithError.length;
        if (imageToLoadLinesTotal > 0 && loadedImageLinesTotal === (imageToLoadLinesTotal)) {
            increaseDeepeningIteration();
        }
    }, [imageLoadedInlinesTotal, imageLoadedXlinesTotal, inlineNumbersWithError, xlineNumbersWithError]);

    useEffect(() => {
        if (geometryLoadedInlinesNumbers.length === inlineNumbersToLoad.length && geometryLoadedXlinesNumbers.length === xlineNumbersToLoad.length && (geometryLoadedInlinesNumbers.length > 0 || geometryLoadedXlinesNumbers.length > 0)) {
            onLoadEnd();

            if (currentDeepeningIterationRef.current === 1) {
                selectCentralLines();
            }
        }
    }, [geometryLoadedXlinesNumbers, geometryLoadedInlinesNumbers]);

    useEffect(() => {
        setTotalInlineNumbers(geometryLoadedInlinesNumbers);
        setTotalXlineNumbers(geometryLoadedXlinesNumbers);
    }, [geometryLoadedXlinesNumbers, geometryLoadedInlinesNumbers]);

    const selectCentralLines = () => {
        const centralInlineIndex = Math.round(geometryLoadedInlinesNumbers.length / 2);
        const centralInlineNumber = geometryLoadedInlinesNumbers[centralInlineIndex];
        setSearchedInlineNumber(centralInlineNumber);

        const centralXlineIndex = Math.round(geometryLoadedXlinesNumbers.length / 2);
        const centralXlineNumber = geometryLoadedXlinesNumbers[centralXlineIndex];
        setSearchedXlineNumber(centralXlineNumber);
    };

    return (
        <React.Fragment>
            {
                (
                    volumeType === SurveyType.Seismic3D ?
                        <React.Fragment>
                            <Lines3D
                                lineLoadingStep={lineLoadingStepRef.current}
                                seismicMetadata={metadata3DViewer}
                                lineType={LineType.Inline}
                                scene={geopostScene}
                                searchedLineNumber={searchedInlineNumber}
                                srid={mainFeatureSrid}
                                setSelectedLineNumber={setSelectedInlineNumber}
                                setIsPriorityLineLoading={setIsInlineSearchLoading}
                                onTotalImageLoadedLinesChange={setImageLoadedInlinesTotal}
                                onLoadedLineNumbersChange={setGeometryLoadedInlineNumbers}
                                lineNumbersToLoad={inlineNumbersToLoad}
                                onLoadingLineError={handleInlineError}
                            ></Lines3D>
                            <Lines3D
                                lineLoadingStep={lineLoadingStepRef.current}
                                seismicMetadata={metadata3DViewer}
                                lineType={LineType.Xline}
                                scene={geopostScene}
                                searchedLineNumber={searchedXlineNumber}
                                srid={mainFeatureSrid}
                                setSelectedLineNumber={setSelectedXlineNumber}
                                setIsPriorityLineLoading={setIsXLineSearchLoading}
                                onTotalImageLoadedLinesChange={setImageLoadedXlinesTotal}
                                onLoadedLineNumbersChange={setGeometryLoadedXlineNumbers}
                                lineNumbersToLoad={xlineNumbersToLoad}
                                onLoadingLineError={handleXlineError}
                            ></Lines3D>
                        </React.Fragment>
                        :
                        (
                            metadata3DViewer &&
                            <Line2D onLoadEnd={onLoadEnd}  scene={geopostScene} seismicMetadata={metadata3DViewer.Metadata} srid={mainFeatureSrid} colorbar={metadata3DViewer.Colorbar}/>
                        )
                )
            }
        </React.Fragment>);
};
