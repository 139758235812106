import { css } from '@mui/material';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useDebouncedCallback } from 'use-debounce';

const style = css({position: 'relative'
    ,'& .swatch':{
        width:'39px',
        height:'10px',
        borderRadius:'8px',
        border:'3px solid #fff',
        boxShadow:'0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
        cursor:'pointer',
    },'& .popover':{
        position:'absolute',
        top:'calc(100% + 2px)',
        left:'0',
        borderRadius:'9px',
        boxShadow:'0 6px 12px rgba(0, 0, 0, 0.15)',
        zIndex:99999
    }});
interface IProps{
    onChange(color:string):void
    color?:string;
};
export function CrossSectionColorPicker({onChange,color}:IProps){
    const colors = [
        '#FF5733',
        '#E81123',
        '#FF8C00',
        '#FFD700',
        '#E6B333',
        '#8B5A00',
        '#7FFF00',
        '#00FF00',
        '#2E8B57',
        '#32CD32',
        '#00FFFF',
        '#008080',
        '#0000FF',
        '#1E90FF',
        '#4B0082',
        '#8A2BE2',
        '#FF69B4',
        '#FF1493',
        '#FFC0CB',
        '#CD5C5C',
        '#8B0000',
        '#FFDAB9',
        '#FFE4B5',
        '#FFA07A',
        '#FF7F50',
        '#FF6347',
        '#A0522D',
        '#8B4513',
        '#696969',
        '#A9A9A9'
    ];
    const debounce = useDebouncedCallback ((curveColor)=>onChange(curveColor),1000);

    // const [color, setColor] = useState(createColor('#FF0000'));\
    const useClickOutside = (ref:RefObject<HTMLDivElement>, handler : (event:Event)=>void) => {
        useEffect(() => {
            let startedInside = false;
            let startedWhenMounted:HTMLDivElement | null = null;

            const listener = (event:Event) => {
            // Do nothing if `mousedown` or `touchstart` started inside ref element
                if (startedInside || !startedWhenMounted) {return}
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target as Node)) {return}

                handler(event);
            };

            const validateEventStart = (event:Event) => {
                startedWhenMounted = ref.current;
                startedInside = (ref.current && ref.current.contains(event.target as Node)) ?? false;
            };

            document.addEventListener('mousedown', validateEventStart);
            document.addEventListener('touchstart', validateEventStart);
            document.addEventListener('click', listener);

            return () => {
                document.removeEventListener('mousedown', validateEventStart);
                document.removeEventListener('touchstart', validateEventStart);
                document.removeEventListener('click', listener);
            };
        }, [ref, handler]);
    };
    const popover = useRef<HTMLDivElement>(null);
    // const [color, setColor] = useState((colors[Math.floor(Math.random() * 29)]));
    const [stateColor, setStateColor] = useState(color ? `#${color}` : colors[Math.floor(Math.random() * 29)]);
    const [isOpen, toggle] = useState(false);
    const close = useCallback(() => toggle(false), []);
    useClickOutside(popover, close);

    useEffect(() => {
        if (stateColor){
            debounce(stateColor);
        }
    }, [stateColor]);

    const handleChange = (newValue:string) => {
        setStateColor(newValue);
    };
    return (

        <div className="picker" css={style}>
            <div
                className="swatch"
                style={{ backgroundColor: stateColor}}
                onClick={() => toggle(true)}
            />

            {isOpen && (
                <div className="popover" ref={popover}>
                    <HexColorPicker color={stateColor} onChange={handleChange} />
                </div>
            )}
        </div>
    );
}

