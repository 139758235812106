import { CircularProgress, IconButton, InputAdornment, Slider, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ChangeEventHandler, FormEventHandler, ReactNode, useCallback, useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import LoupeIcon from '@mui/icons-material/Loupe';

export type LineSliderProps = {
    totalLineNumbers: number[],
    selectedLineNumber: number,
    onLineInputSubmit: (value: number) => void,
    isSearchLoading: boolean,
    onSliderChange: (event: Event, value: number | number[], activeThumb: number) => void,
    lineIncrement: number,
    detailsRoute: string,
    label: string
};

export const LineSlider = ({ totalLineNumbers, selectedLineNumber, onLineInputSubmit, isSearchLoading, lineIncrement, onSliderChange, detailsRoute, label } : LineSliderProps) => {

    const [ lineNumberInput, setLineNumberInput ] = useState<number>(selectedLineNumber);

    const handleLineInputChange : ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
        const value = parseInt(event.target.value);
        setLineNumberInput(value);
    };

    const handleLineInputSubmit : FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onLineInputSubmit(lineNumberInput);
    };

    const lineScale = useCallback((value: number) => totalLineNumbers[value], [totalLineNumbers]);

    useEffect(() => setLineNumberInput(selectedLineNumber), [selectedLineNumber]);

    return (
        <Stack>
            <Stack direction='row'>
                <form onSubmit={handleLineInputSubmit}>
                    <TextField
                        size='small'
                        type='number'
                        label={label}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                {
                                    isSearchLoading ?
                                        <CircularProgress size={15}/>
                                        :
                                        <IconButton type='submit'>
                                            <SearchIcon/>
                                        </IconButton>
                                }

                            </InputAdornment>,
                            sx: {
                                width: '170px'
                            }
                        }}
                        value={lineNumberInput}
                        onChange={handleLineInputChange}
                        disabled={totalLineNumbers.length === 0 || isSearchLoading}
                    />
                </form>
                <Tooltip placement='top'
                    title={
                        <Stack spacing={0.5}>
                            <LineInfoText>Start: {totalLineNumbers[0]}</LineInfoText>
                            <LineInfoText>End: {totalLineNumbers[totalLineNumbers.length - 1]}</LineInfoText>
                            <LineInfoText>Increment: {lineIncrement} </LineInfoText>
                        </Stack>
                    }
                >
                    <IconButton>
                        <InfoIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip
                    placement='top'
                    title={
                        <Stack>
                            <LineInfoText>Click to open the selected line in the details using the 2D Viewer</LineInfoText>
                        </Stack>
                    }
                >
                    <IconButton href={detailsRoute} target='_blank' disabled={isSearchLoading}>
                        <LoupeIcon/>
                    </IconButton>
                </Tooltip>
            </Stack>

            <Slider
                id='inline-slider'
                value={selectedLineNumber === 0? 0 : totalLineNumbers.indexOf(selectedLineNumber)}
                aria-label='Inline'
                defaultValue={0}
                step={1}
                min={0}
                max={totalLineNumbers.length - 1}
                valueLabelDisplay='off'
                onChange={onSliderChange}
                disabled={totalLineNumbers.length === 0}
                scale={lineScale}
            />
        </Stack>
    );
};

type LineInfoTextProps = {
    children: ReactNode
};

const LineInfoText = ({ children } : LineInfoTextProps) => (<Typography variant='caption'  color='white'>{children}</Typography>);