import { useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { useSessionStore } from 'session/useSessionStore';
import { ITag, ITagGroup } from './interfaces/ITag';

// import request from "../../common/services/axios-service.js";

export function useGetAllTagGroups() {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllTagGroups', async () => {
        const response = await axios.request<IResponseAPI<ITagGroup[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/TagGroup/GetAllTagGroups',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetAllTags(tagGroupToken: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllTags' + tagGroupToken, async () => {
        const response = await axios.request<IResponseAPI<ITag[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Tag/GetAllTags',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        enabled: tagGroupToken !== null && tagGroupToken !== undefined && tagGroupToken !== '',
        cacheTime: 0,
    });
}

// export function deleteTagGroup(token) {
//   return request.delete("TagGroup/DeleteTagGroup", {
//     params: {
//       token,
//     },
//   });
// }

export function useSaveTagGroup(data: ITagGroup | null) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveTagGroup' + data?.Name + data?.Token + data?.TagsToken + data?.DataSubgroupToken, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/TagGroup/SaveTagGroup',
            data: {
                Name: data?.Name,
                Token: data?.Token,
                TagsToken: data?.TagsToken,
                DataSubgroupToken: data?.DataSubgroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    },{
        refetchOnWindowFocus: false,
        enabled: data !== null,
        cacheTime: 0,
    });
}

// export function saveTag(data) {
//   return request.post("Tag/SaveTag", {
//     Name: data.name,
//     Token: data.token,
//     TagGroupToken: data.tagGroupToken,
//     Regex: data.regex,
//   });
// }

// export function deleteTag(token) {
//   return request.delete("Tag/DeleteTag", {
//     params: {
//       token,
//     },
//   });
// }

// export function reindexAllFilesByTag(tagToken, connectionId) {
//   console.log("ENTROUN NO METODO");
//   return request.get("/FilesData/ReIndexFilesByTag", {
//     params: {
//       tagToken,
//       connectionId
//     },
//   });
// }

// export function getOptGroup() {
//   return request.get("/TagGroup/GetTagGroupsAndTagOptGroup");
// }
