import { Box, Stack, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { GeopostLogo } from 'components/geopostLogo/GeopostLogo';

export const LogoutPanel = () => {

    return (
        <Box sx={{backgroundColor:'black'}} height={'100%'} width={'100%'} display='flex' alignItems='center' justifyContent='center'>
            <Stack spacing={2} alignItems='center'>
                <GeopostLogo maxWidth={400} maxHeight={128}/>
                <LogoutIcon sx={{height: '150px', width: '150px'}} color='secondary'/>
                <Stack spacing={1} direction='row' maxWidth='600px'>
                    <Typography variant='h4' color='white'>You are now logged out, access the application again in order to log in.</Typography>
                </Stack>
            </Stack>
        </Box>
    );
};