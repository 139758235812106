import { CacheSystem } from 'features/seismic/models/enums/CacheSystem';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { LineType } from '../models/enums/LineType';
import { lineMetadata } from '../models/types/LineMetadata';
import { LineStringResponse } from '../models/types/LineStringResponse';
import { Metadata3D } from '../models/types/Metadata3D';
import { Metadata3DHeader } from '../models/types/Metadata3DHeader';
import { Survey3DInfo } from '../models/types/Survey3DInfo';
import { TileRequest } from '../models/types/TileRequest';
import { GeopostThreeJsConstants } from '../threejs/utils/GeopostThreeJsConstants';
import { getUrlParams } from './Seismic3DUrlUtils';

//const divisionXFactor = 125;
//const divisionXFactor = 875;

export const useGetSeismic3DParamsXLine = 0;

const baseWidthSize = 64;

export const getSeismic3DParams = (
    samplesPerTrace: number, sampleInterval: number, byteSize: number, tracesIncrement: number, surveyLineStart: number, surveyLineEnd: number, seismicVolumeToken: string,
    seismicVolumePath: string, minSampleValue: number, maxSampleValue: number, lineType: LineType, lineMetadata : LineStringResponse, tileIndex: number, lineNumber: number,
    geoserverXFactor: number, totalTiles: number, surveyInlineStart:number, surveyInlineEnd:number, surveyXlineStart:number,surveyXlineEnd:number,surveyInlineIncrement:number,
    surveyXlineIncrement:number, colorBar:string, traceRangeStart: number, traceRangeEnd: number, tileHeight?: number
) => {
    //const minTrace = lineType === LineType.Inline ? survey3DInfo.XlineStart : survey3DInfo.InlineStart;
    const lineStart = lineMetadata.Min + (geoserverXFactor * tileIndex * tracesIncrement);
    //item.MinTrace + (tileIndex * item.FactorTraceTile * tracesIncrement);
    const lineEnd = lineStart + (geoserverXFactor * tracesIncrement);

    const tileRequest : TileRequest = {
        render: getUrlParams().sourceApi,
        volumeToken: seismicVolumeToken,
        forceDisableCache: getUrlParams().forceDisableCache,
        filePath: seismicVolumePath,
        colorbar: colorBar ? colorBar : 'binary',
        skipTraceCount: getUrlParams().traceDecimation,
        parallelProcesses: 10,
        //tileWidth: 64,
        tileWidth: geoserverXFactor,
        //tileWidth: 700,
        //tileWidth: 100,
        //tileWidth: 500,
        //tileHeight: 1080,
        tileHeight: (samplesPerTrace),
        dpi: 1,
        volumeSamplesPerTrace: samplesPerTrace,
        jpgQuality: 70,
        isIndexedVolume: true,
        lineIncrement: tracesIncrement,
        minSampleValue: minSampleValue,
        maxSampleValue: maxSampleValue,
        lineStart: lineStart,
        lineEnd: lineEnd,
        lineStartSample: 0,
        lineEndSample: samplesPerTrace + 200,
        traceRangeStart: traceRangeStart,
        traceRangeEnd: traceRangeEnd,
        surveyLineStart: surveyLineStart,
        surveyLineEnd: surveyLineEnd,
        sampleInterval: sampleInterval,
        applyWatermark: null,
        lineType: lineType,
        byteSize: byteSize,
        lineNumber: lineNumber,
        surveyInlineStart:surveyInlineStart,
        surveyInlineEnd:surveyInlineEnd,
        surveyXlineStart:surveyXlineStart,
        surveyXlineEnd:surveyXlineEnd,
        surveyInlineIncrement:surveyInlineIncrement,
        surveyXlineIncrement:surveyXlineIncrement,
        forceDisableSamplesCache: true,
        imageCacheSystem: CacheSystem.CloudStorage,
        samplesCacheSystem: CacheSystem.CloudStorage,
        inverted: false
    };
    return tileRequest;
};

export const getTileRequestsFromLine = (volumeType: SurveyType, samplesPerTrace: number, sampleInterval: number, byteSize: number,  tracesIncrement: number,
    surveyLineStart: number, surveyLineEnd: number, seismicVolumeToken: string, seismicVolumePath: string, minSampleValue: number,
    maxSampleValue: number, lineNumber : number, lineType: LineType, lineMetadata: LineStringResponse,
    surveyInlineStart:number, surveyInlineEnd:number, surveyXlineStart:number,surveyXlineEnd:number,surveyInlineIncrement:number,surveyXlineIncrement:number, colorBar: string, tileWidth?: number, tileHeight?: number) => {
    const threeJsXFactor = Math.abs((lineMetadata.TotalTraces) / (tileWidth ?? GeopostThreeJsConstants.xDivisionFactor));
    const threeXTotalTiles = Math.ceil(threeJsXFactor);
    const tracesNumberByTile = Math.round(Math.abs((lineMetadata.TotalTraces) / threeXTotalTiles));
    const tileRequests = [];
    const traceRangeStart = volumeType === SurveyType.Seismic3D ? 0 : 1;
    const traceRangeEnd = volumeType === SurveyType.Seismic3D ? 0 : lineMetadata.TotalTraces;
    for (let i = 0; i < threeXTotalTiles; i++) {
        const tileRequest = getSeismic3DParams(samplesPerTrace, sampleInterval, byteSize, tracesIncrement, surveyLineStart, surveyLineEnd, seismicVolumeToken, seismicVolumePath, minSampleValue, maxSampleValue, lineType, lineMetadata, i, lineNumber, tracesNumberByTile, threeXTotalTiles, surveyInlineStart, surveyInlineEnd, surveyXlineStart, surveyXlineEnd, surveyInlineIncrement, surveyXlineIncrement, colorBar, traceRangeStart, traceRangeEnd, tileHeight);
        tileRequests.push(tileRequest);
    }
    return tileRequests;
};

export const getTotalOfTilesFromLine = (totalTraces: number, tileWidth: number) => {
    const threeJsXFactor = Math.abs((totalTraces) / tileWidth);
    const threeXTotalTiles = Math.ceil(threeJsXFactor);
    return threeXTotalTiles;
};

export const getThreeJsYFactor = (samplesPerTrace: number) => {
    return Math.abs(samplesPerTrace / GeopostThreeJsConstants.yDivisionFactor);
};

export const getRelativeLinesToLoadInFirstIteration = (initialRange: number, totalLines: number) => {
    const linesToLoad : number[] = [];
    const lineSkipFactor = Math.floor(totalLines / initialRange);
    linesToLoad.push(0);
    for (let i = 1; i < initialRange; i++){
        const relativeLineToLoad = i * lineSkipFactor;
        //const lineToLoad = lineStart + lineIncrement * relativeLineToLoad;
        linesToLoad.push(relativeLineToLoad);
    }
    linesToLoad.push(totalLines - 1);
    return linesToLoad;
};

export const getDeeperRelativeLinesToLoad = (loadedLines: number[]) => {
    const updatedLoadedLines = [...loadedLines];
    for (let index = 1; index < updatedLoadedLines.length; index++){
        const previousLine = updatedLoadedLines[index - 1];
        const line = updatedLoadedLines[index];
        let lineNumberToLoad = Math.floor((line - previousLine) / 2) + previousLine;
        if (lineNumberToLoad === previousLine){
            lineNumberToLoad++;
        }
        if (lineNumberToLoad === line){
            lineNumberToLoad--;
        }
        if (lineNumberToLoad !== previousLine && lineNumberToLoad !== line){
            updatedLoadedLines.splice(index, 0, lineNumberToLoad);
            index += 1;
        }
    }
    return updatedLoadedLines;
};

export const getRealLineValue = (relativeValue: number, lineStart: number, lineIncrement: number) => {
    return lineStart + lineIncrement * relativeValue;
};

export const sortLinesFromTheMiddle = (lines: lineMetadata[]) => {
    lines.sort((a, b) => a.lineNumber - b.lineNumber);
    const middleIndex = Math.round(lines.length / 2);
    const pivot = lines[middleIndex];
    lines.sort((a, b) => Math.abs(a.lineNumber - pivot.lineNumber) - Math.abs(b.lineNumber - pivot.lineNumber) || b.lineNumber - a.lineNumber);
};

export const sortLineNumbersFromTheMiddle = (lineNumbers: number[]) => {
    lineNumbers.sort((a, b) => a - b);
    const middleIndex = Math.round(lineNumbers.length / 2);
    const pivot = lineNumbers[middleIndex];
    lineNumbers.sort((a, b) => Math.abs(a - pivot) - Math.abs(b - pivot) || b - a);
};