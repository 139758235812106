import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { AutocompleteElement } from 'react-hook-form-mui';
import { css } from '@mui/material';

import { GeopostLoading } from './GeopostLoading';

const style = css({
    width: '100%',
    position: 'relative',
});

export const GeopostAutocomplete = ({ fieldName, label, items, setInputValue, loading = false, required = true, disabled = false }: Props) => {
    return (
        <Box css={style}>
            <GeopostLoading loading={loading} />
            <AutocompleteElement
                label={label}
                name={fieldName}
                options={items}
                required={required}
                autocompleteProps={{
                    onInputChange: (event, newInputValue) => {
                        setInputValue(newInputValue);
                    }
                }}
            />
        </Box>
    );
};

interface Props {
    fieldName: string;
    label: string;
    items: object[];
    setInputValue: (value: string) => void;
    loading?: boolean;
    required?: boolean;
    disabled?: boolean;
}