import { css } from '@mui/material';

// export const urlEnvVar = 'https://leplac-data-index-api.geopostenergy.com';
//export const urlEnvVar = 'https://localhost:44342';
// export const authTokenEnvVar = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWRtaW4gR2VvcG9zdCBMZXBsYWMiLCJwcm9qZWN0IjoiNDE2NzIxOTUtMTBlMS00YTI1LTlmMGItYjIzZDQzOTgzZmYyIiwibmFtZWlkIjoiMzVkYzVkMTUtMmRhNy00NmZiLTkwM2UtMmQxMDU1NzJiMWFhIiwiY2xpZW50TmFtZSI6IkFkbWluIEdlb3Bvc3QgTGVwbGFjIiwiY2xpZW50SWQiOiIzNWRjNWQxNS0yZGE3LTQ2ZmItOTAzZS0yZDEwNTU3MmIxYWEiLCJwcm9qZWN0c0FsbG93ZWQiOiI0MTY3MjE5NS0xMGUxLTRhMjUtOWYwYi1iMjNkNDM5ODNmZjIiLCJjb250ZXh0IjoiMSIsIm5iZiI6MTcxNzc2Nzc0OSwiZXhwIjoxNzE3ODM5NzQ5LCJpYXQiOjE3MTc3Njc3NDl9.ByyOezgh7PseDf5kD9KzvM6Oj-P_mRJn_dgIn_E6_oE';
// export const tenantToken = '77fb6644-96fc-45d5-a73f-bcf504c73a81';

export const whiteColor = '#ffffff';
export const whiteBackground = '#ffffff';
export const loadingBackground = '#ffffffA0';
// export const loadingBackground = '#000000A0';

export const MainTableCss = css({
    margin: '2rem auto',
    width: '99%',
    overflowY: 'auto',
    borderRadius: '4px',
    '& .table-header': {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        padding: '12px 16px',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0 -3px 5px rgba(0, 0, 0, 0.2), 0 -1px 2px rgba(0, 0, 0, 0.14), 0 0px 1px -2px rgba(0, 0, 0, 0.12)',
    },
    '& .table-header-title': {
        fontSize: '20px',
        letterSpacing: '0.005em',
        fontWeight: '400',
    },
    '& .search': {
        display: 'flex',
        padding: '0 1rem',
        height: '40px',
        justifyContent: 'space-around',
    },
    '& .options-buttons': {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    '& .icon-blocked': {
        cursor: 'not-allowed'
    },
    '& .delete-icon, .edit-icon, .add-icon, .link-icon, .manage-icon, .files-icon': {
        margin: '0 0 0 1rem',
    },
    '& .loading': {
        display: 'flex',
        position: 'absolute',
        backgroundColor: `${loadingBackground}`,
        width: '99%',
        height: 'calc(100vh - 256px)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: '10000',
    },
});