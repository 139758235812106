import { useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IDataGroup, IDataSubGroup, IPreviewFile, IRule, ISubgroup, ISubRule } from './interfaces/ITaxonomy';
import { useSessionStore } from 'session/useSessionStore';

// export function getAllDataSubRule() {
//     return request.get("/Taxonomy/GetAllDataSubRule", {});
//   }

export function useGetOneDataSubRule(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getOneDataSubRule' + token, async () => {
        const response = await axios.request<IResponseAPI<ISubRule>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubRuleByToken',
            params: {
                token,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useGetDataSubRuleBySubGroupToken(subGroupToken: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getDataSubRuleBySubGroupToken' + subGroupToken, async () => {
        const response = await axios.request<IResponseAPI<ISubRule[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubRulesByDataSubGroupToken',
            params: {
                subGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: subGroupToken !== null && subGroupToken !== undefined && subGroupToken !== '',
        cacheTime: 0,
    });
}

export function useDeleteDataSubRule(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteDataSubRule', async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/DeleteDataSubRule',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== ''
    });
}

export function useSaveDataSubRule(data: ISubRule | null) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveDataSubRule' + data?.Token + data?.Rule + data?.Priority + data?.RuleType + data?.DataSubGroupToken + data?.InstitutionToken, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/SaveDataSubRule',
            data: {
                Token: data?.Token,
                Rule: data?.Rule,
                Priority: data?.Priority,
                RuleType: data?.RuleType,
                DataSubGroupToken: data?.DataSubGroupToken,
                InstitutionToken: data?.InstitutionToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: data !== null && data !== undefined
    });
}

export function useGetAllDataSubGroups() {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllDataSubGroups', async () => {
        const response = await axios.request<IResponseAPI<ISubgroup[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetAllDataSubGroup',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
    });
}

export function useGetDataSubGroupByDataGroupToken(dataGroupToken: string | undefined) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getDataSubGroupByDataGroupToken' + dataGroupToken, async () => {
        const response = await axios.request<IResponseAPI<IDataSubGroup>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubGroupByDataGroupToken',
            params: {
                dataGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result.dataSubGroups;
    }, {
        refetchOnWindowFocus: false,
        enabled: dataGroupToken !== null && dataGroupToken !== undefined && dataGroupToken !== '',
    });
}

export function useGetDataSubGroupByDataGroupTokenDropdown(dataGroupToken: string | undefined) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('useGetDataSubGroupByDataGroupTokenDropdown' + dataGroupToken, async () => {
        const response = await axios.request<IResponseAPI<ISubgroup[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubGroupByDataGroupToken',
            params: {
                dataGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: dataGroupToken !== null && dataGroupToken !== undefined && dataGroupToken !== '',
    });
}

export function useGetDataSubGroupAndChildGroupsByDataGroupToken(dataGroupToken: string | undefined) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getDataSubGroupAndChildGroupsByDataGroupToken' + dataGroupToken, async () => {
        const response = await axios.request<IResponseAPI<IDataSubGroup>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubGroupAndChildGroupsByDataGroupToken',
            params: {
                dataGroupToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result.dataSubGroups;
    }, {
        refetchOnWindowFocus: false,
        enabled: dataGroupToken !== null && dataGroupToken !== undefined && dataGroupToken !== '',
        cacheTime: 0,
    });
}

export function useGetOneDataSubGroup(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getOneDataSubGroup' + token, async () => {
        const response = await axios.request<IResponseAPI<ISubgroup>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetDataSubGroupByToken',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== '',
    });
}

export function useDeleteDataSubGroup(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteDataSubGroup' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/DeleteSubGroup',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== ''
    });
}

export function useSaveDataSubGroup(data: ISubgroup | null) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveDataSubGroup', async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/SaveDataSubGroup',
            data: {
                Name: data?.Name,
                Token: data?.Token,
                DataSubRulesTokens: data?.DataSubRulesTokens,
                DataGroupToken: data?.DataGroupToken,
                InternalPath: data?.InternalPath,
                CopyTransmittalFilesToInternalPath: data?.CopyTransmittalFilesToInternalPath,
                TransmittalInternalPath: data?.TransmittalInternalPath,
                DataGroupName: data?.DataGroupName,
                RegexPathExtractRule: data?.RegexPathExtractRule,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: data !== null && data !== undefined
    });
}

export function useGetAllDataGroups() {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllInsitutions', async () => {
        const response = await axios.request<IResponseAPI<IDataGroup[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/GetAllDataGroups',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

// export function getOneDataGroup(token) {
//     return request.get("/Taxonomy/GetDataGroupByToken", {
//       params: {
//         token,
//       },
//     });
//   }

//   export function deleteDataGroup(token) {
//     return request.delete("/Taxonomy/DeleteDataGroup", {
//       params: {
//         token,
//       },
//     });
//   }
//   export function saveDataGroup(data) {
//     return request.post("/Taxonomy/SaveDataGroup", {
//       Name: data.label,
//       Token: data.token,
//       DataSubGroupsTokens: data.subgroups,
//       ParentGroupToken: data.parentGroupToken,
//     });
//   }

export function useTestRules(phrase: string, send: boolean) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('testRules', async () => {
        const response = await axios.request<IResponseAPI<IRule[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/RulesCheck',
            params: {
                phrase
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: phrase !== null && phrase !== undefined && phrase !== '' && send
    });
}

export function usePreviewFilesReindexChange(token: string) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('previewFilesReindexChange', async () => {
        const response = await axios.request<IResponseAPI<IPreviewFile[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/PreviewFilesReindexChange',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: token !== null && token !== undefined && token !== ''
    });
}

export function useReindexSubgroup(subGroupToken: string, send: boolean) {
    const {tenantConfig, jwtToken} = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('reindexSubgroup' + subGroupToken, async () => {
        const response = await axios.request<IResponseAPI<{ error: boolean }>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/Taxonomy/ReindexSubgroup',
            params: {
                subGroupToken
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        enabled: subGroupToken !== null && subGroupToken !== undefined && subGroupToken !== '' && send
    });
}