import { Fill, Stroke, Style, Text } from 'ol/style';

import { ICrossSection } from '../models/interfaces/ICrossSection';
import { ICrossSectionItem } from '../models/interfaces/ICrossSectionItem';
import { IWellAnnotation } from '../models/interfaces/IWellAnnotation';

export function buildStyle(label: string){
    return new Style({
        fill: new Fill({color: 'rgb(4, 31, 60)'}),
        stroke: new Stroke({color: 'rgb(4, 31, 60)', width: 3}),
        text: new Text({
            font: 'bold 12px/1 arial',
            stroke: new Stroke({color: '#ffffff', width: 2}),
            text:label,
            offsetY: 10,
            overflow: true,
            justify: 'left',
            placement: 'line',
            textBaseline: 'alphabetic',
            backgroundFill: new Fill({color: '#CGCGCG'}),
        })
    });
}

export function createGroupKey(annotation: IWellAnnotation){
    if (annotation.OwnerId) {
        return annotation.Name + annotation.OwnerId.toString();
    } else {
        return annotation.Name + '_no_user';
    }
}

export function calculateDisplacementByAnnotation(flatAnnotation: string, annotations: {[wellToken: string]: IWellAnnotation[]}, crossSection: ICrossSection,
    factorVertical: number){
    console.log('teste');
    const displacementes: {[wellToken: string]: number} = {};
    console.log('teste 222');

    if (!crossSection.Itens || crossSection.Itens.length <= 1){
        //se tiver um so poço n tem oq fazer
        return;
    }

    const firstWellId = crossSection.Itens[0].WellId!;
    const firstAnnotation = annotations[firstWellId]?.find(x => x.Annotation + x.OwnerId === flatAnnotation);

    if (!firstAnnotation){
        return;
    }

    const firstDepth = firstAnnotation.Depths[0];

    for (let i=1; i < crossSection.Itens.length; i++){
        const crossItem:ICrossSectionItem = crossSection.Itens[i];
        const wellId = crossItem.WellId;

        if (!wellId){
            continue;
        }

        const annotation = annotations[wellId]?.find(x => x.Annotation + x.OwnerId === flatAnnotation);

        if (!annotation){
            continue;
        }

        displacementes[wellId] = Math.round((firstDepth - annotation.Depths[0]) * factorVertical);

    }

    console.log(annotations, crossSection, firstDepth, displacementes, factorVertical);

    return displacementes;
}