import { Fragment } from 'react';
import { Navbar } from 'fileIndexing/otherComponents/Navbar';
import { NavigationMenu } from 'fileIndexing/otherComponents/NavigationMenu';

import { EntitiesTable } from '../components/EntitiesTable';

export function EntitiesMainPage() {
    return (
        <Fragment>
            <Navbar />
            <NavigationMenu />
            <EntitiesTable />
        </Fragment>
    );
}