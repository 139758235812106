import React, { useCallback, useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, checkboxClasses, CircularProgress, css, FormControlLabel, Grid, Slider, Stack, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { List, ListRowRenderer } from 'react-virtualized';
import { shallow } from 'zustand/shallow';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { IWell } from 'features/seismic/models/interfaces/IWell';
import { IScale, IWellLayers, useSeismicStore } from 'features/seismic/stores/useSeismicStore';
import { Well } from './Well';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { useMapStore } from 'features/seismic/stores/useMapStore';
import { LineType } from 'features/seismic/models/enums/LineType';
import { FetchType } from 'models/enums/FetchType';
import { ApiType } from 'models/enums/ApiType';
import { useAPI } from 'hooks/useAPI';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { useGetAllByPolygon } from './api/useSeismicWellController';
import { IGetWellsByPolygonRequest } from 'features/seismic/models/interfaces/IGetWellsByPolygonRequest';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { use3DCrossingPoints } from './hooks/use3DCrossingPoints';
import { use3DWellGroups } from './hooks/use3DWellGroups';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { use3DAnnotations } from './hooks/use3DAnnotations2';
import { WellAnnotationType } from 'features/seismic/models/enums/WellAnnotationType';
import { Author } from './AnnotationAuthors';
import { use3DLithologies } from './hooks/use3DLithologies';
import { use3DTimeDepths } from './hooks/use3DTimeDepths';
import { GeopostCheckbox } from 'components/geopostCheckbox/GeopostCheckbox';
import { use3DDirectionalPaths } from './hooks/use3DDirectionalPaths';
import { Well3DWebtour } from './Well3DWebtour';

const containerStyle = css({
    width: '100%',
    '& .loading': {
        display: 'flex',
        position: 'fixed',
        zIndex: '2',
        top: 40,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgb(255, 255, 255)',
        opacity: 0.85,
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .MuiButtonBase': {
        display: 'flex',
        alignItems: 'center'
    },
    '& .container-projection-distance': {
        paddingTop: 5,
    },
    '& .well': {
        zIndex: '1',
        bottom: '0',
        right: '0',
        borderRadius: '5px 0 0 0',
        boxShadow: '0 0 9px -3px black',
        flexDirection: 'column',
        height: '100%',
        '& .well-top': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'rgba(0, 30, 58, 0.85)',
            width: '380px',
            height: '17px',
            padding: '10px 5px 10px 10px',
            borderRadius: '5px 0 0 0',
            '& .MuiTypography-root': {
                margin: '0px',
            },
            '& .MuiButtonBase-root': {
                padding: '0px',
                marginRight: '5px',
            },
            '& .MuiSvgIcon-root': {
                width: '20px',
                height: '20px',
                padding: '5px 5px'
            },
        },
        '& .well-container': {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'rgb(255, 255, 255)',
            height: '100%',
            padding: '0 9px',
            '& .wells-container': {
                flexGrow: 1
            },
            '& .MuiTypography-root': {
                color: 'rgb(0, 0, 0)',
            },
            '& .distance-box': {
                width: '80px',
                height: '11px',
            },
            '& .ok-button': {
                backgroundColor: 'rgb(14, 149, 196)',
                color: 'rgb(255, 255, 255)',
                padding: '6px 12px',
                margin: '0 0 0 10px',
                border: '0px',
                borderRadius: '3px',
                fontSize: '14px',
                textAlign: 'center',
                cursor: 'pointer',
            },
            '& .ok-button:hover': {
                backgroundColor: 'rgb(14, 162, 185)',
            },
            '& .MuiSlider-root': {
                color: 'rgb(14, 149, 196)',
                alignSelf: 'center',
                width: '85%',
                margin: '0 2.3%'
            },
            '& .MuiSlider-rail': {
                height: 4,
            },
            '& .MuiSlider-thumb': {
                width: 16,
                height: 16,
                outline: 'none',
                boxShadow: 'none'
            },
            '& .search-box': {
                width: '365px',
                height: '20px',
                alignSelf: 'center',
            },
            '& .checkboxes': {
                justifyContent: 'space-between',
                margin: '10px',
                '& .MuiSvgIcon-root': {
                    fill: 'rgba(14, 149, 196, 0.75)',
                    color: '#fff'
                }
            },
            '& .line': {
                width: '95%',
                border: '1px solid #DEDEDE',
                margin: '0px 0px 7px 0px'
            },
            '& .no-wells': {
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
    },
    '& .row': {
        display: 'flex',
        flexDirection: 'row'
    },
    '& .column': {
        display: 'flex',
        flexDirection: 'column'
    },
    '& .align-items': {
        alignItems: 'center'
    },
    '& .bold': {
        fontWeight: 'bold'
    },
    '& .MuiTypography-root': {
        fontSize: '14px',
        fontFamily: 'Arial, Verdana, sans-serif',
    },
    '& input': {
        outline: 'none',
        border: '1px solid #DEDEDE',
        borderRadius: '2px',
        padding: '6px',
        fontSize: '14px',
        '&:focus': {
            outline: '1px solid rgb(14, 149, 196)'
        },
    },
    '& .MuiAccordion-root': {
        marginBottom: '0px !important',
        marginTop: '10px !important',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2) !important'
    },
    '& .MuiAccordionSummary-root': {
        minHeight: '40px !important',
        '& .MuiAccordionSummary-content': {
            margin: '2px'
        }
    }
});

export function SeismicWellContent({ opened, setOpened, dataGeometryLine, surveyType, runWebtour = false }: Props) {
    const { t } = useTranslation();

    const { surveyMetadata, wellLayers, scale, miniMapWells, wellRelatedDataTextLayers, resetWellRelatedData, resetWellRelatedDataTextLayers, setRemoveWellInMiniMap, setDefineWellLayers } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata,
        wellLayers: state.wellsLayers,
        setWellsLayers: state.setWellsLayers,
        scale: state.scale,
        miniMapWells: state.miniMapWells,
        wellRelatedData: state.wellRelatedData,
        wellRelatedDataTextLayers: state.wellRelatedDataTextLayers,
        resetWellRelatedData: state.resetWellRelatedData,
        resetWellRelatedDataTextLayers: state.resetWellRelatedDataTextLayers,
        setRemoveWellInMiniMap: state.setRemoveWellInMiniMap,
        setDefineWellLayers: state.setDefineWellLayers,
    }));

    const mainFeatureGeomDataFrom3D = use3DViewerStore(state => state.mainFeatureGeomData);

    const { lineNumber, lineType } = useLine3DNavigationStore(state => ({
        lineNumber: state.lineNumber,
        lineType: state.lineType
    }));

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const [projectionDistance, setProjectionDistance] = useState<number>(1000);
    const [projectionDistanceInput, setProjectionDistanceInput] = useState<number>(1000);
    const [mouseRelease, setMouseRelease] = useState<boolean>(true);
    const [checkBox, setCheckBox] = useState({
        Profiles: false,
        TimeDepth: false,
        Hydrocarbon: false,
        RelatedInfo: false,
        GenericCuts: false,
        FormationTop: false,
        FormationTest: false,
        WellBottom: false,
        HasDirectionalData: false,
        Lithology: false
    });

    const [searchText, setSearchText] = useState<string>('');
    const [layersToRecreate, setLayersToRecreate] = useState<IWellLayers>({});

    const inputRef = useRef<HTMLInputElement>(null);
    const lineValues = useRef<[number, IScale, LineType] | []>([]);

    const [getWellsByPolygonRequest, setGetWellsByPolygonRequest] = useState<IGetWellsByPolygonRequest>();
    const [dataWells, setDataWells] = useState<IWell[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { isLoading: isAllByDistanceLoading, execute: executeAllByDistance, data: wellsByDistance } = useAPI<IWell[]>('/Wells/SeismicWells/GetAllByDistance');
    const { isLoading: isAllByPolygonLoading, data: wellsByPolygon } = useGetAllByPolygon(getWellsByPolygonRequest);

    const wellDisplayStyle = opened ? 'flex' : 'none';

    // const { data: dataWells, isLoading } = useGetAllByDistance(dataGeometryLine!.Line, projectionDistance, surveyMetadata!.Domain, dataGeometryLine!.TotalTraces, surveyMetadata!.Header.SamplesPerTrace);

    const handleProjectionDistanceInput = (event: ChangeEvent<HTMLInputElement>) => {
        setProjectionDistanceInput(parseInt(event.target.value));
    };

    const handleProjectionDistanceSlider = (event: ChangeEvent<{}>, value: number | number[]) => {
        let inputValue = typeof value === 'number' ? value : value[0];
        setProjectionDistance(inputValue);
        setProjectionDistanceInput(inputValue);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const button = document.getElementsByClassName('ok-button')[0] as HTMLButtonElement;
            button.click();
        }
    };

    const okButtonClick = () => {
        const newProjectionDistance = projectionDistanceInput < 10000 ? projectionDistanceInput : 10000;
        setProjectionDistance(newProjectionDistance);
        setProjectionDistanceInput(newProjectionDistance);
        setMouseRelease(true);
    };

    const filterWells = useCallback((item: IWell) => {
        if (checkBox.Profiles && !item.Profiles) {
            return false;
        }
        if (checkBox.FormationTest && !item.FormationTest) {
            return false;
        }
        if (checkBox.FormationTop && !item.FormationTop) {
            return false;
        }
        if (checkBox.TimeDepth && !item.TimeDepth) {
            return false;
        }
        if (checkBox.Hydrocarbon && !item.Hydrocarbon) {
            return false;
        }
        if (checkBox.RelatedInfo && !item.RelatedInfo) {
            return false;
        }
        if (checkBox.WellBottom && !item.WellBottom) {
            return false;
        }
        if (checkBox.HasDirectionalData && !item.HasDirectionalData) {
            return false;
        }
        if (checkBox.GenericCuts && !item.GenericCuts) {
            return false;
        }
        if (checkBox.Lithology) {
            const genericCuts = item.GenericCuts.split(';');
            if (!genericCuts.find(genericCut => genericCut.split('|')[2] === '1')) {
                return false;;
            }
        }

        const isSearchTextMatch = searchText === '' || item.Name.includes(searchText);
        if (!isSearchTextMatch) {
            return false;
        }

        return true;
    }, [checkBox, searchText]);

    const handleChange = (event: { target: { name: string; checked: boolean; }; }) => {
        setCheckBox({ ...checkBox, [event.target.name]: event.target.checked });
    };

    const fetchAllDistance = async () => {
        executeAllByDistance({
            type: FetchType.Post,
            apiType: ApiType.WebApi,
            body: {
                lineString: dataGeometryLine!.Line,
                distance: projectionDistance,
                domain: surveyMetadata!.Domain,
                numberOfTraces: dataGeometryLine!.TotalTraces,
                datumSeismic: 0,
                srid: 4326,
                samplesPerTrace: surveyMetadata!.Header.SamplesPerTrace
            }
        });
    };

    const filteredDataWells = useMemo(() => (dataWells?.filter(dataWell => filterWells(dataWell)) ?? []), [dataWells, filterWells]);

    useEffect(() => {
        if (surveyType === SurveyType.Seismic2D) {
            setDataWells(wellsByDistance);
        }
        else {
            setDataWells(wellsByPolygon ?? null);
        }
    }, [wellsByDistance, wellsByPolygon]);

    useEffect(() => {
        if (surveyType === SurveyType.Seismic3D) {
        }
    }, []);

    useEffect(() => {
        if (surveyType === SurveyType.Seismic2D) {
            setIsLoading(isAllByDistanceLoading);
        } else {
            setIsLoading(isAllByPolygonLoading);
        }
    }, [isAllByDistanceLoading, isAllByPolygonLoading]);

    useEffect(() => {
        if (surveyType === SurveyType.Seismic2D && dataGeometryLine && surveyMetadata) {
            fetchAllDistance();
        }
    }, [dataGeometryLine, surveyMetadata]);

    useEffect(() => {
        if (surveyType === SurveyType.Seismic2D && (projectionDistance !== null || projectionDistance !== undefined) && mouseRelease) {
            setMouseRelease(false);
            fetchAllDistance();
        }
    }, [projectionDistance, mouseRelease]);

    useEffect(() => {
        if (surveyType === SurveyType.Seismic3D && !!mainFeatureGeomDataFrom3D) {
            setGetWellsByPolygonRequest({
                polygonSrid: 3857,
                polygonWkt: mainFeatureGeomDataFrom3D.GeomWKT,
                datumSeismic: 0,
                domain: !surveyMetadata ? AmplitudeDomain.Depth : surveyMetadata?.Domain
            });
        }
    }, [mainFeatureGeomDataFrom3D]);

    useEffect(() => {
        if (surveyType === SurveyType.Seismic3D) {
            return;
        }
        if (dataWells !== null && dataWells !== undefined && Object.keys(wellLayers).length !== 0) {
            let recreateLayers: IWellLayers = {};
            let removeLayers: IWellLayers = {};
            for (let key in wellLayers) {
                let layerFound = false;

                for (let j = 0; j < dataWells!.length; j++) {
                    if (key === (dataWells![j].Id).toString()) {
                        layerFound = true;
                        recreateLayers[key] = wellLayers[key];
                        break;
                    }
                }

                if (!layerFound) {
                    removeLayers[key] = wellLayers[key];
                }
            }

            if (lineValues.current[0] === lineNumber && lineValues.current[1] === scale && lineValues.current[2] === lineType) {
                const wellsToRemove = [];
                for (let wellId in removeLayers) {
                    for (let typeKey in removeLayers[wellId]) {
                        map?.removeLayer(removeLayers[wellId][typeKey].layer);

                        if (typeKey === 'crossingPoint') {
                            wellsToRemove.push(miniMapWells[wellId]);
                        }
                    }
                }
                if (wellsToRemove.length > 0) {
                    setRemoveWellInMiniMap(wellsToRemove);
                }
            }
            else if (lineValues.current[0] !== lineNumber || lineValues.current[1] !== scale || lineValues.current[2] !== lineType) {
                const miniMapToRecreate = [];
                for (let wellId in wellLayers) {
                    for (let lineId in wellRelatedDataTextLayers[wellId]) {
                        map?.removeLayer(wellRelatedDataTextLayers[wellId][lineId]);
                    }

                    for (let typeKey in wellLayers[wellId]) {
                        map?.removeLayer(wellLayers[wellId][typeKey].layer);

                        if (typeKey === 'relatedData') {
                            resetWellRelatedData();
                            resetWellRelatedDataTextLayers();
                        }

                        if (typeKey === 'crossingPoint') {
                            miniMapToRecreate.push(miniMapWells[wellId]);
                        }
                    }
                }

                if (Object.keys(recreateLayers).length > 0) {
                    setLayersToRecreate(recreateLayers);
                    setDefineWellLayers(recreateLayers);
                }

                const wellsToRemove = [];
                for (let key in removeLayers) {
                    wellsToRemove.push(miniMapWells[key]);
                }
                if (wellsToRemove.length > 0) {
                    setRemoveWellInMiniMap(wellsToRemove);
                }
            }

            lineValues.current = [lineNumber, scale, lineType];
        }
    }, [dataWells, scale]);

    const {
        main3DFeatureCentroidX,
        main3DFeatureCentroidY,
        seismic3DCountTimeDepth,
        seismic3DHeightPixelFactor,
    } = use3DViewerStore(state => ({
        main3DFeatureCentroidX: state.featureCentroidX,
        main3DFeatureCentroidY: state.featureCentroidY,
        seismic3DCountTimeDepth: state.countTimeDepth,
        seismic3DHeightPixelFactor: state.heightPixelFactor
    }), shallow);

    const crossingPoint3DColor = '#cccc00';

    const { wellGroups: well3DGroups, addWellGroup: addWell3DGroup } = use3DWellGroups(seismic3DCountTimeDepth, seismic3DHeightPixelFactor, main3DFeatureCentroidX, main3DFeatureCentroidY);

    const [wellsWithVisibleCrossingPoint, setWellsWithVisibleCrossingPoint] = useState<IWell[]>([]);
    const [ wellsWithVisibleDirectional, setWellsWithVisibleDirectional ] = useState<IWell[]>([]);
    const [ wellsWithVisibleTimeDepth, setWellsWithVisibleTimeDepth ] = useState<IWell[]>([]);
    const [ wellsWithVisibleHydrocarbon, setWellsWithVisibleHydrocarbon ] = useState<IWell[]>([]);
    const [ wellsWithVisibleFormationTop, setWellsWithVisibleFormationTop ] = useState<IWell[]>([]);
    const [ wellsWithVisibleFormationTest, setWellsWithVisibleFormationTest ] = useState<IWell[]>([]);
    const [ wellsWithVisibleLithology, setWellsWithVisibleLithology ] = useState<IWell[]>([]);

    const hydrocarbonLabel3DColor = '#ffe6ff';
    const hydrocarbonSelectedLabel3DColor = '#ff00ff';
    const formationTestLabel3DColor = '#ffcc80';
    const formationTestSelectedLabel3DColor = '#ff9900';
    const formationTopLabel3DColor = '#66ff66';
    const formationTopSelectedLabel3DColor = '#00ff00';
    const timeDepthLabel3DColor = '#00ffff';
    const selectedTimeDepthLabel3DColor = '#b3ffff';

    use3DCrossingPoints(wellsWithVisibleCrossingPoint, addWell3DGroup, crossingPoint3DColor, main3DFeatureCentroidX, main3DFeatureCentroidY);

    const { directionalWellsLoading } = use3DDirectionalPaths(wellsWithVisibleDirectional, addWell3DGroup, crossingPoint3DColor);

    const [hydrocarbonAuthorsData, setHydrocarbonAuthorsData] = useState<{ wellId: number, authors: Author[]}[]>([]);
    const [ formationTopAuthorsData, setFormationTopAuthorsData ] = useState<{ wellId: number, authors: Author[]}[]>([]);
    const [ formationTestAuthorsData, setFormationTestAuthorsData ] = useState<{ wellId: number, authors: Author[]}[]>([]);

    const { loadingTimeDepthWells } = use3DTimeDepths(wellsWithVisibleTimeDepth, addWell3DGroup, surveyMetadata?.VolumeToken ?? '', 0, timeDepthLabel3DColor, selectedTimeDepthLabel3DColor, !surveyMetadata ? AmplitudeDomain.Depth : surveyMetadata?.Domain);

    const { changeAuthorVisibility: changeHydrocarbonAuthorVisibility, loadingAnnotationWells: loadingHydrocarbonWells } = use3DAnnotations(
        wellsWithVisibleHydrocarbon,
        WellAnnotationType.Hydrocarbon,
        0,
        !surveyMetadata ? AmplitudeDomain.Depth : surveyMetadata?.Domain,
        addWell3DGroup,
        0,
        hydrocarbonLabel3DColor,
        hydrocarbonSelectedLabel3DColor,
        (annotationsData, wellId) => {
            if (!hydrocarbonAuthorsData.find(x => x.wellId = wellId)) {
                setHydrocarbonAuthorsData(current => [...current, {
                    wellId: wellId,
                    authors: annotationsData.items.map(item => ({
                        name: item.User,
                        permission: item.Permission,
                        selected: true
                    }))
                }]);
            }
        }
    );

    const { changeAuthorVisibility: changeFormationTopAuthorVisbility, loadingAnnotationWells: loadingFormationTopWells } = use3DAnnotations(
        wellsWithVisibleFormationTop,
        WellAnnotationType.FormationTop,
        0,
        surveyMetadata?.Domain ?? AmplitudeDomain.Depth,
        addWell3DGroup,
        0,
        formationTopLabel3DColor,
        formationTopSelectedLabel3DColor,
        (annotationsData, wellId) => {
            if (!formationTopAuthorsData.find(x => x.wellId = wellId)) {
                setFormationTopAuthorsData(current => [...current, {
                    wellId: wellId,
                    authors: annotationsData.items.map(item => ({
                        name: item.User,
                        permission: item.Permission,
                        selected: true
                    }))
                }]);
            }
        }
    );

    const { changeAuthorVisibility: changeFormationTestAuthorVisbility, loadingAnnotationWells: loadingFormationTestWells } = use3DAnnotations(
        wellsWithVisibleFormationTest,
        WellAnnotationType.FormationTest,
        0,
        surveyMetadata?.Domain ?? AmplitudeDomain.Depth,
        addWell3DGroup,
        0,
        formationTestLabel3DColor,
        formationTestSelectedLabel3DColor,
        (annotationsData, wellId) => {
            if (!formationTestAuthorsData.find(x => x.wellId = wellId)) {
                setFormationTestAuthorsData(current => [...current, {
                    wellId: wellId,
                    authors: annotationsData.items.map(item => ({
                        name: item.User,
                        permission: item.Permission,
                        selected: true
                    }))
                }]);
            }
        }
    );

    const { loadingLithologyWells } = use3DLithologies(wellsWithVisibleLithology, 0, 0, surveyMetadata?.Domain ?? AmplitudeDomain.Depth, addWell3DGroup);

    const gridSelectedSeismic = use3DGridStore(state => state.gridSelectedSeismic);

    const wellsWithAvailableCrossingPoint = useMemo(() => filteredDataWells.filter(item => !item.HasDirectionalData), [filteredDataWells]);
    const wellsWithAvailableDirectional = useMemo(() => filteredDataWells.filter(item => item.HasDirectionalData), [filteredDataWells]);
    const wellsWithAvailableTimeDepth = useMemo(() => filteredDataWells.filter(item => item.TimeDepth), [filteredDataWells]);
    const wellsWithAvailableFormationTop = useMemo(() => filteredDataWells.filter(item => item.FormationTop), [filteredDataWells]);
    const wellsWithAvailableFormationTest = useMemo(() => filteredDataWells.filter(item => item.FormationTest), [filteredDataWells]);
    const wellsWithAvailableHydrocarbon = useMemo(() => filteredDataWells.filter(item => item.Hydrocarbon), [filteredDataWells]);
    const wellsWithAvailableLithology = useMemo(() =>  filteredDataWells.filter(item => item.GenericCuts.split(';').findIndex(genericCut => genericCut.split('|')[2] === '1') >= 0), [filteredDataWells]);

    const allCrossingPointsChecked = useMemo(() => wellsWithAvailableCrossingPoint.length > 0 && wellsWithAvailableCrossingPoint.every(well => wellsWithVisibleCrossingPoint.includes(well)), [wellsWithAvailableCrossingPoint, wellsWithVisibleCrossingPoint]);
    const allDirectionalCrossingPointChecked = useMemo(() => wellsWithAvailableDirectional.length > 0 && wellsWithAvailableDirectional.every(well => wellsWithVisibleDirectional.includes(well)), [wellsWithAvailableDirectional, wellsWithVisibleDirectional]);
    const allTimeDepthsChecked = useMemo(() => wellsWithAvailableTimeDepth.length > 0 && wellsWithAvailableTimeDepth.every(well => wellsWithVisibleTimeDepth.includes(well)), [wellsWithAvailableTimeDepth, wellsWithVisibleTimeDepth]);
    const allHydrocarbonsChecked = useMemo(() => wellsWithAvailableHydrocarbon.length > 0 && wellsWithAvailableHydrocarbon.every(well => wellsWithVisibleHydrocarbon.includes(well)), [wellsWithAvailableHydrocarbon, wellsWithVisibleHydrocarbon]);
    const allFormationTopChecked = useMemo(() => wellsWithAvailableFormationTop.length > 0 && wellsWithAvailableFormationTop.every(well => wellsWithVisibleFormationTop.includes(well)), [wellsWithAvailableFormationTop, wellsWithVisibleFormationTop]);
    const allFormationTestChecked = useMemo(() => wellsWithAvailableFormationTest.length > 0 && wellsWithAvailableFormationTest.every(well => wellsWithVisibleFormationTest.includes(well)), [wellsWithAvailableFormationTest, wellsWithVisibleFormationTest]);
    const allLithologiesChecked = useMemo(() => wellsWithAvailableLithology.length > 0 && wellsWithAvailableLithology.every(well => wellsWithVisibleLithology.includes(well)), [wellsWithAvailableLithology, wellsWithVisibleLithology]);

    const someWellsWithAvailableDirectional = useMemo(() => wellsWithAvailableDirectional.length > 0, [wellsWithAvailableDirectional]);
    const someWellsWithAvailableTimeDepth = useMemo(() => wellsWithAvailableTimeDepth.length > 0, [wellsWithAvailableTimeDepth]);
    const someWellsWithAvailableFormationTop = useMemo(() => wellsWithAvailableFormationTop.length > 0, [wellsWithAvailableFormationTop]);
    const someWellsWithAvailableFormationTest = useMemo(() => wellsWithAvailableFormationTest.length > 0, [wellsWithAvailableFormationTest]);
    const someWellsWithAvailableHydrocarbon = useMemo(() => wellsWithAvailableHydrocarbon.length > 0, [wellsWithAvailableHydrocarbon]);
    const someWellsWithAvailableLithology = useMemo(() => wellsWithAvailableLithology.length > 0, [wellsWithAvailableLithology]);

    const getWellCheckboxHandler = (wellsWithVisibleItem: IWell[], setWellsWithVisibleItems: (value: React.SetStateAction<IWell[]>) => void) => {
        return (well: IWell, checked: boolean) => {
            if (checked) {
                setWellsWithVisibleItems(current => [...current, well]);
            } else {
                const indexOnVisibleItems = wellsWithVisibleItem.indexOf(well);
                if (indexOnVisibleItems >= 0) {
                    setWellsWithVisibleItems(current => {
                        const newArray = [...current];
                        newArray.splice(indexOnVisibleItems, 1);
                        return newArray;
                    });
                }
            }
        };
    };

    const handleCrossingPointCheckChange = useCallback(getWellCheckboxHandler(wellsWithVisibleCrossingPoint, setWellsWithVisibleCrossingPoint), [wellsWithVisibleCrossingPoint, getWellCheckboxHandler]);
    const handleDirectionalCheckChange = useCallback(getWellCheckboxHandler(wellsWithVisibleDirectional, setWellsWithVisibleDirectional), [wellsWithVisibleDirectional, getWellCheckboxHandler]);
    const handleTimeDepthCheckChange = useCallback(getWellCheckboxHandler(wellsWithVisibleTimeDepth, setWellsWithVisibleTimeDepth), [wellsWithVisibleTimeDepth, getWellCheckboxHandler]);
    const handleHydrocarbonCheckChange = useCallback(getWellCheckboxHandler(wellsWithVisibleHydrocarbon, setWellsWithVisibleHydrocarbon), [wellsWithVisibleHydrocarbon, getWellCheckboxHandler]);
    const handleFormationTopCheckChange = useCallback(getWellCheckboxHandler(wellsWithVisibleFormationTop, setWellsWithVisibleFormationTop), [wellsWithVisibleFormationTop, getWellCheckboxHandler]);
    const handleFormationTestCheckChange = useCallback(getWellCheckboxHandler(wellsWithVisibleFormationTest, setWellsWithVisibleFormationTest), [wellsWithVisibleFormationTest, getWellCheckboxHandler]);
    const handleLithologyCheckChange = useCallback(getWellCheckboxHandler(wellsWithVisibleLithology, setWellsWithVisibleLithology), [wellsWithVisibleLithology, getWellCheckboxHandler]);

    const enableAllCrossingPoints = useCallback(() => {
        setWellsWithVisibleCrossingPoint(wellsWithAvailableCrossingPoint);
    }, [wellsWithAvailableCrossingPoint]);
    const disableAllCrossingPoints = () => setWellsWithVisibleCrossingPoint([]);

    const enableAllDirectionals = useCallback(() => setWellsWithVisibleDirectional(wellsWithAvailableDirectional), [wellsWithAvailableDirectional]);
    const disableAllDirectionals = useCallback(() => setWellsWithVisibleDirectional([]), []);

    const enableAllTimeDepths = useCallback(() => {
        setWellsWithVisibleTimeDepth(wellsWithAvailableTimeDepth);
    }, [wellsWithAvailableTimeDepth]);
    const disableAllTimeDepths = useCallback(() => setWellsWithVisibleTimeDepth([]), []);

    const enableAllHydrocarbons = useCallback(() => setWellsWithVisibleHydrocarbon(wellsWithAvailableHydrocarbon), [wellsWithAvailableHydrocarbon]);
    const disableAllHydrocarbons = useCallback(() => setWellsWithVisibleHydrocarbon([]), []);

    const enableAllFormationTop = useCallback(() => setWellsWithVisibleFormationTop(wellsWithAvailableFormationTop), [wellsWithAvailableFormationTop]);
    const disableAllFormationTop = useCallback(() => setWellsWithVisibleFormationTop([]), []);

    const enableAllFormationTest = useCallback(() => setWellsWithVisibleFormationTest(wellsWithAvailableFormationTest), [wellsWithAvailableFormationTest]);
    const disableAllFormationTest = useCallback(() => setWellsWithVisibleFormationTest([]), []);

    const enableAllLithology = useCallback(() => setWellsWithVisibleLithology(wellsWithAvailableLithology), [wellsWithAvailableLithology]);
    const disableAllLithology = useCallback(() => setWellsWithVisibleLithology([]), []);

    useEffect(() => {
        console.log(wellsWithVisibleCrossingPoint);
    }, [wellsWithVisibleCrossingPoint]);

    // useEffect(() => {
    //     map?.on('pointermove', (e) => {
    //         console.log(e.coordinate);
    //     });
    // }, []);

    return (
        <Box css={containerStyle}>
            {isLoading &&
                <Box className='loading'>
                    <CircularProgress />
                </Box>
            }

            <Box className='well' style={{ display: wellDisplayStyle, overflow: 'auto' }} >
                <Box className='well-container'>
                    {
                        surveyType === SurveyType.Seismic2D
                        &&
                        <>
                            <Box className='row align-items container-projection-distance'>
                                <Typography>{t('projectionDistance')}:&nbsp;</Typography>
                                <Box className='row align-items'>
                                    <input ref={inputRef} type='number' value={projectionDistanceInput} className='distance-box' onChange={handleProjectionDistanceInput} onKeyDown={handleKeyDown}></input>
                                    <Typography>&nbsp;[m]</Typography>
                                </Box>
                                <button className="ok-button" onClick={okButtonClick}>OK</button>
                            </Box>
                            <Slider
                                value={projectionDistance}
                                min={0}
                                max={10000}
                                // não sei como resolver isso sem o any
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                onChange={handleProjectionDistanceSlider as any}
                                onMouseUp={() => { setMouseRelease(true) }}
                            />
                        </>

                    }
                    <input className='search-box' placeholder={t('search')} value={searchText} onChange={(event) => setSearchText(event.target.value)}></input>
                    <Accordion
                    >
                        <AccordionSummary
                            id='toggle-well-filters-button'
                            className='troggle-well-filters-button'
                            expandIcon={<ExpandMore/>}
                        >
                            <Typography variant='body1' sx={{fontWeight: 'bold'}}>Data Type Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className='checkboxes row'>
                                <Box className='column'>
                                    <FormControlLabel
                                        control={<Checkbox className='wells-filter-checkbox' checked={checkBox.Profiles} onChange={handleChange} name='Profiles' />}
                                        label={<Typography>{t('profile')}</Typography>}
                                    />
                                    {
                                        someWellsWithAvailableTimeDepth
                                        &&
                                        <FormControlLabel
                                            control={<Checkbox className='wells-filter-checkbox' checked={checkBox.TimeDepth} onChange={handleChange} name='TimeDepth' />}
                                            label={<Typography>{t('timeDepth')}</Typography>}
                                        />
                                    }
                                    {
                                        someWellsWithAvailableHydrocarbon
                                        &&
                                        <FormControlLabel
                                            control={<Checkbox className='wells-filter-checkbox' checked={checkBox.Hydrocarbon} onChange={handleChange} name='Hydrocarbon' />}
                                            label={<Typography>{t('hydrocarbon')}</Typography>}
                                        />
                                    }
                                    <FormControlLabel
                                        control={<Checkbox className='wells-filter-checkbox' checked={checkBox.RelatedInfo} onChange={handleChange} name='RelatedInfo' />}
                                        label={<Typography>{t('relatedData')}</Typography>}
                                    />
                                    {
                                        someWellsWithAvailableLithology
                                        &&
                                        <FormControlLabel
                                            control={<Checkbox className='wells-filter-checkbox' checked={checkBox.Lithology} onChange={handleChange} name='Lithology' />}
                                            label={<Typography>{/*TODO: Adicionar tradução */ 'Lithology'}</Typography>}
                                        />
                                    }
                                </Box>

                                <Box className='column'>
                                    <FormControlLabel
                                        control={<Checkbox className='wells-filter-checkbox' checked={checkBox.GenericCuts} onChange={handleChange} name='GenericCuts' />}
                                        label={<Typography>{t('tracking')}</Typography>}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox className='wells-filter-checkbox' checked={checkBox.FormationTop} onChange={handleChange} name='FormationTop' />}
                                        label={<Typography>{t('formationTop')}</Typography>}
                                    />
                                    {
                                        someWellsWithAvailableFormationTest
                                        &&
                                        <FormControlLabel
                                            control={<Checkbox className='wells-filter-checkbox' checked={checkBox.FormationTest} onChange={handleChange} name='FormationTest' />}
                                            label={<Typography>{t('formationTest')}</Typography>}
                                        />
                                    }
                                    <FormControlLabel
                                        control={<Checkbox className='wells-filter-checkbox' checked={checkBox.WellBottom} onChange={handleChange} name='WellBottom' />}
                                        label={<Typography>{t('bottomWell')}</Typography>}
                                    />
                                    {
                                        surveyType === SurveyType.Seismic3D
                                        &&
                                        someWellsWithAvailableDirectional
                                        &&
                                        <FormControlLabel
                                            control={<Checkbox className='wells-filter-checkbox' checked={checkBox.HasDirectionalData} onChange={handleChange} name='HasDirectionalData' />}
                                            label={<Typography>{/*TODO: Adicionar tradução */ 'Directional Path'}</Typography>}
                                        />
                                    }
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    {
                        surveyType === SurveyType.Seismic3D
                        &&
                        <>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore id='toggle-well-select-all-button'/>}
                                >
                                    <Typography variant='body1' sx={{fontWeight: 'bold'}}>Select all</Typography>
                                </AccordionSummary>
                                <Box>
                                    <Box className='checkboxes row'>
                                        <Box className='column'>
                                            <FormControlLabel
                                                control={<GeopostCheckbox className='select-all-checkbox' disabled={wellsWithAvailableCrossingPoint.length < 1} loading={false} checked={allCrossingPointsChecked} onChange={(checked) => checked ? enableAllCrossingPoints() : disableAllCrossingPoints()}/>}
                                                label={<Typography>Crossing point</Typography>}
                                            />
                                            {
                                                someWellsWithAvailableDirectional
                                                &&
                                                <FormControlLabel
                                                    control={<GeopostCheckbox className='select-all-checkbox' disabled={wellsWithAvailableDirectional.length < 1} loading={directionalWellsLoading.length > 0} checked={allDirectionalCrossingPointChecked} onChange={(checked) => checked ? enableAllDirectionals() : disableAllDirectionals()}/>}
                                                    label={<Typography>Directional path</Typography>}
                                                />
                                            }
                                            {
                                                someWellsWithAvailableTimeDepth
                                                &&
                                                <FormControlLabel
                                                    control={<GeopostCheckbox className='select-all-checkbox' disabled={wellsWithAvailableTimeDepth.length < 1}  loading={loadingTimeDepthWells.length > 0} checked={allTimeDepthsChecked} onChange={(checked) => checked ? enableAllTimeDepths() : disableAllTimeDepths()}/>}
                                                    label={<Typography>Time Depth</Typography>}
                                                />
                                            }
                                            {
                                                someWellsWithAvailableHydrocarbon
                                                &&
                                                <FormControlLabel
                                                    control={<GeopostCheckbox className='select-all-checkbox' disabled={wellsWithAvailableHydrocarbon.length < 1} loading={loadingHydrocarbonWells.length > 0} checked={allHydrocarbonsChecked} onChange={(checked) => checked ? enableAllHydrocarbons() : disableAllHydrocarbons()}/>}
                                                    label={<Typography>Hydrocarbon</Typography>}
                                                />
                                            }
                                        </Box>
                                        <Box className='column'>
                                            {
                                                someWellsWithAvailableFormationTop
                                                &&
                                                <FormControlLabel
                                                    control={<GeopostCheckbox className='select-all-checkbox' disabled={wellsWithAvailableFormationTop.length < 1} loading={loadingFormationTopWells.length > 0} checked={allFormationTopChecked} onChange={(checked) => checked ? enableAllFormationTop() : disableAllFormationTop()}/>}
                                                    label={<Typography>Formation Top</Typography>}
                                                />
                                            }
                                            {
                                                someWellsWithAvailableFormationTest
                                                &&
                                                <FormControlLabel
                                                    control={<GeopostCheckbox className='select-all-checkbox' disabled={wellsWithAvailableFormationTest.length < 1} loading={loadingFormationTestWells.length > 0} checked={allFormationTestChecked} onChange={(checked) => checked ? enableAllFormationTest() : disableAllFormationTest()}/>}
                                                    label={<Typography>Formation Test</Typography>}
                                                />
                                            }
                                            {
                                                someWellsWithAvailableLithology
                                                &&
                                                <FormControlLabel
                                                    control={<GeopostCheckbox className='select-all-checkbox' disabled={wellsWithAvailableLithology.length < 1} loading={loadingLithologyWells.length > 0} checked={allLithologiesChecked} onChange={(checked) => checked ? enableAllLithology() : disableAllLithology()}/>}
                                                    label={<Typography>Lithology</Typography>}
                                                />
                                            }

                                        </Box>
                                    </Box>
                                </Box>
                            </Accordion>
                        </>
                    }

                    {(dataWells && filteredDataWells.length > 0) ?
                        (
                            <Box className='wells-container' overflow='auto'>
                                {
                                    surveyType === SurveyType.Seismic2D ?
                                        <Box overflow={'auto'}>
                                            {
                                                filteredDataWells.map(filteredDataWell => (
                                                    <Well
                                                        filterOn={true}
                                                        key={'well-wrapper-container-' + filteredDataWell.Id}
                                                        domain={surveyMetadata?.Domain ?? AmplitudeDomain.Depth}
                                                        data={filteredDataWell}
                                                        dataGeometryLine={dataGeometryLine}
                                                        layersToRecreate={layersToRecreate}
                                                        surveyType={surveyType}
                                                        showNoTimeDepthIcon={surveyMetadata?.Domain === AmplitudeDomain.Time && !filteredDataWell.TimeDepth}
                                                    />))
                                            }
                                        </Box>
                                        :
                                        <AutoSizer
                                            className={
                                                (wellsWithVisibleCrossingPoint.length +
                                                wellsWithVisibleFormationTop.length +
                                                wellsWithVisibleHydrocarbon.length +
                                                wellsWithVisibleFormationTest.length +
                                                wellsWithVisibleLithology.length +
                                                wellsWithVisibleTimeDepth.length).toString()
                                            }
                                        >
                                            {
                                                ({ height, width }: { height: number, width: number }) => (
                                                    <List
                                                        data={
                                                            wellsWithVisibleCrossingPoint.length +
                                                            wellsWithVisibleFormationTop.length +
                                                            wellsWithVisibleHydrocarbon.length +
                                                            wellsWithVisibleFormationTest.length +
                                                            wellsWithVisibleLithology.length +
                                                            wellsWithVisibleTimeDepth.length
                                                        }
                                                        height={height}
                                                        width={width}
                                                        rowCount={filteredDataWells.length}
                                                        rowHeight={({ index }) => {
                                                            const item = filteredDataWells[index];

                                                            let size = 50;

                                                            if (item.TimeDepth) {
                                                                size += 25;
                                                            }
                                                            if (item.FormationTop) {
                                                                size += 25;
                                                            }
                                                            if (item.FormationTest) {
                                                                size += 25;
                                                            }
                                                            if (!!item.GenericCuts) {
                                                                item.GenericCuts.split(';').forEach(() => size += 25);
                                                            }
                                                            if (item.RelatedInfo) {
                                                                size += 25;
                                                            }
                                                            if (item.Hydrocarbon) {
                                                                size += 25;
                                                            }

                                                            return size;
                                                        }}
                                                        rowRenderer={({ index, style }) => {
                                                            const item = filteredDataWells[index];
                                                            return (
                                                                <div key={index} style={style}>
                                                                    <Well
                                                                        key={'well-wrapper-container-' + item.Id}
                                                                        domain={surveyMetadata?.Domain ?? AmplitudeDomain.Depth}
                                                                        data={item}
                                                                        filterOn={true}
                                                                        dataGeometryLine={dataGeometryLine}
                                                                        layersToRecreate={layersToRecreate}
                                                                        surveyType={surveyType}

                                                                        crossingPointChecked={item.HasDirectionalData ? wellsWithVisibleDirectional.includes(item) : wellsWithVisibleCrossingPoint.includes(item)}
                                                                        onCrossingPointCheckChange={(checked) => item.HasDirectionalData ? handleDirectionalCheckChange(item, checked) : handleCrossingPointCheckChange(item, checked)}
                                                                        isCrossingPointLoading={directionalWellsLoading.includes(item)}

                                                                        timeDepthChecked={wellsWithVisibleTimeDepth.includes(item)}
                                                                        isTimeDepthLoading={loadingTimeDepthWells.includes(item)}
                                                                        onTimeDepthCheckChange={(checked) => handleTimeDepthCheckChange(item, checked)}

                                                                        hydrocarbonAuthors={hydrocarbonAuthorsData.find(x => x.wellId === item.Id)?.authors}
                                                                        hydrocarbonChecked={wellsWithVisibleHydrocarbon.includes(item)}
                                                                        isHydrocarbonLoading={loadingHydrocarbonWells.includes(item)}
                                                                        onHydrocarbonCheckChange={(checked) => handleHydrocarbonCheckChange(item, checked)}
                                                                        onChangeHydrocarbonAuthorVisibility={author => changeHydrocarbonAuthorVisibility(author.name, item.Id)}

                                                                        formationTopChecked={wellsWithVisibleFormationTop.includes(item)}
                                                                        isFormationTopLoading={loadingFormationTopWells.includes(item)}
                                                                        formationTopAuthors={formationTopAuthorsData.find(x => x.wellId = item.Id)?.authors}
                                                                        onFormationTopCheckChange={checked => handleFormationTopCheckChange(item, checked)}
                                                                        onChangeFormationTopAuthorVisbility={author => changeFormationTopAuthorVisbility(author.name, item.Id)}

                                                                        formationTestChecked={wellsWithVisibleFormationTest.includes(item)}
                                                                        isFormationTestLoading={loadingFormationTestWells.includes(item)}
                                                                        formationTestAuthors={formationTestAuthorsData.find(x => x.wellId = item.Id)?.authors}
                                                                        onFormationTestCheckChange={checked => handleFormationTestCheckChange(item, checked)}
                                                                        onChangeFormationTestAuthorVisbility={author => changeFormationTestAuthorVisbility(author.name, item.Id)}

                                                                        lithologyChecked={wellsWithVisibleLithology.includes(item)}
                                                                        isLithologyLoading={loadingLithologyWells.includes(item)}
                                                                        onGenericCutCheckChange={(checked, elementToken) => handleLithologyCheckChange(item, checked)}

                                                                        showNoTimeDepthIcon={surveyMetadata?.Domain === AmplitudeDomain.Time && !item.TimeDepth}
                                                                    />
                                                                </div>
                                                            );
                                                        }}
                                                    >
                                                    </List>
                                                )
                                            }
                                        </AutoSizer>
                                }
                            </Box>
                        ) : (
                            <Box className='no-wells'>
                                <Typography className='bold'>Sem poços</Typography>
                            </Box>
                        )
                    }
                </Box>
            </Box>
            <Well3DWebtour run={runWebtour && opened} isWellsLoading={isLoading}/>
        </Box>
    );
}

interface Props {
    opened: boolean;
    setOpened: (value: boolean) => void;
    dataGeometryLine: ILineString | null;
    surveyType: SurveyType;
    runWebtour?: boolean;
}