import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { LineType } from 'features/seismic/models/enums/LineType';
import { SeismicAttribute } from 'features/seismic/models/interfaces/ISurveyMetadata';
import { useLine3DNavigationStore } from 'features/seismic/stores/useLine3DNavigationStore';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';

export function SeismicAttributesContent({ seismicAttributes, closePopup }: Props) {
    const { lineNumber, setLineNumber, lineType } = useLine3DNavigationStore(state => ({
        lineNumber: state.lineNumber,
        setLineNumber: state.setLineNumber,
        lineType: state.lineType
    }));

    const { surveyMetadata } = useSeismicStore(state => ({
        surveyMetadata: state.surveyMetadata,
    }));

    function getClosestLine(startProduct:number, endProduct: number, incrementProduct:number, line:number, lineIncrement: number | undefined) {
        let productLinesMatched = getMatchedLines(startProduct, endProduct, incrementProduct, line, lineIncrement) as number[];
        let minDistance = 0;
        let closestLine = 0;
        for (let i = 0; i < productLinesMatched.length; i++) {
            let distance = Math.abs(lineNumber - productLinesMatched[i]);
            if (i === 0) {
                minDistance = distance;
                closestLine = productLinesMatched[i];
                break;
            } else {
                if (distance < minDistance) {
                    minDistance = distance;
                    closestLine = productLinesMatched[i];
                }
            }
        }

        return closestLine;
    }

    function getClosestLineControl(startProduct:number, endProduct: number, incrementProduct:number, line:number, lineIncrement: number | undefined) {
        let closestLine = getClosestLine(startProduct, endProduct, incrementProduct, line, lineIncrement);
        if (closestLine === 0) {
            return 'N/A';
        } else {
            return closestLine.toString();
        }
    }

    function getClosestLineColor(startProduct:number, endProduct: number, incrementProduct:number, line:number, lineIncrement: number | undefined) {
        let closestLine = getClosestLine(startProduct, endProduct, incrementProduct, line, lineIncrement);
        if (closestLine === 0) {
            return 'rgb(199 100 100)';
        } else {
            return '#011045';
        }
    }

    function getMatchedLines(startProduct:number, endProduct: number, incrementProduct:number, line:number, lineIncrement: number | undefined) {
        if (surveyMetadata) {
            let mainLines:number[] = [];
            if (lineType === LineType.Inline) {
                for (let i = surveyMetadata.Survey3DInfo.InlineStart; i <= surveyMetadata.Survey3DInfo.InlineEnd; i += surveyMetadata.Survey3DInfo.InlineIncrement) {
                    mainLines.push(i);
                }
            } else if (lineType === LineType.Xline) {
                for (let i = surveyMetadata.Survey3DInfo.XlineStart; i <= surveyMetadata.Survey3DInfo.XlineEnd; i += surveyMetadata.Survey3DInfo.XlineIncrement) {
                    mainLines.push(i);
                }
            }

            let productLines = [];
            for (let i = startProduct; i <= endProduct; i += incrementProduct) {
                productLines.push(i);
            }

            let productLinesMatched = productLines.filter((x) => mainLines.includes(x));
            return productLinesMatched;
        }
    }

    function getClosestLineOld(startProduct:number, endProduct: number, incrementProduct:number, line:number, lineIncrement: number | undefined) {
        if (lineIncrement) {
            if (surveyMetadata?.Survey3DInfo?.XlineStart && surveyMetadata?.Survey3DInfo?.InlineStart){
                if (endProduct <= surveyMetadata?.Survey3DInfo?.XlineStart || startProduct >= surveyMetadata?.Survey3DInfo?.XlineEnd) {
                    return 0;
                }
            } else {
                return 0;
            }

            let productLines = [];
            for (let i = startProduct; i <= endProduct; i += incrementProduct) {
                productLines.push(i);
            }

            // let closestLine = productLines.filter((x) => x >= line);
            // return closestLine[0];

            let increment = 0;
            let higherIncrement = incrementProduct >= lineIncrement ? incrementProduct : lineIncrement;
            let lowerIncrement = incrementProduct <= lineIncrement ? incrementProduct : lineIncrement;

            if (higherIncrement % lowerIncrement === 0) {
                increment = higherIncrement;
            }
            else {
                increment = higherIncrement * lowerIncrement;
            }

            if (line > endProduct) {
                if (line % increment !== 0) {
                    return endProduct - increment;
                }
                return endProduct;
            }

            if (line < startProduct) {
                if (line % increment !== 0) {
                    return startProduct + increment;
                }
                return startProduct;
            }
            return line - increment;
        }
        return 0;
    }

    return (
        <Box style={{padding: '5px'}}>
            {seismicAttributes &&
                <Box>
                    {surveyMetadata &&
                        <Box>
                            <Typography>Current viewing information</Typography>
                            <Typography>Product: {seismicAttributes.baseProductName}</Typography>
                            <Typography>Line: {lineType === LineType.Inline ? 'Inline' : (lineType === LineType.Xline ? 'Xline' : '')} - {lineNumber}</Typography>
                            <Typography>Product Inline Grid: Start {surveyMetadata.Survey3DInfo.InlineStart} | Increment {surveyMetadata.Survey3DInfo.InlineIncrement} | End {surveyMetadata.Survey3DInfo.InlineEnd}</Typography>
                            <Typography>Product Xline Grid: Start {surveyMetadata.Survey3DInfo.XlineStart} | Increment {surveyMetadata.Survey3DInfo.XlineIncrement} | End {surveyMetadata.Survey3DInfo.XlineEnd}</Typography>
                        </Box>
                    }

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{width: '28%'}} align='left'>Type</TableCell>
                                    <TableCell style={{width: '28%'}} align='left'>Product</TableCell>
                                    <TableCell style={{width: '7%'}} align='center'>IL Start</TableCell>
                                    <TableCell style={{width: '7%'}} align='center'>IL Inc</TableCell>
                                    <TableCell style={{width: '7%'}} align='center'>IL End</TableCell>
                                    <TableCell style={{width: '7%'}} align='center'>XL Start</TableCell>
                                    <TableCell style={{width: '7%'}} align='center'>XL Inc</TableCell>
                                    <TableCell style={{width: '7%'}} align='center'>XL End</TableCell>
                                    <TableCell style={{width: '10%'}} align='center'>Closest IL/XL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {seismicAttributes.all.map((attribute) => (
                                    <TableRow style={{width: '100%'}}
                                        key={attribute.Product}
                                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell style={{width: '28%'}}>{attribute.VolumeName}</TableCell>
                                        <TableCell style={{width: '28%'}}>{attribute.Product}</TableCell>
                                        <TableCell style={{width: '7%'}} align='center'>{attribute.Survey3DInfo.InlineStart}</TableCell>
                                        <TableCell style={{width: '7%'}} align='center'>{attribute.Survey3DInfo.InlineIncrement}</TableCell>
                                        <TableCell style={{width: '7%'}} align='center'>{attribute.Survey3DInfo.InlineEnd}</TableCell>
                                        <TableCell style={{width: '7%'}} align='center'>{attribute.Survey3DInfo.XlineStart}</TableCell>
                                        <TableCell style={{width: '7%'}} align='center'>{attribute.Survey3DInfo.XlineIncrement}</TableCell>
                                        <TableCell style={{width: '7%'}} align='center'>{attribute.Survey3DInfo.XlineEnd}</TableCell>
                                        <TableCell style={{width: '10%'}} align='center'>
                                            <Box style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                                {lineType === LineType.Inline &&
                                                    <Box
                                                        style={{color: '#ffffff', backgroundColor: getClosestLineColor(attribute.Survey3DInfo.InlineStart, attribute.Survey3DInfo.InlineEnd, attribute.Survey3DInfo.InlineIncrement, lineNumber, surveyMetadata?.Survey3DInfo?.InlineIncrement), borderRadius: '5px', cursor: 'pointer', width: 'fit-content', padding: '5px'}}
                                                        onClick={() => {
                                                            let matchedLine = getClosestLine(attribute.Survey3DInfo.InlineStart, attribute.Survey3DInfo.InlineEnd, attribute.Survey3DInfo.InlineIncrement, lineNumber, surveyMetadata?.Survey3DInfo?.InlineIncrement);
                                                            if (matchedLine > 0){
                                                                setLineNumber(matchedLine);
                                                                closePopup();
                                                            }
                                                        }
                                                        }>
                                                        {getClosestLineControl(attribute.Survey3DInfo.InlineStart, attribute.Survey3DInfo.InlineEnd, attribute.Survey3DInfo.InlineIncrement, lineNumber, surveyMetadata?.Survey3DInfo?.InlineIncrement)}
                                                    </Box>
                                                }
                                                {lineType === LineType.Xline &&
                                                    <Box
                                                        style={{backgroundColor: getClosestLineColor(attribute.Survey3DInfo.XlineStart, attribute.Survey3DInfo.XlineEnd, attribute.Survey3DInfo.XlineIncrement, lineNumber, surveyMetadata?.Survey3DInfo?.InlineIncrement), borderRadius: '5px', cursor: 'pointer', width: 'fit-content', padding: '5px', color: '#ffffff'}}
                                                        onClick={() => {
                                                            let matchedLine = getClosestLine(attribute.Survey3DInfo.XlineStart, attribute.Survey3DInfo.XlineEnd, attribute.Survey3DInfo.XlineIncrement, lineNumber, surveyMetadata?.Survey3DInfo?.InlineIncrement);
                                                            if (matchedLine > 0){
                                                                setLineNumber(matchedLine);
                                                                closePopup();
                                                            }
                                                        }}>
                                                        {getClosestLineControl(attribute.Survey3DInfo.XlineStart, attribute.Survey3DInfo.XlineEnd, attribute.Survey3DInfo.XlineIncrement, lineNumber, surveyMetadata?.Survey3DInfo?.XlineIncrement)}
                                                    </Box>
                                                }
                                                {lineType !== LineType.Inline && lineType !== LineType.Xline &&
                                                    <Typography>-</Typography>
                                                }
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </Box>
    );
}

interface Props {
    seismicAttributes: {
        baseProductName: string,
        all: SeismicAttribute[]
    } | undefined;
    closePopup: () => void;
}