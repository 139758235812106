import { Button, css, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvertColorsIcon from '@mui/icons-material/InvertColors';

import { useSeismicStore } from '../stores/useSeismicStore';
import { SeismicColorbarSelect } from './SeismicColorbarSelect';

const imageStyle = css({
    height: '20px',
    width: '20px',
    borderRadius: '3px',
});

const containerStyle = css({
    '& .MuiFormControl-root': {
        margin: '0px',
        '& .MuiOutlinedInput-notchedOutline': {
            '& legend': {
                width: '0px',
            }
        }
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: 'white',
            borderWidth: '1px',
            borderRadius: '5px'
        }
    }
});
export function SeismicColorbarNavbar ({setOpenedLabelColorbar}:Props){
    const { setColorbar, colorbar } = useSeismicStore(state => ({
        colorbar: state.colorbar,
        setColorbar: state.setColorbar
    }));

    const [isInverted, setIsInverted] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [ isPanelVisible, setIsPanelVisible ] = useState(false);
    const handleClose = () => setIsPanelVisible(false);

    const { t } = useTranslation();
    const iconButtonRef = useRef(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setIsPanelVisible(true);
        setAnchorEl(iconButtonRef.current);
    };

    const classNameLabelColorbar = (isPanelVisible) ? 'active': '';
    const classNamePolarity = (isInverted) ? 'active': '';

    useEffect(() => {
        if (isInverted) {
            const invertedColorbar = {...colorbar};
            invertedColorbar.Name = invertedColorbar.Name + '_r';
            setColorbar(invertedColorbar);
        } else {
            const originalColorBar = {...colorbar};
            originalColorBar.Name = originalColorBar.Name.replace('_r', '');
            setColorbar(originalColorBar);
        }
    }, [isInverted]);

    useEffect(() => {
        if (!colorbar.Name.includes('_r') && isInverted){
            setIsInverted(false);
        }
    }, [colorbar?.Name]);

    //onClick={() => /*setOpenedLabelColorbar(!openedLabelColorbar)*/ null}
    return  <Fragment>
        <IconButton style={{width: '38px', height: '38px'}} id='colorbar' title={t('colorbar')} className={classNameLabelColorbar} ref={iconButtonRef} onClick={handleContextMenu} >
            <img src={colorbar.Picture} css={imageStyle}/>
        </IconButton>
        <IconButton id='colorbar_inv' title={'Click to invert the color polarity'} size='medium' className={classNamePolarity} onClick={() => setIsInverted(!isInverted)} style={{fontSize: '22px'}} >
            <InvertColorsIcon style={{fontSize: '22px', color: 'white'}}></InvertColorsIcon>
        </IconButton>
        <Menu
            keepMounted
            anchorEl={anchorEl}
            open={isPanelVisible}
            onClose={handleClose}
        >
            {/*<MenuItem style={{backgroundColor: 'grey', margin:0}}>
                <Typography>{t('colorbar')}</Typography>
            </MenuItem>*/}
            <MenuItem css={containerStyle}>
                <SeismicColorbarSelect/>
            </MenuItem>
            <MenuItem>
                <Button fullWidth variant='outlined' onClick={() => setOpenedLabelColorbar && setOpenedLabelColorbar(true)}>Legend</Button>
            </MenuItem>
        </Menu>
    </Fragment>;
}

interface Props {
    setOpenedLabelColorbar?: (value: boolean) => void
}