import { Checkbox, CircularProgress } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { useGetFeaturesIntersectionsByGeometry, useGetIntersection } from './api/useSeismicController';
import { IIntersections } from 'features/seismic/models/interfaces/IIntersection';

export function SeismicCheckbox({token, dataGeometryLine, srid, afterGetCallback}:
    {token: string, srid:number, dataGeometryLine: ILineString | null, afterGetCallback: (intersectionInformation: IIntersections[], token: string) => void}){

    const [checked, setChecked] = useState(false);

    const { data: dataGetFeatures, isLoading: isLoadingGetFeatures } = useGetFeaturesIntersectionsByGeometry(checked ? token:'', dataGeometryLine?.Line, dataGeometryLine?.TotalTraces, srid);
    const { data: dataGetIntersection, isLoading: isLoadingGetIntersection } = useGetIntersection(token, dataGeometryLine?.Line, srid);

    const handleCheckboxChange = ( event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);

        if (!event.target.checked){
            afterGetCallback([], token);
        }
    };

    useEffect(() => {
        if (dataGetFeatures){
            afterGetCallback(dataGetFeatures, token);
        }
    }, [dataGetFeatures]);

    if (isLoadingGetFeatures || isLoadingGetIntersection){
        return <CircularProgress />;
    }

    return <Checkbox disabled={dataGetIntersection ? dataGetIntersection === 0:true}
        checked={checked}
        title={!dataGetIntersection || dataGetIntersection === 0 ? 'There is no intersections of this layer with this seismic line' : 'Click to show the projections'}
        //style={{backgroundColor: 'red'}}
        onChange={handleCheckboxChange} />;
}