import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { useGetLineGeometries, useGetLineGeometry, useGetTiles } from '../api/useSeismic3DInfoController';
import { getFirstAndLastCoordinatesFromGeomWkt } from './projections/featureGeomPolygon';
import { LineType } from '../models/enums/LineType';
import {  IGetLineGeometryRequest } from '../models/interfaces/requests/IGetLineGeometryRequest';
import { lineMetadata } from '../models/types/LineMetadata';
import { Metadata } from '../models/types/Metadata';
import { Metadata3D } from '../models/types/Metadata3D';
import { TileRequest } from '../models/types/TileRequest';
import { GeopostScene } from '../threejs/scene/GeopostScene';
import { getThreeJsYFactor, getTileRequestsFromLine, getTotalOfTilesFromLine, sortLinesFromTheMiddle } from '../utils/TileUtils';
import { VolumeType } from '../models/enums/VolumeType';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { GeopostThreeJsConstants } from '../threejs/utils/GeopostThreeJsConstants';
export type Use3DLinesProps = {
    lineNumbersToLoad: number[];
    seismicMetadata: Metadata<Metadata3D> | undefined;
    lineType: LineType;
    srid: number;
    scene: GeopostScene;
    searchedLineNumber: number | null;
    lineLoadingStep: number,
    setSelectedLineNumber: (value: number) => void,
    setIsPriorityLineLoading: (isLoading: boolean) => void,
    onLoadedLineNumbersChange: (loadedLineNumbers: number[]) => void,
    onTotalImageLoadedLinesChange: (total: number) => void,
    onLoadingLineError: (lineNumbers: number[]) => void
};

export const Lines3D = ({
    lineNumbersToLoad,
    seismicMetadata,
    lineType,
    srid,
    scene,
    searchedLineNumber,
    setSelectedLineNumber,
    lineLoadingStep,
    setIsPriorityLineLoading,
    onLoadedLineNumbersChange,
    onTotalImageLoadedLinesChange,
    onLoadingLineError
} : Use3DLinesProps) => {
    const [ loadedLineNumbers, setLoadedLineNumbers ] = useState<number[]>([]);
    const [ totalImageLoadedLines, setTotalImageLoadedLines ] = useState<number>(0);

    const seismic3DDefaults = useSessionStore(state => state.tenantConfiguration?.viewer3DDefaults);

    const seismic3DInfo = seismicMetadata?.Metadata.Survey3DInfo;
    const seismicHeader = seismicMetadata?.Metadata.Header;

    const [ lineStart, lineEnd, lineIncrement ] = [
        lineType === LineType.Inline ? seismic3DInfo?.InlineStart ?? 0 : seismic3DInfo?.XlineStart ?? 0,
        lineType === LineType.Inline ? seismic3DInfo?.InlineEnd ?? 0 : seismic3DInfo?.XlineEnd ?? 0,
        lineType === LineType.Inline ? seismic3DInfo?.InlineIncrement ?? 0 : seismic3DInfo?.XlineIncrement ?? 0,
    ];

    const [ traceLineStart, traceLineEnd, tracesIncrement ] = [
        lineType === LineType.Xline ? seismic3DInfo?.InlineStart ?? 0 : seismic3DInfo?.XlineStart ?? 0,
        lineType === LineType.Xline ? seismic3DInfo?.InlineEnd ?? 0 : seismic3DInfo?.XlineEnd ?? 0,
        lineType === LineType.Xline ? seismic3DInfo?.InlineIncrement ?? 0 : seismic3DInfo?.XlineIncrement ?? 0,
    ];

    const [ samplesPerTrace, sampleInterval, byteSize ] = [
        seismicHeader?.SamplesPerTrace ?? 0,
        seismicHeader?.SampleInterval ?? 0,
        seismicHeader?.ByteSize ?? 0
    ];

    const [ seismicVolumeToken, seismicVolumePath ] = [ seismicMetadata?.Metadata.VolumeToken ?? '', seismicMetadata?.Metadata.VolumePath ?? '' ];
    const [ seismicMaxSampleValue, seismicMinSampleValue ] = [ seismicMetadata?.Metadata.MaxSampleValue ?? 0, seismicMetadata?.Metadata.MinSampleValue ?? 0 ];

    const [ currentLineLoadingImageIndexes, setCurrentLineLoadingImageIndexes ] = useState<number[]>([]);
    const [ linesToLoadImages, setLinesToLoadImages ] = useState<lineMetadata[]>([]);

    const [ totalLines, setTotalLines ] = useState<lineMetadata[]>([]);

    const [ tileRequests, setTileRequests ] = useState<TileRequest[]>([]);
    const fetchedTileResults = useGetTiles(tileRequests);

    const [ priorityTileRequests, setPriorityTileRequests ] = useState<TileRequest[]>([]);
    const priorityTileResults = useGetTiles(priorityTileRequests);

    const [ lineGeometryRequests, setLineGeometryRequests ] = useState<IGetLineGeometryRequest[]>([]);

    const [ priorityLineNumber, setPriorityLineNumber ] = useState<number>();

    const [ priorityLineGeometryRequest, setPriorityLineGeometryRequest ] = useState<IGetLineGeometryRequest | undefined>();

    const { current: errorLineNumbers } = useRef<number[]>([]);

    useEffect(() => {
        if (priorityLineNumber) {
            setIsPriorityLineLoading(true);
            setPriorityLineGeometryRequest(({
                lineNumber: priorityLineNumber,
                lineType: lineType,
                srid: srid,
                volumeToken: seismicVolumeToken,
                volumeType: VolumeType.Seismics3D,
                dimensionName: ''
            }));
            priorityLoadedLineNumbersRef.current.push(priorityLineNumber);
        }
    }, [priorityLineNumber]);

    const { data: priorityLineGeometry } = useGetLineGeometry(priorityLineGeometryRequest);

    const lineGeometryResults = useGetLineGeometries(lineGeometryRequests);

    const tileWidth = useMemo(() => seismic3DDefaults?.tile_width ? parseInt(seismic3DDefaults.tile_width) : GeopostThreeJsConstants.xDivisionFactor, [seismic3DDefaults]);

    const updatedLinesRef = useRef<number[]>([]);
    const totalOfLinesToLoadRef = useRef<number>(0);
    const totalOfTilesToLoadRef = useRef<number>(0);
    const [ tilesLoadedTotal, setTilesLoadedTotal ] = useState<number>(0);
    const tilesLoadedForCurrentLineRef = useRef<number[]>([]);
    const priorityLoadedLineNumbersRef = useRef<number[]>([]);
    const previousSelectedLineRelativeNumberRef = useRef<number>(searchedLineNumber ?? 0);

    const startLoadingLines = (lineNumbers : number[]) => {
        const newLineGeometryRequests : IGetLineGeometryRequest[] = lineNumbers.map(lineNumber => ({
            lineNumber: lineNumber,
            lineType: lineType,
            srid: srid,
            volumeToken: seismicVolumeToken,
            volumeType: VolumeType.Seismics3D,
            dimensionName: '',
        }));
        setLineGeometryRequests(newLineGeometryRequests);
        setLinesToLoadImages([]);
    };

    const addLineTiles = useCallback((line : lineMetadata) => {
        const [firstLineCoordinate, lastLineCoordinate] = getFirstAndLastCoordinatesFromGeomWkt(line.geometryMetadata.Line);
        const totalTiles = getTotalOfTilesFromLine(line.geometryMetadata.TotalTraces, tileWidth);
        scene.addLineTiles(firstLineCoordinate, lastLineCoordinate, totalTiles, line.lineNumber, line.lineType, getThreeJsYFactor(samplesPerTrace));
    }, [samplesPerTrace]);

    const getTileRequests = useCallback((line: lineMetadata) => {
        const tileHeight = seismic3DDefaults?.tile_height ? parseFloat(seismic3DDefaults?.tile_height) : undefined;

        const colorBar = seismic3DDefaults?.color_bar_name ?? 'binary';

        return getTileRequestsFromLine(SurveyType.Seismic3D, samplesPerTrace, sampleInterval, byteSize, tracesIncrement, traceLineStart,
            traceLineEnd, seismicVolumeToken, seismicVolumePath, seismicMinSampleValue, seismicMaxSampleValue,
            line.lineNumber, line.lineType, line.geometryMetadata,
            seismic3DInfo?.InlineStart ? seismic3DInfo?.InlineStart : 0,
            seismic3DInfo?.InlineEnd ? seismic3DInfo.InlineEnd : 0,
            seismic3DInfo?.XlineStart ? seismic3DInfo?.XlineStart : 0,
            seismic3DInfo?.XlineEnd ? seismic3DInfo?.XlineEnd : 0,
            seismic3DInfo?.InlineIncrement ? seismic3DInfo?.InlineIncrement : 0,
            seismic3DInfo?.XlineIncrement ? seismic3DInfo?.XlineIncrement : 0,
            colorBar, tileWidth, tileHeight);
    }, [seismic3DInfo]);

    useEffect(() => {
        setLineGeometryRequests([]);
        setLinesToLoadImages([]);
        setLoadedLineNumbers([]);
        setTotalImageLoadedLines(0);
        setTileRequests([]);
        setTilesLoadedTotal(0);
    }, [seismic3DInfo]);

    useEffect(() => {
        const newLineNumbersToLoad = lineNumbersToLoad.filter(lineNumber => !loadedLineNumbers.includes(lineNumber) && !priorityLoadedLineNumbersRef.current.includes(lineNumber));
        startLoadingLines(newLineNumbersToLoad);
    }, [lineNumbersToLoad]);

    useEffect(() => {
        let linesToAdd : lineMetadata[] = [];
        const newErrorLineNumbers: number[] = [];
        lineGeometryResults.forEach((lineGeometryResult, index) => {
            const relatedLineRequest = lineGeometryRequests[index];
            if (!lineGeometryResult) {
                return;
            }
            else if (!lineGeometryResult.Line || lineGeometryResult.Line === 'EMPTY') {
                if (!errorLineNumbers.includes(relatedLineRequest.lineNumber)) {
                    errorLineNumbers.push(relatedLineRequest.lineNumber);
                    newErrorLineNumbers.push(relatedLineRequest.lineNumber);
                }
                return;
            }
            if (linesToLoadImages.findIndex(line => line.lineNumber === relatedLineRequest.lineNumber) < 0) {
                linesToAdd.push({
                    lineNumber: relatedLineRequest.lineNumber,
                    lineType: lineType,
                    geometryMetadata: lineGeometryResult
                });
            }
        });
        if (linesToAdd.length > 0) {
            setLinesToLoadImages(current => {
                const newArray = [...current, ...linesToAdd];
                sortLinesFromTheMiddle(newArray);
                return newArray;
            });
        }
        if (newErrorLineNumbers.length > 0) {
            onLoadingLineError(newErrorLineNumbers);
        }
    }, [lineGeometryResults]);

    useEffect(() => {
        console.log('comparison');
        console.log('Line type ====> ' + lineType.toString());
        console.log([...linesToLoadImages]);
        console.log([...lineGeometryResults]);

        if (linesToLoadImages.length >= (lineGeometryResults.filter(x => x?.Line !== 'EMPTY').length) && linesToLoadImages.length > 0 && lineGeometryRequests.length > 0) {
            console.log('passed!!!');
            scene.addGridSection(linesToLoadImages, seismicVolumeToken);
            linesToLoadImages.forEach(line => {
                addLineTiles(line);
            });
            const firstIndexesToLoad = [];
            for (let i = 0; i < lineLoadingStep; i++) {
                firstIndexesToLoad.push(i);
            }
            setCurrentLineLoadingImageIndexes(firstIndexesToLoad);
            setLoadedLineNumbers(current => {
                const newArray = [...lineNumbersToLoad];
                priorityLoadedLineNumbersRef.current.forEach(priorityLineNumber => {
                    const priorityLineIndex = newArray.findIndex((loadedLineNumber, index) => {
                        return index === newArray.length - 1 || (loadedLineNumber < priorityLineNumber && priorityLineNumber < newArray[index + 1]) || loadedLineNumber === priorityLineNumber;
                    });
                    if (newArray[priorityLineIndex] === priorityLineNumber) {
                        newArray.splice(priorityLineIndex, 1, priorityLineNumber);
                    } else {
                        newArray.splice(priorityLineIndex + 1, 0, priorityLineNumber);
                    }

                });
                return newArray;
            });
        }
        console.log('-----------------------------------');
    }, [linesToLoadImages]);

    useEffect(() => {
        if (currentLineLoadingImageIndexes.length === 0) {
            return;
        }
        else {
            const newTileRequests : TileRequest[] = [];
            currentLineLoadingImageIndexes.forEach(currentLineLoadingImageIndex => {
                if (currentLineLoadingImageIndex >= linesToLoadImages.length) {
                    return;
                }
                const currentLineLoadingImage = linesToLoadImages[currentLineLoadingImageIndex];
                newTileRequests.push(...getTileRequests(currentLineLoadingImage));
                scene.markLineAsLoading(currentLineLoadingImage.lineNumber, currentLineLoadingImage.lineType);
            });
            setTileRequests([...newTileRequests]);
            totalOfTilesToLoadRef.current = newTileRequests.length;
            tilesLoadedForCurrentLineRef.current = [];
            setTilesLoadedTotal(0);
        }
    }, [currentLineLoadingImageIndexes]);

    useEffect(() => {
        let newFilledTiles = 0;
        fetchedTileResults.forEach((fetchedTileResult, index) => {
            if (!fetchedTileResult) {
                return;
            }

            if (!tilesLoadedForCurrentLineRef.current.includes(index)) {
                tilesLoadedForCurrentLineRef.current.push(index);
                newFilledTiles++;
            }
        });
        if (newFilledTiles > 0) {
            setTilesLoadedTotal(current => current + newFilledTiles);
        }
    }, [fetchedTileResults]);

    useEffect(() => {
        if (tilesLoadedTotal === totalOfTilesToLoadRef.current && tilesLoadedTotal > 0) {
            currentLineLoadingImageIndexes.forEach(index => {
                if (index >= linesToLoadImages.length) {
                    return;
                }
                const currentLineLoadingImage = linesToLoadImages[index];
                const tileResultsFromLine = fetchedTileResults.filter((fetchedTileResult, index) => !!tileRequests[index] && tileRequests[index].lineNumber === currentLineLoadingImage.lineNumber);
                const tileRequestsFromLine = tileRequests.filter((tileRequest) => tileRequest.lineNumber === currentLineLoadingImage.lineNumber);
                tileResultsFromLine.forEach((tileResult, index) => {
                    const associatedTileRequest = tileRequestsFromLine[index];
                    if (!!tileResult?.file && associatedTileRequest && !tileResult.error) {
                        scene.addTileTexture(index, associatedTileRequest.lineNumber, associatedTileRequest.lineType, tileResult.file);
                    }
                });
                scene.markLineAsLoaded(currentLineLoadingImage.lineNumber, currentLineLoadingImage.lineType);
            });
            setTotalImageLoadedLines(current => current + currentLineLoadingImageIndexes.length);
            const newIndexesToLoad = currentLineLoadingImageIndexes.map(index => index + lineLoadingStep).filter(index => index < linesToLoadImages.length);
            setCurrentLineLoadingImageIndexes(newIndexesToLoad);
        }
    }, [tilesLoadedTotal]);

    useEffect(() => {
        if (!searchedLineNumber) {
            return;
        }
        if (loadedLineNumbers.includes(searchedLineNumber)) {
            scene.showLineTiles(searchedLineNumber, lineType);
            setSelectedLineNumber(searchedLineNumber);
        } else {
            setPriorityLineNumber(searchedLineNumber);
        } if (previousSelectedLineRelativeNumberRef.current !== searchedLineNumber) {
            scene.hideLineTiles(previousSelectedLineRelativeNumberRef.current, lineType);
            previousSelectedLineRelativeNumberRef.current = searchedLineNumber;
        }
    }, [searchedLineNumber]);

    useEffect(() => {
        if (!priorityLineGeometry || !priorityLineGeometry.Line || priorityLineGeometry.Line === 'EMPTY' ) {
            return;
        }
        const lineNumber = priorityLineGeometryRequest!.lineNumber;
        const lineMetadata = {
            lineNumber: lineNumber,
            lineType: lineType,
            geometryMetadata: priorityLineGeometry
        };
        scene.addGridSection([lineMetadata], seismicVolumeToken);
        addLineTiles(lineMetadata);
        setPriorityTileRequests(getTileRequests(lineMetadata));

        const priorityLineIndex = loadedLineNumbers.findIndex((loadedLineNumber, index) => {
            return index === loadedLineNumbers.length - 1 || (loadedLineNumber < lineNumber && lineNumber < loadedLineNumbers[index + 1]);
        });

        setLoadedLineNumbers(current => {
            const newList = [...current];
            newList.splice(priorityLineIndex + 1, 0, lineNumber);
            return newList;
        });

        scene.markLineAsLoading(lineNumber, lineType);
        scene.showLineTiles(lineNumber, lineType);
        setSelectedLineNumber(lineNumber);
        //setPriorityLinesSearchedNumber(current => current + 1);

    }, [priorityLineGeometry]);

    const totalPriorityTileResults = useMemo(() => priorityTileResults.filter(tileResult => !!tileResult).length, [priorityTileResults]);

    useEffect(() => {
        if (totalPriorityTileResults > 0 && totalPriorityTileResults === priorityTileRequests.length) {
            priorityTileResults.forEach((tileResult, index) => {
                const associatedTileRequest = priorityTileRequests[index];
                if (!!tileResult?.file && !tileResult.error) {
                    scene.addTileTexture(index, associatedTileRequest.lineNumber, associatedTileRequest.lineType, tileResult.file);
                }
            });
            //setTotalImageLoadedLines(current => current + 1);
            setIsPriorityLineLoading(false);
            scene.markLineAsLoaded(priorityLineNumber!, lineType);
            setTotalImageLoadedLines(current => current + 1);
        }
    }, [totalPriorityTileResults]);

    useEffect(() => {
        onLoadedLineNumbersChange(loadedLineNumbers);
    }, [loadedLineNumbers]);

    useEffect(() => {
        onTotalImageLoadedLinesChange(totalImageLoadedLines);
    }, [totalImageLoadedLines]);

    return (<React.Fragment></React.Fragment>);
};