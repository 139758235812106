import { ReactNode } from 'react';
import { Alert, Box, Collapse, Slider, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import React from 'react';

import { useSessionStore } from 'session/useSessionStore';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { LineSlider } from './Lineslider';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { LineType } from 'features/seismic-3d/models/enums/LineType';

export const GridSlider = () => {
    const {
        totalInlineNumbers,
        totalXlineNumbers,
        selectedInlineNumber,
        selectedXlineNumber,
        selectedSeismic3DInfo,
        isInlineSearchLoading,
        isXlineSearchLoading,
        setSearchedInlineNumber,
        setSearchedXlineNumber
    } = use3DGridStore(state => ({
        totalInlineNumbers: state.totalInlineNumbers,
        totalXlineNumbers: state.totalXlineNumbers,
        selectedInlineNumber: state.selectedInlineNumber,
        selectedXlineNumber: state.selectedXlineNumber,
        setSelectedInlineNumber: state.setSelectedInlineNumber,
        setSelectedXlineNumber: state.setSelectedXlineNumber,
        selectedSeismic3DInfo: state.selectedSeismic3DInfo,
        isInlineSearchLoading: state.isInlineSearchLoading,
        isXlineSearchLoading: state.isXlineSearchLoading,
        setSearchedInlineNumber: state.setSearchedInlineNumber,
        setSearchedXlineNumber: state.setSearchedXlineNumber
    }), shallow);

    const volumeToken = use3DViewerStore(state => state.mainFeatureVolumeToken);

    const tenantToken = useSessionStore(state => state.tenantConfiguration?.tenantToken);

    const scene = use3DSceneStore(state => state.scene);

    const [ showErrorAlert, setShowErrorAlert ] = useState<boolean>(false);

    const [ gridOpacity, setGridOpacity ] = useState<number>(1);

    const lineDetailsRoute = useMemo(() => `/seismic?forceDisableCache=false&volumeToken=${volumeToken}&tenantToken=${tenantToken}`, [volumeToken, tenantToken]);

    const inlineDetailsRoute = useMemo(() => `${lineDetailsRoute}&lineNumber=${selectedInlineNumber}&lineType=${LineType.Inline}`, [lineDetailsRoute, selectedInlineNumber]);
    const xlineDetailsRoute = useMemo(() => `${lineDetailsRoute}&lineNumber=${selectedXlineNumber}&lineType=${LineType.Xline}`, [lineDetailsRoute, selectedXlineNumber]);

    const onInlineSliderChange = useCallback((event: Event, value: number | number[]) => {
        const selectedInline = totalInlineNumbers[value as number];
        setSearchedInlineNumber(selectedInline);
    }, [setSearchedInlineNumber, totalInlineNumbers]);

    const onXlineSliderChange = useCallback((event: Event, value: number | number[]) => {
        const selectedXline = totalXlineNumbers[value as number];
        setSearchedXlineNumber(selectedXline);
    }, [setSearchedXlineNumber, totalXlineNumbers]);

    const onInlineInputSubmit = (value: number) => {
        if (isNaN(value) || isInlineSearchLoading) {
            return;
        }
        if (value < totalInlineNumbers[0] || value > totalInlineNumbers[totalInlineNumbers.length - 1]) {
            setShowErrorAlert(true);
        } else {
            setSearchedInlineNumber(value);
        }
    };

    const onXlineInputSubmit = (value: number) => {
        if (isNaN(value) || isXlineSearchLoading) {
            return;
        }

        if (value < totalXlineNumbers[0] || value > totalXlineNumbers[totalXlineNumbers.length - 1]) {
            setShowErrorAlert(true);
        } else {
            setSearchedXlineNumber(value);
        }
    };

    useEffect(() => {
        scene.changeGridOpacity(gridOpacity);
    }, [gridOpacity]);

    useEffect(() => {
        if (showErrorAlert) {
            setTimeout(() => setShowErrorAlert(false), 3500);
        }
    }, [showErrorAlert]);

    return (
        <React.Fragment>
            <Collapse in={showErrorAlert}>
                <Alert severity='error' onClose={() => setShowErrorAlert(false)}>Line out of range! Please select a line between the seismic range.</Alert>
            </Collapse>
            <Box sx={{backgroundColor: 'white', padding: '10px 20px 10px 20px'}}>
                <Stack spacing={2}>
                    <Stack>
                        <Typography>Grid Opacity: {(gridOpacity * 100).toFixed() + '%'}</Typography>
                        <Slider
                            sx={{
                                width: '130px'
                            }}
                            id='grid-op-slider'
                            value={gridOpacity}
                            step={0.05}
                            min={0}
                            max={1}
                            onChange={(event, value) => setGridOpacity(value as number)}
                            valueLabelDisplay='off'
                        />
                    </Stack>
                    <Stack paddingRight={'10px'} spacing={2}>
                        <LineSlider
                            label='Selected Inline'
                            onLineInputSubmit={onInlineInputSubmit}
                            onSliderChange={onInlineSliderChange}
                            isSearchLoading={isInlineSearchLoading}
                            lineIncrement={selectedSeismic3DInfo?.InlineIncrement ?? 0}
                            selectedLineNumber={selectedInlineNumber}
                            totalLineNumbers={totalInlineNumbers}
                            detailsRoute={inlineDetailsRoute}
                        />
                        <LineSlider
                            label='Selected Xline'
                            onLineInputSubmit={onXlineInputSubmit}
                            onSliderChange={onXlineSliderChange}
                            isSearchLoading={isXlineSearchLoading}
                            lineIncrement={selectedSeismic3DInfo?.XlineIncrement ?? 0}
                            selectedLineNumber={selectedXlineNumber}
                            totalLineNumbers={totalXlineNumbers}
                            detailsRoute={xlineDetailsRoute}
                        />
                    </Stack>
                </Stack>
            </Box>
        </React.Fragment>
    );
};

type LineInfoTextProps = {
    children: ReactNode
};

const LineInfoText = ({ children } : LineInfoTextProps) => (<Typography variant='caption'  color='white'>{children}</Typography>);