import * as THREE from 'three';

import { interpolate } from 'features/seismic-3d/components/projections/featureGeomPolygon';
import { GeopostGeometry } from '../geometry/GeopostGeometry';
import { GeopostTileGeometry } from '../geometry/GeopostTileGeometry';

export class GeopostTileMesh extends THREE.Mesh {
    public constructor (firstLineCoordinate: number[], lastLineCoordinate: number[], tilesTotal: number, tileIndex: number, imageBottomY : number, imageTopY: number) {
        const firstCoordinate = interpolate(firstLineCoordinate, lastLineCoordinate, tileIndex / tilesTotal);
        const nextTileFirstCoordinate = interpolate(firstLineCoordinate, lastLineCoordinate, (tileIndex + 1) / tilesTotal);
        const tileGeometry = new GeopostTileGeometry(firstCoordinate, nextTileFirstCoordinate, imageTopY, imageBottomY);
        const  material = new THREE.MeshPhongMaterial({color: '#cccccc', side: THREE.DoubleSide, transparent: true, opacity: 0.4});
        super(tileGeometry, material);
    };
}