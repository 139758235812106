import { useCallback, useEffect, useRef, useState } from 'react';

import { LineType } from 'features/seismic-3d/models/enums/LineType';
import { PrepareTilesRenderRequest, SamplesReference, usePrepareTilesRender } from '../components/attributes/api/useSeismicController';
import { GeopostSeismicLayer } from '../models/classes/GeopostSeismicLayer';
import { GeopostTileLoadingLayer } from '../models/classes/layers/GeopostTileLoadingLayer';
import { IGeopostSeismicLayerParams } from '../models/interfaces/IGeopostSeismicLayerParams';
import { useSeismicStore } from '../stores/useSeismicStore';
import { getParamsFromUrl } from '../utils/seismicUrlUtils';

type prepareSuccessHandler = (minSampleValue: number, maxSampleValue: number, limits: [number, number][], stepIndex: number ) => void;

export const usePrepareRender = () => {

    const { current: chunksQueue } = useRef<PrepareTilesRenderRequest[]>([]);
    const [ doneChunksQueue, setDoneChunksQueue ] = useState<PrepareTilesRenderRequest[]>([]);

    const setAbsoluteGain = useSeismicStore(state => state.setAbsoluteGain);

    const { mutateAsync: fetchPrepareRender } = usePrepareTilesRender();

    const unqueueChunk = useCallback(() => {
        if (chunksQueue.length === 0) {
            return null;
        }
        const chunkToUnqueue = chunksQueue.shift();
        return chunkToUnqueue;
    }, [chunksQueue]);

    const gainUpdatedRef = useRef<boolean>(true);

    const prepareChunk = useCallback(async (layer: GeopostSeismicLayer, loadingLayer: GeopostTileLoadingLayer, updateRenderLimits: boolean) => {
        const chunk = unqueueChunk();

        if (!chunk) {
            return;
        }

        const gainReferenceValues = await fetchPrepareRender(chunk);

        const newLayerParams : IGeopostSeismicLayerParams = {};

        if (!gainUpdatedRef.current) {
            if (gainReferenceValues.MaxSampleValue !== 0 && gainReferenceValues.MinSampleValue !== 0) {
                setAbsoluteGain({
                    max: gainReferenceValues.MaxSampleValue,
                    min: gainReferenceValues.MinSampleValue
                });
                newLayerParams.absoluteGain = {
                    max: gainReferenceValues.MaxSampleValue,
                    min: gainReferenceValues.MinSampleValue
                };
                gainUpdatedRef.current = true;
            }
        }

        const chunkLoadingKey = `${chunk.prepareLineStart}-${chunk.prepareLineEnd}-prepare`;
        loadingLayer.hideLoading(chunkLoadingKey);
        //layer.getParams().increaseLoaded!(chunkLoadingKey);

        setDoneChunksQueue(current => [...current, chunk]);

        if (updateRenderLimits) {
            newLayerParams.renderLimits = [...layer.getParams().renderLimits!, [chunk.prepareLineStart, chunk.prepareLineEnd]];

            layer.updateParams(newLayerParams, false).reload();
        }

        prepareChunk(layer, loadingLayer, updateRenderLimits);
    }, [fetchPrepareRender, unqueueChunk]);

    const prepareRenderStep = getParamsFromUrl().prepareRenderStep;
    const cacheSystem = getParamsFromUrl().samplesCacheSystem;
    const prepareIterationChunks = getParamsFromUrl().prepareIterationChunks;

    useEffect(() => {
    }, [doneChunksQueue]);

    const prepareRender = useCallback(async (layer: GeopostSeismicLayer, layerParams: IGeopostSeismicLayerParams, loadingLayer: GeopostTileLoadingLayer, updateGain: boolean = true, lineType: LineType, updateRenderLimits: boolean = true) => {
        setDoneChunksQueue([]);

        const [ surveyLineStart, surveyLineEnd, surveyLineIncrement ] = layer.tileSourceStrategy.getSurveyLineDimensions(layer.getParams());

        const prepareRenderParams = {
            volumeToken: layerParams.volumeToken!,
            filePath: layerParams.filePath!,
            byteSize: layerParams.byteSize!,
            volumeSamplesPerTrace: layerParams.volumeSamplesPerTrace!,
            lineIncrement: surveyLineIncrement!,
            traceRangeStart: layerParams.traceRangeStart ?? 0,
            traceRangeEnd: layerParams.traceRangeEnd ?? 0,
            parallelProcesses: layerParams.parallelProcesses!,
            surveyInlineIncrement: layerParams.surveyInlineIncrement!,
            surveyXlineIncrement: layerParams.surveyXlineIncrement!,
            isIndexedVolume: layerParams.isIndexedVolume!,
            surveyInlineEnd: layerParams.surveyInlineEnd!,
            surveyInlineStart: layerParams.surveyInlineStart!,
            surveyXlineStart: layerParams.surveyXlineStart!,
            surveyXlineEnd: layerParams.surveyXlineEnd!,
            lineNumber: layerParams.lineNumber!,
            lineType: lineType!,
            lineStartSample: 0,
            lineEndSample: Math.round(layerParams.tileHeight! * layerParams.resolutions![0] / layerParams.scaleY!),
            sampleInterval: layerParams.sampleInterval!,
            skipTraceCount: layerParams.skipTraceCount!,
            surveyLineEnd: surveyLineEnd!,
            surveyLineStart: surveyLineStart!,
            inlineDimensionName: layerParams.inlineDimensionName!,
            sampleDimensionName: layerParams.sampleDimensionName!,
            xlineDimensionName: layerParams.xlineDimensionName!,
            cdpDimensionName: layerParams.cdpDimensionName!,
            cacheSystem,
            storeGainCache: false
        };

        const prepareRenderChunks : PrepareTilesRenderRequest[] = [];

        const prepareRange = prepareRenderStep * 128;

        const step = prepareIterationChunks;

        for (let i = surveyLineStart; i <= surveyLineEnd; i += prepareRange * surveyLineIncrement) {
            const chunkStart = i;
            let chunkEnd = i + (prepareRange * surveyLineIncrement);
            chunkEnd =  chunkEnd > surveyLineEnd ? surveyLineEnd : chunkEnd;
            prepareRenderChunks.push({
                ...prepareRenderParams,
                prepareLineStart: chunkStart,
                prepareLineEnd: chunkEnd
            });
        }

        const middleIndex = Math.ceil(prepareRenderChunks.length / 2);
        const pivot = prepareRenderChunks[middleIndex - 1];

        prepareRenderChunks.sort((a, b) => Math.abs(a.prepareLineStart - pivot.prepareLineStart) - Math.abs(b.prepareLineStart - pivot.prepareLineStart) || b.prepareLineStart - a.prepareLineStart);

        prepareRenderChunks.forEach(chunk => {
            const key = `${chunk.prepareLineStart}-${chunk.prepareLineEnd}-prepare`;
            loadingLayer.showLoading(key, chunk.prepareLineStart, chunk.prepareLineEnd, surveyLineIncrement, surveyLineStart, layerParams.scaleX!, chunk.lineStartSample * -1, chunk.lineEndSample * -1,  0, layer.map!.getView().calculateExtent(), '#333333');
            //layerParams.increaseLoading!(key);
        });
        if (updateGain) {
            gainUpdatedRef.current = false;
        }

        chunksQueue.push(...prepareRenderChunks);
        for (let i = 0; i < step; i++) {
            prepareChunk(layer, loadingLayer, updateRenderLimits);
        }

    }, [cacheSystem, chunksQueue, prepareChunk, prepareIterationChunks, prepareRenderStep]);

    return prepareRender;
};