import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { GeopostWellGroup } from 'features/seismic-3d/threejs/group/GeopostWellGroup';
import { GeopostThreeJsConstants } from 'features/seismic-3d/threejs/utils/GeopostThreeJsConstants';
import { getScaled2DCoordinate } from 'features/seismic-3d/threejs/utils/ScaleUtils';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { IWellDirectionalData } from 'features/seismic/models/interfaces/IWellDirectionalData';
import { useGetMultipleDirectionalDataByWell } from '../api/useWellDirectionalDataController';
import { GeopostWellCrossingPointMesh } from 'features/seismic-3d/threejs/group/GeopostWellCrossingPointMesh';
import { use3DGridStore } from 'features/seismic-3d/stores/use3DGridStore';
import { RaycasterEventFactory } from 'features/seismic-3d/threejs/utils/RaycasterEventFactory';

export const use3DDirectionalPaths = (
    wellsWithVisibleDirectional: IWell[],
    onAddWellGroup: (well: IWell) => void,
    color: string
) => {
    const mainFeatureCentroidX = use3DViewerStore(state => state.featureCentroidX);
    const mainFeatureCentroidY = use3DViewerStore(state => state.featureCentroidY);

    const surveyHeightInPixels = use3DViewerStore(state => state.heightPixelFactor);

    const { current: lastWellsWithVisibleDirectional } = useRef<IWell[]>([]);

    const wellsToAddDirectional = useMemo(() => wellsWithVisibleDirectional.filter(well => !lastWellsWithVisibleDirectional.includes(well)), [wellsWithVisibleDirectional]);

    const wellsToRemoveDirectional = useMemo(() => lastWellsWithVisibleDirectional.filter(well => !wellsWithVisibleDirectional.includes(well)), [wellsWithVisibleDirectional]);

    const {  current: fetchedDirectionalData } = useRef<{ wellId: number, directional: IWellDirectionalData }[]>([]);

    const { mutateAsync: getDirectionalDataByWell} = useGetMultipleDirectionalDataByWell();

    const [ directionalWellsLoading, setDirectionalWellsLoading ] = useState<IWell[]>([]);

    const scene = use3DSceneStore(state => state.scene);

    const addCrossingPoint = useCallback((well: IWell, platFormPosition: number[], points: number[][]) => {
        let wellGroup : GeopostWellGroup;
        do {
            wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
            if (!wellGroup) {
                onAddWellGroup(well);
            }
        } while (!wellGroup);

        const vectorPoints: THREE.Vector3[] = [];

        points.forEach(wellPoint => {
            const pixelsY = Math.abs(wellPoint[1]) / wellGroup.heightFactor;
            const scaledY = - (pixelsY / GeopostThreeJsConstants.yDivisionFactor);
            const [scaledX, scaledZ] = getScaled2DCoordinate([wellPoint[0], wellPoint[2]], mainFeatureCentroidX, mainFeatureCentroidY);
            const scaledPoint = new THREE.Vector3(scaledX, scaledY, scaledZ);
            vectorPoints.push(scaledPoint);
        });
        wellGroup.wellPoints = vectorPoints;
        const crossingPointMesh = new GeopostWellCrossingPointMesh(well.Name, wellGroup.heightFactor, vectorPoints, platFormPosition, color, mainFeatureCentroidX, mainFeatureCentroidY);
        crossingPointMesh.name = 'crossing-point';
        wellGroup.add(crossingPointMesh);

        const raycasterEventFactory = new RaycasterEventFactory<GeopostWellCrossingPointMesh>();
        const originalLabelPosition = new THREE.Vector3(crossingPointMesh.label.position.x, crossingPointMesh.label.position.y, crossingPointMesh.label.position.z);
        const intersectionEvent = raycasterEventFactory.addIntersectionEventOnGroup(
            crossingPointMesh,
            (mesh) => {
                if (!mesh.visible) {
                    return;
                }
                const camera = scene.camera!;
                camera.updateMatrix();
                camera.updateMatrixWorld();
                const frustum = new THREE.Frustum();
                frustum.setFromProjectionMatrix(new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));
                mesh.label.position.set(originalLabelPosition.x, originalLabelPosition.y, originalLabelPosition.z);
                if (!frustum.containsPoint(mesh.label.position)) {
                    for (let i = 0; i < mesh.points.length; i++) {
                        const point = mesh.points[i];
                        if (frustum.containsPoint(point)) {
                            mesh.label.position.set(point.x, point.y, point.z);
                        }
                    }
                };
                mesh.label.visible = true;
            },
            (mesh) => {
                if (!mesh.visible) {
                    return;
                }
                mesh.label.visible = false;
            }
        );
        scene.intersectionEvents.push(intersectionEvent);

    }, [color, mainFeatureCentroidX, mainFeatureCentroidY, scene, onAddWellGroup]);

    const addDirectionalPath = useCallback((directionalData: IWellDirectionalData, well: IWell) => {
        const points = directionalData.DirectionalPoints.map(directionalPoint => [directionalPoint.MetersX, directionalPoint.MeasureDepth, directionalPoint.MetersY]);
        const platformPoint = [well.PlatformXPosition, 0, well.PlatformYPosition];
        addCrossingPoint(well, platformPoint, points);
    }, [addCrossingPoint]);

    useEffect(() => {
        wellsToAddDirectional.forEach(well => {
            setDirectionalWellsLoading(current => [...current, well]);
            const fetchedDirectional = fetchedDirectionalData.find(directionalData => directionalData.wellId === well.Id)?.directional;
            lastWellsWithVisibleDirectional.push(well);
            if (!!fetchedDirectional) {
                addDirectionalPath(fetchedDirectional, well);
                setDirectionalWellsLoading(current => {
                    const newArray = [...current];
                    newArray.splice(newArray.indexOf(well), 1);
                    return newArray;
                });
            } else {
                getDirectionalDataByWell(well.Id)
                    .then(directional => {
                        addDirectionalPath(directional, well);
                        fetchedDirectionalData.push({
                            wellId: well.Id,
                            directional: directional
                        });
                    })
                    .finally(() => setDirectionalWellsLoading(current => {
                        const newArray = [...current];
                        newArray.splice(newArray.indexOf(well), 1);
                        return newArray;
                    }));
            }
        });
    }, [wellsToAddDirectional]);

    useEffect(() => {
        wellsToRemoveDirectional.forEach(well => {
            removeCrossingPoint(well.Id);
            if (well.HasDirectionalData) {
                const index = lastWellsWithVisibleDirectional.indexOf(well);
                if (index >= 0) {
                    lastWellsWithVisibleDirectional.splice(index, 1);
                }
            }
        });
    }, [wellsToRemoveDirectional]);

    const removeCrossingPoint = useCallback((wellId: number) => {
        const wellGroup = (scene.getObjectByName(GeopostWellGroup.getWellGroupName(wellId)) as GeopostWellGroup | undefined);
        const crossingPointMesh = wellGroup?.getObjectByName('crossing-point');
        if (crossingPointMesh) {
            wellGroup!.remove(crossingPointMesh);
        }
    }, [scene]);

    useEffect(() => {
        if (!surveyHeightInPixels) {
            return;
        }
        lastWellsWithVisibleDirectional.forEach(well => {
            removeCrossingPoint(well.Id);
            const fetchedDirectional = fetchedDirectionalData.find(directionalData => directionalData.wellId === well.Id)?.directional;
            if (fetchedDirectional) {
                addDirectionalPath(fetchedDirectional, well);
            }
        });
    }, [surveyHeightInPixels]);

    return { directionalWellsLoading };
};
