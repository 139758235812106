import { LineType } from '../models/enums/LineType';
import { SampleIntervalUnity } from '../models/enums/SampleIntervalUnity';
import { ISurveyMetadata } from '../models/interfaces/ISurveyMetadata';
import { getParamsFromUrl } from './seismicUrlUtils';

export function changeLineNumber(surveyMetadata: ISurveyMetadata | null, lineNumber: number, lineType: LineType, value: number, skip: number) {
    const urlParams = getParamsFromUrl();

    if (!surveyMetadata || !surveyMetadata.Survey3DInfo) {
        return 0;
    }

    if (skip === 0){
        skip = 1;
    }

    if (lineType === LineType.Inline) {
        if (surveyMetadata?.Survey3DInfo.EntitledInlines && surveyMetadata?.Survey3DInfo.EntitledInlines.length > 0) {
            let idxLineNumber = surveyMetadata?.Survey3DInfo.EntitledInlines.indexOf(lineNumber);
            idxLineNumber = idxLineNumber + value;
            if (idxLineNumber < surveyMetadata.Survey3DInfo.EntitledInlines.length && idxLineNumber >= 0) {
                lineNumber = surveyMetadata.Survey3DInfo.EntitledInlines[idxLineNumber];
            } else {
                if (value > 0) {
                    lineNumber = surveyMetadata.Survey3DInfo.EntitledInlines[surveyMetadata.Survey3DInfo.EntitledInlines.length - 1];
                } else {
                    lineNumber = surveyMetadata.Survey3DInfo.EntitledInlines[0];
                }
            }
        } else {
            lineNumber += (value * surveyMetadata.Survey3DInfo.InlineIncrement * urlParams.forceLineSkip * skip);

            if (lineNumber < surveyMetadata.Survey3DInfo.InlineStart) {
                lineNumber = surveyMetadata.Survey3DInfo.InlineStart;
            }
            else if (lineNumber > surveyMetadata.Survey3DInfo.InlineEnd) {
                lineNumber = surveyMetadata.Survey3DInfo.InlineEnd;
            }
        }
    }
    else if (lineType === LineType.Xline) {
        if (surveyMetadata?.Survey3DInfo.EntitledXlines && surveyMetadata?.Survey3DInfo.EntitledXlines.length > 0) {
            let idxLineNumber = surveyMetadata?.Survey3DInfo.EntitledXlines.indexOf(lineNumber);
            idxLineNumber = idxLineNumber + value;
            if (idxLineNumber < surveyMetadata.Survey3DInfo.EntitledXlines.length && idxLineNumber >= 0) {
                lineNumber = surveyMetadata.Survey3DInfo.EntitledXlines[idxLineNumber];
            } else {
                if (value > 0) {
                    lineNumber = surveyMetadata.Survey3DInfo.EntitledXlines[surveyMetadata.Survey3DInfo.EntitledXlines.length - 1];
                } else {
                    lineNumber = surveyMetadata.Survey3DInfo.EntitledXlines[0];
                }
            }
        } else {
            lineNumber += (value * surveyMetadata.Survey3DInfo.XlineIncrement * urlParams.forceLineSkip * skip);

            if (lineNumber < surveyMetadata.Survey3DInfo.XlineStart) {
                lineNumber = surveyMetadata.Survey3DInfo.XlineStart;
            }
            else if (lineNumber > surveyMetadata.Survey3DInfo.XlineEnd) {
                lineNumber = surveyMetadata.Survey3DInfo.XlineEnd;
            }
        }
    }
    else if (lineType === LineType.ZSlice) {
        const sampleInterval = surveyMetadata.Header.SampleIntervalUnit === SampleIntervalUnity.Microsecond ? surveyMetadata.Header.SampleInterval / 1000 : surveyMetadata.Header.SampleInterval;
        lineNumber += (value * sampleInterval * urlParams.forceLineSkip * skip);
        const maxDepth = (surveyMetadata.Header.SamplesPerTrace - 1) * sampleInterval;
        if (lineNumber < 0) {
            lineNumber = 0;
        } else if (lineNumber > maxDepth) {
            lineNumber = maxDepth;
        }
    }

    return lineNumber;
}

export function calculateDisable(surveyMetadata: ISurveyMetadata | null, lineNumber: number, lineType: LineType){
    let disableIncrease = false;
    let disableDecrease = false;

    if (surveyMetadata && surveyMetadata.Survey3DInfo) {
        if (lineType === LineType.Inline) {
            if (lineNumber === surveyMetadata.Survey3DInfo.InlineStart) {
                disableDecrease = true;
            }
            else if (lineNumber === surveyMetadata.Survey3DInfo.InlineEnd) {
                disableIncrease = true;
            }
        }
        else if (lineType === LineType.Xline) {
            if (lineNumber === surveyMetadata.Survey3DInfo.XlineStart) {
                disableDecrease = true;
            }
            else if (lineNumber >= surveyMetadata.Survey3DInfo.XlineEnd) {
                disableIncrease = true;
            }
        }
    }

    return {
        disableIncrease: disableIncrease,
        disableDecrease: disableDecrease
    };
}

export const getLineIdentifier = (lineType: LineType, lineNumber: number) => `${lineType}_${lineNumber}`;