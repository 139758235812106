import { Box, Checkbox, CircularProgress, Stack } from '@mui/material';
import { Fragment, useState } from 'react';

import { IGeopostCheckboxProps } from './interfaces/IGeopostCheckboxProps';

export const GeopostCheckbox = ({id, className, onSelect = () => {}, onUnselect =  () => {}, onChange, checked, loading, disabled = false} : IGeopostCheckboxProps) => {
    //const [selected, setSelected] = useState<boolean>(false);
    return (
        <Fragment>
            <Stack direction='row' alignItems='center'>
                <Checkbox
                    id={id}
                    className={className}
                    color="primary"
                    style={{ marginLeft: 17 }}
                    checked={checked}
                    disabled={loading || disabled}
                    onChange={(event, checked) => {
                        if (!!onChange) {
                            onChange(checked);
                        } else {
                            if (checked) {
                                onSelect();
                            }
                            else {
                                onUnselect();
                            }
                        }
                    }
                    }
                />
                {
                    loading
                    &&
                    <Box  sx={{marginRight: '5px'}}>
                        <CircularProgress size={15}/>
                    </Box>
                }
            </Stack>
        </Fragment>
    );
};