import { useEffect, useRef, useState } from 'react';

import { useCheckForSeismic3D } from '../api/useFeatureFlagController';
import { ISeismic3DFlags } from '../models/interfaces/ISeismic3DFlags';
import { getUrlParams } from '../utils/Seismic3DUrlUtils';

export const useSeismic3DFeatureFlags = () => {
    const tenantToken = getUrlParams().tenantToken;
    const { data: flagNames } = useCheckForSeismic3D(tenantToken);
    const [seismic3DFeatureFlags, setSeismic3DFeatureFlags] = useState<ISeismic3DFlags>({
        enableProjections: true,
        enableSurveys: false,
        enableGrids: false,
        enableWells: false,
        enableParentAppLink: false,
        enablePerformanceMonitoring: false,
    });

    useEffect(() => {
        if (flagNames) {
            const seismic3DFeatureFlagsCopy = { ...seismic3DFeatureFlags };
            flagNames.forEach(flagName => enableFlag(flagName, seismic3DFeatureFlagsCopy));
            setSeismic3DFeatureFlags(seismic3DFeatureFlagsCopy);
        }
    }, [flagNames]);

    const enableFlag = (flagName: string, seismic3DFeatureFlags : ISeismic3DFlags) => {
        switch (flagName) {
        case 'SEISMIC_3D_ENABLE_PROJECTIONS':
            seismic3DFeatureFlags.enableProjections = true;
            break;
        case 'SEISMIC_3D_ENABLE_SURVEYS':
            seismic3DFeatureFlags.enableSurveys = true;
            break;
        case 'SEISMIC_3D_ENABLE_GRIDS':
            seismic3DFeatureFlags.enableGrids = true;
            break;
        case 'SEISMIC_3D_ENABLE_WELLS':
            seismic3DFeatureFlags.enableWells = true;
            break;
        case 'SEISMIC_3D_ENABLE_PARENT_APP_LINK':
            seismic3DFeatureFlags.enableParentAppLink = true;
            break;
        case 'SEISMIC_3D_ENABLE_PERFORMANCE_MONITORING':
            seismic3DFeatureFlags.enablePerformanceMonitoring = true;
            break;
        };
    };

    return seismic3DFeatureFlags;
};

