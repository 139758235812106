import { RefObject, useEffect, useState } from 'react';
import Map from 'ol/Map';
import { Alert, Box, CircularProgress, Collapse, css, IconButton, Snackbar } from '@mui/material';
import ImageLayer from 'ol/layer/Image';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import { useCreateMiniMap } from './hooks/useCreateMiniMap';
import { ILineString } from 'features/seismic/models/interfaces/ILineString';
import { GeopostWMSSource } from 'features/seismic/models/classes/layers/GeopostWMSSource';
import { useSeismicStore } from 'features/seismic/stores/useSeismicStore';

const containerStyle = css({
    position: 'relative',
    width: '100%',
    height: '100%',
    '& .loading': {
        display: 'flex',
        position: 'fixed',
        zIndex: '2',
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 38px)',
        backgroundColor: 'rgb(255, 255, 255)',
        opacity: 0.8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    '& .MuiButtonBase': {
        display: 'flex',
        alignItems: 'center',
    },
    '& .miniMap': {
        backgroundColor: 'rgb(4, 31, 60)',
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        border: '1px solid #ffffff',
    }
});

export function SeismicMiniMapContent({ opened, dataGeometryLine, allLayers }: Props) {
    const { mapRef, isLoading, errorMessage } = useCreateMiniMap(opened, dataGeometryLine, allLayers);

    const [ showLineError, setShowLineError ] = useState(false);

    useEffect(() => {
        setShowLineError(!!errorMessage);
    }, [errorMessage]);

    return (
        <React.Fragment>
            <Box position='absolute'>
                <Snackbar
                    open={showLineError}
                    onClose={() => setShowLineError(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        severity='error'
                        action={
                            <IconButton
                                aria-label='close'
                                color='inherit'
                                size='small'
                                onClick={
                                    () => setShowLineError(false)
                                }
                            >
                                <CloseIcon fontSize='inherit'/>
                            </IconButton>
                        }
                    >
                        <strong> {errorMessage} </strong>
                    </Alert>
                </Snackbar>
            </Box>

            <Box css={containerStyle}>
                {isLoading &&
                    <Box className='loading'>
                        <CircularProgress />
                    </Box>
                }

                <div ref={mapRef} style={{ backgroundColor: 'white' }} className='miniMap'></div>
            </Box>
        </React.Fragment>
    );
}

interface Props {
    opened: boolean;
    dataGeometryLine: ILineString | null;
    allLayers: ImageLayer<GeopostWMSSource>[];
}