import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { shallow } from 'zustand/shallow';

import { useSessionStore } from 'session/useSessionStore';
import { useSaveSLD } from '../api/useSLDController';
import { ISeismicViewer3DMap } from '../models/interfaces/ISeismicViewer3DMap';
import { use3DGridStore } from '../stores/use3DGridStore';
import { use3DViewerStore } from '../stores/use3DViewerStore';
import * as ThreeJsGridUtils from '../threejs/utils/GeopostGridUtils';
import { getSLDAlpha, getSLDMainGrayScale } from '../threejs/utils/GeopostGridUtils';
import { useWMS } from '../api/useMapProxy';
import { WmsRequest } from '../models/types/WmsRequest';
import { GeopostThreeJsConstants } from '../threejs/utils/GeopostThreeJsConstants';
import { use3DSceneStore } from '../stores/use3DSceneStore';
import { GeopostGridGroup } from '../threejs/group/GeopostGridGroup';

export const GridXyz3D = ({grid, mainFeatureSrid, opacity, visible, onLoadEnd, onLoadStart} : GridXyz3DProps) => {

    const gridSelectedSeismic = use3DGridStore(state => state.gridSelectedSeismic);

    const groupRef = useRef<GeopostGridGroup>();

    const surveyHeightPixel = gridSelectedSeismic?.samplesPerTrace ?? 0;

    const [ mainGrayScaleSLD, setMainGrayScaleSLD ] = useState<string>();
    const [ alphaSld, setAlphaSld ] = useState<string>();

    const [ isGridCreationLoading, setIsGridCreationLoading ] = useState<boolean>(false);

    const texturePictureWidth = 1920;
    const texturePictureHeight = 968;

    const { mutate: saveSLD } = useSaveSLD();

    const sldHost = useSessionStore(state => state.tenantConfiguration?.endpoints.sld) ?? '';
    const geoServerProxyHost = useSessionStore(state => state.tenantConfiguration?.endpoints.geoServerProxy) ?? '';
    const geopostScene = use3DSceneStore(state => state.scene);;

    (ThreeJsGridUtils.checkGridVisibility(grid.Token, geopostScene));

    const {
        featureCentroidX,
        featureCentroidY,
        countTimeDepth,
        heightPixelFactor
    } = use3DViewerStore(state => ({
        featureCentroidX: state.featureCentroidX,
        featureCentroidY: state.featureCentroidY,
        countTimeDepth: state.countTimeDepth,
        heightPixelFactor: state.heightPixelFactor
    }), shallow);

    useEffect(() => {
        if (ThreeJsGridUtils.checkIfGridHasMesh(grid.Token, geopostScene)) {
            ThreeJsGridUtils.changeGridMeshVisbility(grid.Token, geopostScene, visible);
        }
        else if (visible) {
            buildGridOnScene(grid);
        }
    }, [visible]);

    const [ wmsColorRequest, setWmsColorRequest ] = useState<WmsRequest>();
    const [ wmsDisplacementRequest, setWmsDisplacementRequest ] = useState<WmsRequest>();

    const { data: wmsColor } = useWMS(wmsColorRequest);
    const { data: wmsDisplacement } = useWMS(wmsDisplacementRequest);

    const addGrid = async () => {
        if (!!wmsColor && !!wmsDisplacement) {
            const group = await ThreeJsGridUtils.getGridGroup(grid, wmsDisplacement, wmsColor, countTimeDepth, heightPixelFactor, featureCentroidX, featureCentroidY);
            geopostScene.add(group);
            onLoadEnd();
        }
    };

    useEffect(() => {
        addGrid();
    }, [wmsColor, wmsDisplacement]);

    useEffect(() => {
        if (heightPixelFactor !== 0) {
            if (visible) {
                ThreeJsGridUtils.removeGrid(grid.Token, geopostScene);
                onLoadStart();
                addGrid();
            }
        }
    }, [heightPixelFactor]);

    useEffect(() => {
        ThreeJsGridUtils.changeGridOpacity(grid.Token, geopostScene, opacity);
    }, [opacity]);

    const buildGridOnScene = (grid: ISeismicViewer3DMap) => {
        onLoadStart();
        const mainGrayScaleSLD = getSLDMainGrayScale(grid.Min, grid.Max, grid.Workspace, grid.LayerName);
        const alphaSLD = getSLDAlpha(grid.Min, grid.Max, grid.Workspace, grid.LayerName, grid.SimplifiedGeomWkt);
        saveSLD(mainGrayScaleSLD, {
            onSuccess: (mainGrayScaleSLDToken) => {
                saveSLD(alphaSLD, {
                    onSuccess: (alphaSLDToken) => {
                        const extent = [grid.MinX, grid.MinY, grid.MaxX, grid.MaxY];
                        setWmsColorRequest({
                            workspace: grid.Workspace,
                            layerName: grid.LayerName,
                            hostServer: grid.HostServer,
                            sldHost: sldHost,
                            extent: extent,
                            backgroundColor: '000000',
                            imageFormat: 'image/jpeg',
                            width: GeopostThreeJsConstants.pictureTextureWidth,
                            height: GeopostThreeJsConstants.pictureTextureHeight,
                            sldToken: alphaSLDToken,
                            srid: mainFeatureSrid
                        });
                        setWmsDisplacementRequest({
                            workspace: grid.Workspace,
                            layerName: grid.LayerName,
                            hostServer: grid.HostServer,
                            sldHost: sldHost,
                            extent: extent,
                            width: GeopostThreeJsConstants.pictureTextureWidth,
                            height: GeopostThreeJsConstants.pictureTextureHeight,
                            sldToken: mainGrayScaleSLDToken,
                            srid: mainFeatureSrid,
                            backgroundColor: undefined,
                            imageFormat: 'image/png'
                        });
                    }
                });
            }
        });
    };

    return (
        <React.Fragment></React.Fragment>
    );
};

export type GridXyz3DProps = {
    grid: ISeismicViewer3DMap,
    mainFeatureSrid: number,
    opacity: number,
    visible: boolean,
    onLoadStart: () => void,
    onLoadEnd: () => void
};