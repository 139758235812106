import { LocalizationMap, ProgressBar, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useEffect, useState } from 'react';
import 'pdfjs-dist/build/pdf.worker.entry';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import pt_PT from '@react-pdf-viewer/locales/lib/pt_PT.json';
import english from '@react-pdf-viewer/locales/lib/en_US.json';
import queryString from 'query-string';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { CircularProgress, css } from '@mui/material';

import { RenderError } from 'features/pdf/components/RenderError';
import { PDFToolbar } from './PDFToolbar';
import { useSessionStore } from 'session/useSessionStore';

const style = css({
    height: '100vh'
});

export function PDFViewer() {
    const [url, setUrl] = useState<string>('');

    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search);

        if (queryParams.source === 'file-manager') {
            if (queryParams.fileToken && typeof queryParams.fileToken === 'string') {
                const buildUrl = `${tenantConfig?.endpoints.fileManager}/filemanager/filemanager/downloadFile?&fileToken=${queryParams.fileToken}`;
                setUrl(buildUrl);
            }
            else {
                console.error('params invalido');
            }
        }
        else if (queryParams.source === 'pgs-cognite') {
            if (queryParams.fileToken && typeof queryParams.fileToken === 'string') {
                const buildUrl = `${tenantConfig?.endpoints.render}/seismic/product/downloadFile?fileId=${queryParams.fileToken}`;
                setUrl(buildUrl);
            }
            else {
                console.error('params invalido');
            }
        }
        else {
            console.error('source inválido');
        }
    }, []);

    const renderToolbar = PDFToolbar;

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) => [
            defaultTabs[0],
        ],
    });

    return (
        <div css={style}>
            {(url !== '' && jwtToken !== '') ?
                <Viewer
                    renderLoader={(percentages: number) => (
                        // You can use your own progress bar component
                        <div style={{ width: '240px' }}>
                            <ProgressBar progress={Math.round(percentages)} />
                        </div>
                    )}
                    localization={english as unknown as LocalizationMap}
                    defaultScale={1}
                    theme="dark"
                    fileUrl={url}
                    plugins={[defaultLayoutPluginInstance]}
                    renderError={RenderError}
                    httpHeaders={{
                        Authorization: `Bearer ${jwtToken}`,
                    }} /> :
                <div>Preparing...</div>
            }
        </div>
    );
}