import { IGeopostColorBar } from 'features/seismic-3d/models/interfaces/IGeopostColorBar';

export const seismic3DColorBar : IGeopostColorBar = {
    title: 'Seismic 3D',
    thumb: '/Images/ColorBars/seis.jpg',
    colors: [{
        multiple: 0,
        color: {
            red: 178,
            green: 0,
            blue: 0,
            opacity: 0.7
        }
    },
    {
        multiple: 0.05,
        color: {
            red: 210,
            green: 0,
            blue: 0,
            opacity: 0.7
        }
    },
    {
        multiple: 0.1,
        color: {
            red: 249,
            green: 0,
            blue: 0,
            opacity: 0.7
        }
    },
    {
        multiple: 0.15,
        color: {
            red: 255,
            green: 33,
            blue: 0,
            opacity: 0.7
        }
    },
    {
        multiple: 0.2,
        color: {
            red: 255,
            green: 69,
            blue: 0,
            opacity: 0.7
        }
    },
    {
        multiple: 0.25,
        color: {
            red: 255,
            green: 107,
            blue: 1,
            opacity: 0.7
        }
    },
    {
        multiple: 0.3,
        color: {
            red: 255,
            green: 145,
            blue: 1,
            opacity: 0.7
        }
    },
    {
        multiple: 0.35,
        color: {
            red: 254,
            green: 184,
            blue: 0,
            opacity: 0.7
        }
    },
    {
        multiple: 0.4,
        color: {
            red: 255,
            green: 221,
            blue: 0,
            opacity: 0.7
        }
    },
    {
        multiple: 0.45,
        color: {
            red: 255,
            green: 255,
            blue: 1,
            opacity: 0.7
        }
    },
    {
        multiple: 0.5,
        color: {
            red: 255,
            green: 255,
            blue: 1,
            opacity: 0.7
        }
    },
    {
        multiple: 0.55,
        color: {
            red: 254,
            green: 255,
            blue: 1,
            opacity: 0.7
        }
    },
    {
        multiple: 0.6,
        color: {
            red: 191,
            green: 255,
            blue: 11,
            opacity: 0.7
        }
    },
    {
        multiple: 0.65,
        color: {
            red: 118,
            green: 255,
            blue: 25,
            opacity: 0.7
        }
    },
    {
        multiple: 0.7,
        color: {
            red: 65,
            green: 251,
            blue: 54,
            opacity: 0.7
        }
    },
    {
        multiple: 0.75,
        color: {
            red: 24,
            green: 245,
            blue: 90,
            opacity: 0.7
        }
    },
    {
        multiple: 0.8,
        color: {
            red: 1,
            green: 214,
            blue: 134,
            opacity: 0.7
        }
    },
    {
        multiple: 0.85,
        color: {
            red: 1,
            green: 141,
            blue: 200,
            opacity: 0.7
        }
    },
    {
        multiple: 0.9,
        color: {
            red: 0,
            green: 75,
            blue: 252,
            opacity: 0.7
        }
    },
    {
        multiple: 0.95,
        color: {
            red: 0,
            green: 38,
            blue: 229,
            opacity: 0.7
        }
    },
    {
        multiple: 1,
        color: {
            red: 0,
            green: 5,
            blue: 215,
            opacity: 0.7
        }
    }]
};