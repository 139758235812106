import Map from 'ol/Map';
import {defaults as defaultControls } from 'ol/control';
import {defaults as defaultInteractions } from 'ol/interaction';
import { Feature, View } from 'ol';
import { Extent } from 'ol/extent';
import { LineString } from 'ol/geom';
import { Fill, Stroke, Text,Style } from 'ol/style';
import { Size } from 'ol/size';

import { ICrossSectionConnectionItem } from '../models/interfaces/ICrossSectionItem';
import { useCrossSectionAnnotationsStore } from '../stores/useCrossSectionAnnotationsStore';
import { useCrossSectionStore } from '../stores/useCrossSectionStore';
import { ICrossSection } from '../models/interfaces/ICrossSection';

export function buildMap(wellNameSource: string, WellNameDestination: string):Map{
    return new Map({
        controls: defaultControls({attribution: false, zoom:false}),
        interactions: defaultInteractions({ dragPan: false, shiftDragZoom: false }),
        target: `map${wellNameSource + '_' + WellNameDestination}`
    });
}

export const buildExtent = (view: View, size: Size):Extent => {
    const extent = view.calculateExtent(size);
    extent[1] = -100000;
    extent[3] = 100000; //o Y precisa ser livre para o setCenter funcionar

    return extent;
};

export function buildFeatures(connections: ICrossSectionConnectionItem[], mapExtent: Extent, factorVertical: number, displacementVertical: {[wellId: number]:number}/*, flatAnnotation: string*/, wellStartSource : number, wellDestinationStart : number):Feature[]{
    let features:Feature[] = [];

    connections.forEach(connectionItem => {
        console.log(connectionItem);

        let startY =  (wellStartSource + ( connectionItem.SourceFormationStartDepth - wellStartSource)*factorVertical)  * -1;
        // let startY = (connectionItem.SourceFormationStartDepth) / factorVertical * -1;
        let endY = (wellDestinationStart + (connectionItem.DestinationFormationStartDepth- wellDestinationStart)*factorVertical)  * -1;
        if (displacementVertical[connectionItem.SourceWellId]){
            startY -= (displacementVertical[connectionItem.SourceWellId]);
        }

        if (displacementVertical[connectionItem.DestinationWellId]){
            endY -= (displacementVertical[connectionItem.DestinationWellId]);
        }
        /*

        if (displacementVertical[connectionItem.DestinationWellId]){
            endY -= displacementVertical[connectionItem.DestinationWellId];
            startY = endY;
        }
        */

        const startLine = [mapExtent[0], parseInt(startY.toString())];
        const endLine = [mapExtent[2], parseInt(endY.toString())];

        if (connectionItem.SourceFormationName === 'Fm Sergi'){
            console.log(startY, endY, connectionItem.SourceFormationName, connectionItem.SourceWellId);
        }

        const feature = new Feature<LineString>({
            geometry: new LineString([startLine,endLine])
        });

        feature.set('depth_range_start', connectionItem.RangeDepthStart);
        feature.set('name', connectionItem.SourceFormationName);
        if (connectionItem.UserId) {
            feature.set('groupKey', connectionItem.SourceFormationName + connectionItem.UserId.toString());
        } else {
            feature.set('groupKey', connectionItem.SourceFormationName + '_no_user');
        }

        let style = buildStyle();
        feature.setStyle(style);
        features.push(feature);
    });

    return features;
}

export function buildStyle(){
    return new Style({
        fill: new Fill({color: 'rgb(4, 31, 60)'}),
        stroke: new Stroke({color: 'rgb(4, 31, 60)', width: 3}),
        text: new Text({
            //text:connectionItem.SourceFormationName + '-' + connectionItem.SourceFormationStartDepth,
            offsetY: 10,
            justify: 'left',
            placement: 'line',
            textBaseline: 'alphabetic'
        })
    });
}
