import { View } from 'ol';
import { MousePosition } from 'ol/control';
import { Coordinate, CoordinateFormat } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import Static from 'ol/source/ImageStatic';

import { PROJECTION } from '../consts/layotsConsts';
import { IWellGenericCut } from '../models/interfaces/IWellGenericCut';
import { IDepthRange } from '../stores/useCrossSectionStore';

export function buildExtent (laneWidth: number, genericCut: IWellGenericCut, factorVertical: number): Extent {
    let minX = 0;
    let minY = 1;
    let maxX = laneWidth;
    let maxY = 1;

    if (genericCut){
        minY = genericCut.EndDepth;
        maxY = genericCut.StartDepth;
    }

    const extent = [
        minX,
        // minY * -1* factorVertical,
        // minY * -1,

        // (maxY + (minY - maxY)*factorVertical) * -1,
        (maxY + (minY - maxY)*factorVertical) * -1,
        maxX,
        // maxY * -1 / factorVertical];
        maxY * -1 ];

    console.log(extent);
    return extent;

};

export function calculateVerticalFitByDepths(canvasHeight: number, startDepth: number, endDepth: number){
    const difference = endDepth - startDepth;
    let factorVertical = canvasHeight/difference  ;

    if (window.location.href.toLowerCase().indexOf('crosssection') !== -1){
        factorVertical = 1;
    }

    console.log('endDepth', endDepth, 'startDepth', startDepth, 'difference', difference, 'canvasHeight', canvasHeight, 'fatorvertical', factorVertical);
    return factorVertical;
}

export function calculateCenter(extent: Extent, dataDepthRange: IDepthRange, forceCenterY: number){
    //const baseHeightLane = 800;

    const x = extent[2] / 2;

    let y = (forceCenterY)?forceCenterY:-dataDepthRange.minDepth /*(baseHeightLane / 2) + (dataDepthRange.minDepth) */;

    const center = [x, y];

    return center;
}

export function buildStaticSource(genericCut: IWellGenericCut, extent: Extent,callbackLoading: (value: boolean) => void,externalUrl = '' ,customWidth = 0): Static{
    let wellHeight = genericCut.Height;
    let url =`https://processing.geopostenergy.com/WellGenericCut/GetImageFromAWSS3?token=${genericCut.GenericCutPathToken}`;
    console.log(genericCut.Width);
    let imageDimensions = [customWidth===0?genericCut.Width: customWidth, wellHeight];
    if (externalUrl){
        url = externalUrl;
        imageDimensions = [customWidth===0?genericCut.Width: customWidth, extent[1] * -1 - extent[3] * -1];
    }
    console.log(`Extent Static ::::::::::${extent} ${genericCut.GenericCutName}`);

    const source = new Static({
        //imageSize:  [customWidth===0?genericCut.Width: customWidth, extent[1] * -1 - extent[3] * -1],
        //imageSize:  [customWidth===0?genericCut.Width: customWidth, wellHeight],
        imageSize:  imageDimensions,
        attributions: '©Geopost',
        url: url, //TODO
        imageExtent: extent, //left, bottom, right, top
        projection: PROJECTION
    });

    source.on('imageloadstart', () => callbackLoading(true));
    source.on('imageloaderror', () => callbackLoading(false));
    source.on('imageloadend', () => callbackLoading(false));

    return source;
}

export function buildMousePosition(token: string | undefined, coordinateFormat: CoordinateFormat){
    return new MousePosition({
        coordinateFormat: coordinateFormat,
        projection: PROJECTION,
        className: 'custom-mouse-position',
        target: `mouse${token}`,
    });
}

export function buildView(center: Coordinate, extent: Extent){
    const resolutions = [1];

    return new View({
        projection: PROJECTION,
        resolutions: resolutions,
        // resolution: 2,
        center: center,
        zoom: 0,
        maxZoom: resolutions.length - 1,
        extent: extent,
        constrainOnlyCenter: false,
        smoothResolutionConstraint: false
    });
}