import { useMutation, useQueries, useQuery } from 'react-query';

import { axios } from 'configurations/axios';
import { useSessionStore } from 'session/useSessionStore';

export const useGetImageFromAWSS3 = (urlPartActionTracking: string | undefined) => useQuery(
    ['GetImageFromAWSS3', urlPartActionTracking],
    async () => {
        const response = await axios.get<File>(
            'https://processing.geopostenergy.com/' + urlPartActionTracking,
            {
                responseType: 'blob'
            }
        );
        return response.data;
    },
    {
        enabled: !!urlPartActionTracking
    }
);

export const useGetImagesFromAWSS3 = () => {
    const jwt = useSessionStore(state => state.jwtToken);
    const host = useSessionStore(state => state.tenantConfiguration?.endpoints.s3Image);
    return useMutation(async (url: string) => {
        const response = await axios.get<File>(
            host + url,
            {
                responseType: 'blob',
                headers: {
                    Authorization: 'Bearer ' + jwt
                }
            }
        );
        return response.data;
    });
};