import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AmplitudeDomain } from 'features/seismic/models/enums/AmplitudeDomain';
import { IWell } from 'features/seismic/models/interfaces/IWell';
import { useGetImagesFromAWSS3 } from '../api/useProcessing';
import { useGetGenericCutsForSeismic2 } from '../api/useSeismicWellController';
import { GeopostScene } from 'features/seismic-3d/threejs/scene/GeopostScene';
import { GeopostWellGroup } from 'features/seismic-3d/threejs/group/GeopostWellGroup';
import { IWellGenericCuts } from 'features/seismic/models/interfaces/IWellGenericCuts';
import { GeopostLithologyMesh } from 'features/seismic-3d/threejs/mesh/GeopostLithologyMesh';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';

export const use3DLithologies = (
    wellsWithVisibleLithology: IWell[],
    datum: number,
    trace: number,
    domain: AmplitudeDomain,
    onAddWellGroup: (well: IWell) => void,
) => {
    const { mutateAsync: getGenericCutsForSeismic } = useGetGenericCutsForSeismic2();

    const [ loadingLithologyWells, setLoadingLithologyWells ] = useState<IWell[]>([]);
    //const [ imageUrls, setImageUrls ] = useState<string[]>([]);

    const {mutateAsync: getlithologyImage} = useGetImagesFromAWSS3();

    const {current: lastWellsWithVisibleLithology } = useRef<IWell[]>([]);

    const scene = use3DSceneStore(state => state.scene);

    const surveyHeightInPixels = use3DViewerStore(state => state.heightPixelFactor);

    const wellsToAddLithology = useMemo(() => wellsWithVisibleLithology.filter(data => !lastWellsWithVisibleLithology.includes(data)), [wellsWithVisibleLithology]);

    const wellsToRemoveLithology = useMemo(() => lastWellsWithVisibleLithology.filter(data => !wellsWithVisibleLithology.includes(data)), [wellsWithVisibleLithology]);

    //const isLoading = useMemo(() => isGenericCutsLoading || isImageLoading, [isGenericCutsLoading, isImageLoading]);

    const addLithology = useCallback((well: IWell, timesTried: number = 1) => {
        const lithologyData = well.GenericCuts.split(';').find(genericCut => genericCut.split('|')[2] === '1');
        if (lithologyData) {
            const lithologyToken = lithologyData.split('|')[1];
            setLoadingLithologyWells(current => [...current, well]);
            getGenericCutsForSeismic({
                wellId: well.Id,
                elementToken: lithologyToken,
                datum: datum,
                trace: trace,
                domain: domain
            }).then(genericCutData => {
                if (!!genericCutData.UrlPartActionTracking && !!scene) {
                    getlithologyImage(genericCutData.UrlPartActionTracking).then(image => {
                        let wellGroup : GeopostWellGroup;
                        do {
                            wellGroup = scene.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
                            if (!wellGroup) {
                                onAddWellGroup(well);
                            }
                        } while (!wellGroup);
                        if (!!wellGroup) {
                            const lithologyMesh = new GeopostLithologyMesh(wellGroup.wellPoints, genericCutData.YPosition[1], genericCutData.YPosition[0], wellGroup.heightFactor);
                            lithologyMesh.addTexture(image);
                            lithologyMesh.name = 'lithology';
                            wellGroup.add(lithologyMesh);
                        }
                        setLoadingLithologyWells(current => {
                            const newArray = [...current];
                            newArray.splice(newArray.indexOf(well), 1);
                            return newArray;
                        });
                    });
                } else {
                    if (genericCutData.errorType === 'generating_tracking_not_associeated_time_depth_active' && timesTried < 3) {
                        timesTried++;
                        setTimeout(() => addLithology(well, timesTried), 3000);
                        return;
                    }
                    setLoadingLithologyWells(current => {
                        const newArray = [...current];
                        newArray.splice(newArray.indexOf(well), 1);
                        return newArray;
                    });
                }
            });
        }
    }, [datum, domain, getGenericCutsForSeismic, getlithologyImage, onAddWellGroup, scene, trace]);

    const removeLithology = useCallback((well: IWell) => {
        const wellGroup = scene?.getObjectByName(GeopostWellGroup.getWellGroupName(well.Id)) as GeopostWellGroup;
        if (!!wellGroup) {
            const lithologyMesh = wellGroup.getObjectByName('lithology');
            if (!!lithologyMesh) {
                wellGroup.remove(lithologyMesh);
            }
        }
    }, [scene]);

    useEffect(() => {
        wellsToAddLithology.forEach(well => {
            lastWellsWithVisibleLithology.push(well);
            addLithology(well);
        });
    }, [wellsToAddLithology]);

    useEffect(() => {
        wellsToRemoveLithology.forEach(well => {
            lastWellsWithVisibleLithology.splice(lastWellsWithVisibleLithology.indexOf(well), 1);
            removeLithology(well);
        });
    }, [wellsToRemoveLithology]);

    useEffect(() => {
        if (surveyHeightInPixels !== 0) {
            lastWellsWithVisibleLithology.forEach(well => {
                removeLithology(well);
                addLithology(well);
            });
        }
    }, [surveyHeightInPixels]);

    return { loadingLithologyWells };
};