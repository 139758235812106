import { CSSProperties, Fragment, useRef, useState } from 'react';
import { Box, css, Typography } from '@mui/material';
import { Rnd } from 'react-rnd';
import ReactDOM from 'react-dom';

import { Position } from './models/enums/Position';
import { IGeopostPopupProps } from './models/interfaces/IGeopostPopupProps';
import { useCalculatePosition } from './hooks/useCalculatePosition';
import { GeopostPopupHeader } from './components/GeopostPopupHeader';

const stylePopup = css({
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999999,
    width: '100%',
    height: '100%',

    '& .popup': {
        background: '#fff',
        boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
        borderRadius: 5,
        border: '1px solid #ccc',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
    },
    '& .container-popup': {
        flexGrow: 1,
        overflow: 'auto !important',
        display: 'flex'
    },
    '& .footer': {
        padding: 10,
        background: 'rgba(0,0,0,.1)'
    }
});

const styleOverlay = css({
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0,0,0,.5)'
});

const styleDraggable = css({
    position: 'fixed',
    zIndex: 9999999999999
});

export function GeopostPopup(
    { open, children, onChangeOpen, header, iconHeaders, footer,
        width, minWidth = 200, maxWidth, height, minHeight = 200, maxHeight,
        draggable = true, title = '', position = Position.center, overlay = true, resizable = true,
        rebuildOnOpen = true, customXPosition = undefined, customYPosition = undefined, padding = '0px',
        temporaryIgnoreFlexInContainer = false
    }: IGeopostPopupProps) {
    const popupRef = useRef<HTMLDivElement>(null);

    const { position: positionValue, setPosition: setPositionValue, finishedCalculation: finishedCalculationPosition } = useCalculatePosition(position, open, popupRef, customXPosition, customYPosition);
    const loaded = useRef(false);

    const [ isDragging, setIsDragging ] = useState<boolean>(false);

    if (resizable && (!width || !height)){
        throw new Error('Popup resizable precisa ter width e height definidos');
    }

    if (!open && (!loaded.current || rebuildOnOpen)) {
        return null;
    }

    loaded.current = true;

    const headerElement = (header) ? header : <GeopostPopupHeader title={title} onChangeOpen={onChangeOpen} icons={iconHeaders}/>;

    const containerStyle:CSSProperties = {
        padding: padding
    };

    if (!temporaryIgnoreFlexInContainer){
        containerStyle['display'] = 'flex';
    }

    return ReactDOM.createPortal(
        <Fragment>
            {
                isDragging
                &&
                <div style={{position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, zIndex: 9999}}></div>
            }
            <Rnd default={{
                x: 0,
                y: 0,
                width: width!,
                height: height!
            }}
            style={{
                display: (open)?'block': 'none',
                opacity: (finishedCalculationPosition)?1:0
            }}
            position={{ x: positionValue.x, y: positionValue.y }}
            minWidth={minWidth}
            maxWidth={maxWidth}
            minHeight={minHeight}
            maxHeight={maxHeight}
            css={styleDraggable}
            dragHandleClassName='header'
            bounds='body'
            //disableDragging
            //enableResizing={false}
            disableDragging={!draggable}
            enableResizing={resizable}
            onDragStop={(e, position) => {
                setPositionValue({
                    x: position.x,
                    y: position.y
                });
                setIsDragging(false);
            }}
            onDragStart={(e) => {
                e.stopPropagation();
                setIsDragging(true);
            }}
            >
                <div
                    ref={popupRef}
                    css={stylePopup}
                >
                    <div className='popup'>
                        {headerElement}
                        <Box className='container-popup' sx={containerStyle}>
                            {children}
                        </Box>

                        { footer !== undefined ? <Box className='footer'>{footer}</Box>:null }
                    </div>
                </div>
            </Rnd>

            {overlay && <div css={styleOverlay} style={{display: (open)?'block': 'none'}}></div>}
        </Fragment>,
        document.body!
    );
}