import { Fragment } from 'react';
import { Stack } from '@mui/material';

import { WellTrackLane } from './WellTrackLane';
import { GetAllTracking, GetCrossSection } from '../API/useLogViewerController';
import { ICrossSectionItem } from 'features/cross-section/models/interfaces/ICrossSectionItem';

let tracks = [{name:`track 1`},{name:`track 2`},{name:`track 3`}];

export function WellViewerPanel(){
    const trackingRequests = GetCrossSection('0614f6ae-fac5-493e-bedc-567a242b8e2c');
    const crossSectionItens = trackingRequests.data?.Itens;
    console.log(crossSectionItens);
    return (
        <Stack direction="row" spacing={2}>
            {trackingRequests.isFetched && crossSectionItens?.map((el:ICrossSectionItem) =><WellTrackLane crossSectionItem={el}></WellTrackLane>)}

            {/* {tracks.map(track =><WellTrackLane></WellTrackLane>)} */}
        </Stack>

    );
}
