import { FormControl, Select, MenuItem } from '@mui/material';
import { useState } from 'react';

import { LoadingBlock } from 'components/LoadingBlock';
import { GetAllTracking } from '../API/useLogViewerController';
import { IWellGenericCut } from 'features/cross-section/models/interfaces/IWellGenericCut';

const handleChange = ()=>{

};

export function WellTrackingSelect({crossSectionItemToken} : IProps){
    console.log(crossSectionItemToken);
    let trackingRequests;
    if (crossSectionItemToken){
        trackingRequests = GetAllTracking(crossSectionItemToken);
    }
    console.log(trackingRequests);

    const [selectedTracking, setSelectedTracking] = useState<IWellGenericCut>();
    return (
        <FormControl fullWidth>
            <LoadingBlock open={trackingRequests?.isLoading ?? true}/>
            <Select onChange={handleChange} value={selectedTracking?.GenericCutToken ?? ''} size='small'>
                {
                    trackingRequests?.data?.map((el:IWellGenericCut) => (
                        <MenuItem
                            value={el.GenericCutToken}
                            key={el.GenericCutToken}>
                            {el.GenericCutType}{(el.GenericCutName) ? ` - ${el.GenericCutName}`:''}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );

}
interface IProps{
    crossSectionItemToken: string | undefined;
};