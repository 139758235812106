export function getBooleanLabel(value: boolean){
    return (value)?'yes':'no';
}

export function getLabelSize (size:number) {
    const gigabyte = size / 1000000000;

    if (gigabyte > 1){
        return gigabyte.toFixed(2) + ' GB';
    }

    const megabyte = size / 1000000;
    return megabyte.toFixed(1) + ' MB';
};
