import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import alertify from 'alertifyjs';
import { FormContainer } from 'react-hook-form-mui';
import { GeopostTextField } from 'fileIndexing/otherComponents/GeopostTextField';
import { GeopostDropdown } from 'fileIndexing/otherComponents/GeopostDropdown';

import { ISubgroup } from '../interfaces/ITaxonomy';
import { useGetAllDataGroups, useSaveDataSubGroup } from '../useTaxonomy';

export const TaxonomyForm = ({ setOpenModal, refetchTableData, setLoading, formData }: Props) => {
    const formDataProps = useForm<ISubgroup>({
        defaultValues: formData || undefined
    });
    const [data, setData] = useState<ISubgroup | null>(null);
    const [dataGroupItems, setDataGroupItems] = useState<{ id: string; label: string; }[]>([]);

    const internalPathEnable = formDataProps.watch('CopyTransmittalFilesToInternalPath');

    const { data: dataGroups } = useGetAllDataGroups();
    const { data: saveDataSubGroup } = useSaveDataSubGroup(data);

    const handleData = (data: ISubgroup) => {
        let newData = data;
        if (formData) {
            newData.Token = formData.Token;
        }
        else {
            newData.Token = '';
        }

        const selectedItem = dataGroups!.find(item => item.Token === data.DataGroupToken);
        if (selectedItem) {
            newData.DataGroupToken = selectedItem.Token;
            newData.DataGroupName = selectedItem.Name;
        }

        if (!newData.CopyTransmittalFilesToInternalPath) {
            newData.CopyTransmittalFilesToInternalPath = false;
            newData.TransmittalInternalPath = '';
        }

        newData.DataSubRulesTokens = [];

        setData(newData);
    };

    useEffect(() => {
        if (dataGroups) {
            setDataGroupItems(dataGroups.map(dataGroup => ({
                id: dataGroup.Token,
                label: dataGroup.Name
            })));
        }
    }, [dataGroups]);

    // AVISO: talvez seja retirada essa parte
    function wait(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function executeSave(): Promise<void> {
        setOpenModal(false);
        setLoading(true);
        await wait(2000);
        setLoading(false);
        refetchTableData();
    }

    useEffect(() => {
        if (saveDataSubGroup) {
            if (saveDataSubGroup === 'Success') {
                executeSave();
            }
            else {
                alertify.notify('Ocorreu um erro criando uma nova Instituição');
            }
        }
    }, [saveDataSubGroup]);

    return (
        <Fragment>
            <FormContainer<ISubgroup> defaultValues={formData ?? {}} onSuccess={(data) => handleData(data)} FormProps={{ style:{ width: '100%' } }}>
                <Grid spacing={2} container direction='column'>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'Name'} label={'Subgroup Name'} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'InternalPath'} label={'Internal Path'} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostDropdown fieldName={'DataGroupToken'} label={'Parent Group'} items={dataGroupItems} />
                    </Grid>
                    <Grid xs={12} item>
                        <GeopostTextField fieldName={'RegexPathExtractRule'} label={'Regex Extract Rule Path'} />
                    </Grid>
                    <Grid xs={12} item>
                        <FormGroup className='space-bottom'>
                            <FormControlLabel control={
                                <Checkbox value={internalPathEnable} onChange={() => formDataProps.setValue('CopyTransmittalFilesToInternalPath', !internalPathEnable)} color='primary' />
                            } label={'Copy Transmittal Files to Internal Path'} />
                        </FormGroup>
                    </Grid>
                    {internalPathEnable &&
                        <Grid xs={12} item>
                            <GeopostTextField fieldName={'TransmittalInternalPath'} label={'Transmittal Internal Path'} />
                        </Grid>
                    }
                    <Grid container item direction='row-reverse'>
                        <Button variant='contained' color='primary' type='submit' style={{ marginBottom: '16px' }}>SAVE</Button>
                    </Grid>
                </Grid>
            </FormContainer>
        </Fragment>
    );
};

interface Props {
    setOpenModal: (value: boolean) => void;
    refetchTableData: () => void;
    setLoading: (value: boolean) => void;
    formData?: ISubgroup | null;
}