import React from 'react';
import { useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import { use3DSceneStore } from '../stores/use3DSceneStore';
import { use3DViewerStore } from '../stores/use3DViewerStore';
import { GeopostScene } from '../threejs/scene/GeopostScene';

export const GeopostSceneContainer = ({ height, width } : GeopostSceneContainerProps) => {
    const sceneIframeRef = useRef<HTMLIFrameElement>(null);

    const { scene, lightIntensityPercentage, heightScale  } = use3DSceneStore(state => ({
        scene: state.scene,
        lightIntensityPercentage: state.lightIntensityPercentage,
        heightScale: state.heightScale
    }), shallow);

    const onCanvasResize : React.ReactEventHandler<HTMLIFrameElement> = (event) => {
        //scene.updateDimensions(parseFloat(event.currentTarget.width), parseFloat(event.currentTarget.height));
        console.log(event.currentTarget.width + ' -- ' + event.currentTarget.height);
    };

    useEffect(() => {
        const sceneIframeDocument = sceneIframeRef.current!.contentDocument ?? (sceneIframeRef.current!.contentWindow?.document);
        //const sceneIframeDocument = (document.getElementById('abcdefg') as HTMLIFrameElement).contentDocument;
        sceneIframeDocument?.open();
        const renderersContainer = sceneIframeDocument!.createElement('div');
        sceneIframeDocument!.appendChild(renderersContainer);
        scene.setupAndRun(renderersContainer, sceneIframeRef!.current!.contentWindow!.innerWidth, sceneIframeRef!.current!.contentWindow!.innerHeight);
        //sceneIframeDocument!.body.appendChild(div);
    }, []);

    useEffect(() => {
        scene.updateDimensions(width, height);
    }, [height, width]);

    useEffect(() => {
        scene.setLightIntensity(lightIntensityPercentage);
    }, [lightIntensityPercentage]);

    useEffect(() => {
        scene.changeHeightScale(heightScale);
    }, [heightScale]);

    useEffect(() => {
        console.log('scene container updat');
    });

    return (
        <>
            <iframe
                style={{margin: 0, border: 0}}
                title='threeJsSceneIframe'
                ref={sceneIframeRef}
                src='/scene.html'
                height={height}
                width={width}
            />
        </>
    );
};

export type GeopostSceneContainerProps = {
    height: number;
    width: number;
};