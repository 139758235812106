import { useCallback, useEffect, useRef, useState } from 'react';
import Graticule from 'ol-ext/control/Graticule';
import { Fill, Text, Stroke, Style } from 'ol/style';

import { useMapStore } from '../stores/useMapStore';
import { useSeismicStore } from '../stores/useSeismicStore';
import {  getTraceValue } from '../utils/getLabelsUtils';
import { GeopostSeismicGraticule } from '../models/classes/GeopostSeismicGraticule';
import { ILineString } from '../models/interfaces/ILineString';
import { useLine3DNavigationStore } from '../stores/useLine3DNavigationStore';
import { LineType } from '../models/enums/LineType';
import { getAmplitude, getTrace } from '../utils/calculateScreenValuesUtils';

export function useSeismicGraticule(dataGeometryLine: ILineString | null){
    const { scale, surveyMetadata, calculator, scaleX, isInverted } = useSeismicStore(state => ({
        calculator: state.calculator,
        scale: state.scale,
        surveyMetadata: state.surveyMetadata,
        scaleX: state.scale.x,
        isInverted: state.isInverted
    }));
    const lineType = useLine3DNavigationStore(state => state.lineType);

    const [ stepX, setStepX] = useState(1000);
    const [ stepY, setStepY] = useState(1000);

    const [enableGraticule, setEnableGraticule] = useState(false);
    const [enableLine, setEnableLine] = useState(true);

    const { map } = useMapStore(state => ({
        map: state.map
    }));

    const graticuleControl = useRef<Graticule | null>(null);

    const toggleGraticule = useCallback(() => {
        setEnableGraticule(state => !state);
    }, []);

    useEffect(() => {
        if (!map || !surveyMetadata){
            return;
        }

        if (graticuleControl.current){
            map.removeControl(graticuleControl.current);
        }

        if (!enableGraticule){
            return;
        }

        const stepXValue = (stepX < 10) ? 10:stepX;
        const stepYValue = (stepY < 10) ? 10:stepY;

        const increment = lineType === LineType.Inline ? surveyMetadata.Survey3DInfo.XlineIncrement : surveyMetadata.Survey3DInfo.InlineIncrement;
        const innerGraticule = new GeopostSeismicGraticule({
            step: stepXValue * scale.x,
            increment: increment,
            stepCoord: 1,
            projection: 'EPSG:3857',
            style: buildStyle(true, 16, enableLine),
            stepY: stepYValue * scale.y / 4,
            //spacing: 100,
            // @ts-ignore
            formatCoord(coordinate, position) {
                const coordinateValue = (coordinate as unknown) as number;

                if (position === 'left' || position === 'right'){
                    return getAmplitude(coordinateValue, scale.y, calculator!.calculateSampleInterval(surveyMetadata));
                }
                else {
                    const start = lineType === LineType.Inline ? surveyMetadata.Survey3DInfo.XlineStart : surveyMetadata.Survey3DInfo.InlineStart;
                    const end = lineType === LineType.Inline ? surveyMetadata.Survey3DInfo.XlineEnd : surveyMetadata.Survey3DInfo.InlineEnd;
                    //return getTraceValue(coordinateValue, scaleX, start).toString();
                    if (isInverted) {
                        return end - getTrace(coordinateValue, scaleX, start, increment) + start;
                    } else {
                        return getTrace(coordinateValue, scaleX, start, increment);
                    }
                }
            }
        });

        if (map){
            // map.addLayer(g);
            map.addControl(innerGraticule);
            graticuleControl.current = innerGraticule;
        }

    }, [enableGraticule, map?.geopostKey, scale?.x, scale?.y, enableLine, stepX, stepY, lineType, isInverted]);

    return {
        line: {
            set: setEnableLine,
            value: enableLine
        },
        isEnable: enableGraticule,
        stepX: {
            set: setStepX,
            value: stepX
        },
        stepY: {
            set: setStepY,
            value: stepY
        },
        toggle: toggleGraticule
    };
}

function buildTextStyle(fontSize: number){
    return new Text({
        font: fontSize + 'px Verdana sans-serif',
        fill: new Fill({
            color: '#000'
        }),
        stroke: new Stroke({
            color: '#fff',
            width: 3
        })
    });
}

function buildStrokeStyle(){
    return new Stroke({
        color: '#00000073',
        width: 1,
    });
}

function buildStyle(enableCoordinates: boolean, coordinatesFontSize: number, enableLine: boolean){
    const textStyle = (enableCoordinates) ? buildTextStyle(coordinatesFontSize):undefined;
    const strokeStyle = (enableLine) ? buildStrokeStyle():undefined;

    return new Style({
        text: textStyle,
        stroke: strokeStyle,
    });
}

