import { useQuery } from 'react-query';
import { IImportByDump } from 'fileIndexing/files/interfaces/IFiles';

import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { IDataSource } from './interfaces/IDataSource';
import { useSessionStore } from 'session/useSessionStore';

export function useGetAllDataSource() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getAllDataSource', async () => {
        const response = await axios.request<IResponseAPI<IDataSource[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/DataSource/GetListTable',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        cacheTime: 0,
    });
}

export function useGetDataSourceByToken(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getDataSourceByToken' + token, async () => {
        const response = await axios.request<IResponseAPI<IDataSource>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/DataSource/GetFileSourceByToken',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
        cacheTime: 0,
    });
}

export function useGetFileDataSources() {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('getFileDataSources' + tenantConfig?.tenantToken, async () => {
        const response = await axios.request<IResponseAPI<IDataSource[]>>({
            method: 'get',
            url: tenantConfig?.endpoints.fileIndexing + '/DataSource/GetFileDataSources',
            params: {
                projectToken: tenantConfig?.tenantToken,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: tenantConfig?.tenantToken !== null && tenantConfig?.tenantToken !== undefined && tenantConfig?.tenantToken !== '',
        cacheTime: 0,
    });
}

export function useSaveDataSource(data: IDataSource | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('saveDataSource' + data, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'post',
            url: tenantConfig?.endpoints.fileIndexing + '/DataSource/SaveFileDataSource',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: data !== null,
    });
}

export function useDeleteDataSource(token: string) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('deleteDataSource' + token, async () => {
        const response = await axios.request<IResponseAPI<string>>({
            method: 'delete',
            url: tenantConfig?.endpoints.fileIndexing + '/DataSource/DeleteFileDataSource',
            params: {
                token
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`
            },
        });

        return response.data.Result;
    }, {
        refetchOnWindowFocus: false,
        enabled: token !== null && token !== undefined && token !== '',
    });
}

export function useDumpCSVFile(dumpFile: IImportByDump | null) {
    const { tenantConfig, jwtToken } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
        jwtToken: state.jwtToken
    }));

    return useQuery('dumpCSVFile' + dumpFile?.file + dumpFile?.delimiter + dumpFile?.withChecksum + dumpFile?.connectionId + dumpFile?.dataSourceToken, async () => {
        let formData = new FormData();
        if (dumpFile !== null) {
            formData.append('file', dumpFile.file);
        }

        formData.append('[csvFileInfo][delimiter]', dumpFile ? dumpFile.delimiter : '');
        formData.append('[csvFileInfo][withChecksum]', dumpFile ? `${dumpFile.withChecksum}` : 'false');
        formData.append('[csvFileInfo][connectionId]', dumpFile ? dumpFile.connectionId : '');
        formData.append('[csvFileInfo][dataSourceToken]', dumpFile ? dumpFile.dataSourceToken : '');

        try {
            const response = await axios.request<IResponseAPI<string>>({
                method: 'post',
                data: formData,
                url: tenantConfig?.endpoints.fileIndexing + '/DataSource/DumpCSVFile',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${jwtToken}`
                },
            });

            return response.data.Result;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any) {
            if (error.message === 'Network Error') {
                return 'Network error: Unable to connect to the server.';
            }
            return await error.response.data.Result.Message;
        }
    }, {
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        enabled: dumpFile !== null,
        cacheTime: 0,
    });
}