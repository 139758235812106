import { Box, Button, css, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, Zoom } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Fragment, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';
import alertify from 'alertifyjs';
import { GeopostTable } from 'fileIndexing/otherComponents/GeopostTable';
import { MainTableCss } from 'fileIndexing/envVar/envVar';

import { GeopostPopup } from 'components/geopostPopup/GeopostPopup';
import { useGetAllTagGroups, useGetAllTags } from '../useTags';
import { TagGroupsForm } from './TagGroupsForm';
import { ITag, ITagGroup } from '../interfaces/ITag';
// import { InstitionsForm } from './TagsForm';
// import { ITag } from '../interfaces/ITag';
// import { useDeleteTag, useGetAllTags, useGetTagByToken } from '../useTag';

let style = css({
    '& .tag-groups-row': {
        flexDirection: 'row',
    },
    '& .search-select': {
        width: '200px',
    }
});

export function TagsTable() {
    const [tagGroupSelected, setTagGroupSelected] = useState<string>('');

    const [openTagGroupModal, setOpenTagGroupModal] = useState<boolean>(false);
    const [modalTagGroupData, setModalTagGroupData] = useState<ITagGroup | null>(null);

    // const [openModal, setOpenModal] = useState<boolean>(false);
    // const [modalData, setModalData] = useState<ITag | null>(null);

    const [deleteTagGroupToken, setDeleteTagGroupToken] = useState<string>('');

    // const [editToken, setEditToken] = useState<string>('');
    // const [deleteToken, setDeleteToken] = useState<string>('');

    // const [filteredData, setFilteredData] = useState<ITag[] | undefined>([]);
    const [filter, setFilter] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    const { data: tagGroupsData, isLoading: isLoadingTagGroup, refetch: refetchTagGroup } = useGetAllTagGroups();
    // const { data: tagGetByToken } = useGetTagByToken(editToken);
    const { data: tagsData, isLoading: tagsDataLoading, refetch } = useGetAllTags(tagGroupSelected);
    // const { data: deleteInstituitionData } = useDeleteTag(deleteToken);

    const handleOpenTagGroupModal = () => setOpenTagGroupModal(true);
    const handleCloseTagGroupModal = () => setOpenTagGroupModal(false);

    // const handleOpenModal = () => setOpenModal(true);
    // const handleCloseModal = () => setOpenModal(false);

    const handleOpenTagGroupEdit = () => {
        if (tagGroupSelected && tagGroupsData) {
            let tagGroup = tagGroupsData.find(x => x.Token === tagGroupSelected);
            if (tagGroup) {
                setModalTagGroupData(tagGroup);
                setOpenTagGroupModal(true);
            }
        }
    };

    // const handleOpenEdit = (rowData: ITag) => {
    //     setEditToken(rowData.Token);
    // };

    const handleDeleteTagGroup = () => {
        alertify.confirm(`Do you really wanna delete ${tagGroupsData?.find(x => x.Token === tagGroupSelected)?.Name}?`, async () => {
            setDeleteTagGroupToken(tagGroupSelected);
        });
    };

    // const handleDelete = (name: string, token: string) => {
    //     alertify.confirm(`Do you really wanna delete ${name}?`, async () => {
    //         setDeleteToken(token);
    //     });
    // };

    // reseta as informações preenchidas no modal de tagGroup
    // useEffect(() => {
    //     if (!openModal) {
    //         setModalData(null);
    //     }
    // }, [openModal]);

    // reseta as informações preenchidas no modal de tag
    // useEffect(() => {
    //     if (!openModal) {
    //         setModalData(null);
    //     }
    // }, [openModal]);

    // seta as informações para edit de tagGroup
    // useEffect(() => {
    //     if (tagGetByToken) {
    //         setModalData(tagGetByToken);
    //         handleOpenModal();
    //         setEditToken('');
    //     }
    // }, [tagGetByToken]);

    // seta as informações para edit de tag
    // useEffect(() => {
    //     if (tagGetByToken) {
    //         setModalData(tagGetByToken);
    //         handleOpenModal();
    //         setEditToken('');
    //     }
    // }, [tagGetByToken]);

    // salva as informações de todas as linhas recebidas
    // useEffect(() => {
    //     if (tagsData) {
    //         setFilteredData(tagsData);
    //     }
    // }, [tagsData]);

    // realiza o filtro
    // useEffect(() => {
    //     if (tagsData) {
    //         if (filter === '') {
    //             setFilteredData(tagsData);
    //         }
    //         else {
    //             setFilteredData(tagsData.filter(x => x.Name?.toLowerCase().startsWith(filter.toLowerCase()) ||
    //                 x.Type?.toLowerCase().startsWith(filter.toLowerCase())));
    //         }
    //     }
    // }, [filter]);

    // useEffect(() => {
    //     if (deleteInstituitionData === 'Success') {
    //         refetch();
    //     }
    // }, [deleteInstituitionData]);

    // conteúdo da tabela
    // const columns: GridColDef[] = [
    //     {
    //         field: 'Name',
    //         headerName: 'Name',
    //         headerAlign: 'left',
    //         flex: 1,
    //     },
    //     {
    //         field: 'Type',
    //         headerName: 'Type',
    //         headerAlign: 'left',
    //         flex: 1,
    //     },
    //     {
    //         field: 'Options',
    //         headerName: 'Options',
    //         headerAlign: 'center',
    //         sortable: false,
    //         flex: 1,
    //         renderCell: (params) => (
    //             <Box className='options-buttons'>
    //                 <Tooltip title='Edit Tag metadata' placement='top' arrow TransitionComponent={Zoom}>
    //                     <Button variant='contained' color='primary' onClick={() => handleOpenEdit(params.row)}>
    //                         <EditNoteIcon />
    //                     </Button>
    //                 </Tooltip>
    //                 <Tooltip title='Delete Tag' placement='top' arrow TransitionComponent={Zoom}>
    //                     <Button variant='contained' color='error' className='delete-icon' onClick={() => handleDelete(params.row.Name, params.row.Token)}>
    //                         <DeleteIcon />
    //                     </Button>
    //                 </Tooltip>
    //             </Box>
    //         ),
    //     },
    // ];

    // const getRowId = (row: ITag) => row.Token;

    return (
        <Fragment>
            <Box css={[MainTableCss, style]}>
                <Box className='tag-groups-row'>
                    <FormControl disabled={isLoadingTagGroup} className='search-select' size='small' >
                        <InputLabel color='primary'>Tag Groups</InputLabel>
                        <Select
                            value={tagGroupSelected}
                            onChange={(event) => { setTagGroupSelected(event.target.value) }}
                            label='Data Group Filter'
                            color='primary'
                        >
                            <MenuItem key={'None'} value={''}>None</MenuItem>
                            {tagGroupsData?.map((tagGroupData) => (
                                <MenuItem key={tagGroupData.Token} value={tagGroupData.Token}>{tagGroupData.Name}</MenuItem>
                            ))};
                        </Select>
                    </FormControl>
                    <Button variant='contained' color='primary' className='add-icon' onClick={() => handleOpenTagGroupModal()}>
                        <AddIcon />
                    </Button>
                    <Button disabled={tagGroupSelected === ''} variant='contained' color='primary' className='add-icon' onClick={() => handleOpenTagGroupEdit()}>
                        <EditNoteIcon />
                    </Button>
                    <Button disabled={tagGroupSelected === ''} variant='contained' color='error' className='delete-icon' onClick={() => handleDeleteTagGroup()}>
                        <DeleteIcon />
                    </Button>
                </Box>
                <Box className='table-header'>
                    <Typography className='table-header-title'>Tags</Typography>
                    <Box className='search'>
                        <TextField label='Search Tag' variant='outlined' size='small' color='primary' value={filter} onChange={(event) => setFilter(event.target.value)} />
                        {/* <Button variant='contained' color='primary' className='add-icon' onClick={() => handleOpenModal()}>
                            <AddIcon />
                        </Button> */}
                    </Box>
                </Box>
                {/* <GeopostTable<ITag> filteredData={filteredData} loading={tagsDataLoading || loading} columns={columns} getRowId={getRowId} /> */}
            </Box>

            <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openTagGroupModal}
                onChangeOpen={handleCloseTagGroupModal}
                resizable={false}
                width={560}
                minHeight={260}
                height={260}
                maxHeight={500}
                title={modalTagGroupData ? `Edit ${modalTagGroupData.Name}` : 'Add Tag'}
                padding={'16px'}
            >
                <TagGroupsForm setOpenModal={setOpenTagGroupModal} refetchTableData={refetchTagGroup} setLoading={setLoading} formData={modalTagGroupData} />
            </GeopostPopup>

            {/* <GeopostPopup
                temporaryIgnoreFlexInContainer
                open={openModal}
                onChangeOpen={handleCloseModal}
                resizable={false}
                width={560}
                minHeight={260}
                height={260}
                maxHeight={500}
                title={modalData ? `Edit ${modalData.Name}` : 'Add Tag'}
                padding={'16px'}
            >
                <TagsForm setOpenModal={setOpenModal} refetchTableData={refetch} setLoading={setLoading} formData={modalData} />
            </GeopostPopup> */}
        </Fragment>
    );
}