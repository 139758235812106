import { CircularProgress, Theme, ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { InstitutionMainPage } from 'fileIndexing/institutions/pages/InstitutionMainPage';
import { ContractsMainPage } from 'fileIndexing/contracts/pages/ContractsMainPage';
import { EntitiesMainPage } from 'fileIndexing/entities/pages/EntitiesMainPage';
import { TaxonomyMainPage } from 'fileIndexing/taxonomies/pages/TaxonomyMainPage';
import { FilesMainPage } from 'fileIndexing/files/pages/FilesMainPage';
import { TagMainPage } from 'fileIndexing/tags/pages/TagMainPage';
import { DataSourceMainPage } from 'fileIndexing/dataSources/pages/dataSourceMainPage';

import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { useSessionStore } from 'session/useSessionStore';
import { RestrictedRoute } from 'features/auth/RestrictedRoute';
import { ViewerSecureRoute } from 'features/auth/ViewerSecureRoute';
import { SeismicViewer } from 'pages/SeismicViewer/SeismicViewer';
import { CrossSectionViewer } from 'pages/CrossSectionViewer/CrossSectionViewer';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { IndexViewer } from 'pages/Index/IndexViewer';
import { FeatureViewer3D } from 'pages/SeismicViwer3D/FeatureViewer3D';
import { DocumentViewer } from 'pages/DocumentViewer/DocumentViewer';
import { WellViewer } from 'pages/WellViewer/WellViewer';
import { WellCatalog } from 'pages/WellCatalog/WellCaralog';
import { useTenantConfigurationApi } from 'features/tenant/api/useTenantApi';
import { getParamsFromUrl } from 'features/seismic/utils/seismicUrlUtils';
import { LoginLoader } from 'features/auth/LoginLoader';
import { TenantConfig } from 'features/tenant/model/TenantConfig';
import { getUrlParams } from 'features/seismic-3d/utils/Seismic3DUrlUtils';
import { LogoutPanel } from 'features/seismic/components/LogoutPanel';

export function Main() {
    const [theme, setTheme] = useState<Theme | null>(null);
    const { data, isFetching } = useTenantConfigurationApi();
    const access_token = getParamsFromUrl().access_token;

    const { setTenantConfig, tenantConfig, setJwtToken, jwtToken } = useSessionStore(state => ({
        setTenantConfig: state.setTenantConfiguration,
        tenantConfig: state.tenantConfiguration,
        setJwtToken: state.setJwtToken,
        jwtToken: state.jwtToken
    }));

    const { data:access_token_result } = useQuery('access_token_' + access_token, async () => {
        if (access_token !== '') {
            //temporario para PGS ate termos novo certificado para o viewer
            const jwtFromAccessToken = axios.request<IResponseAPI<string>>({
                method: 'get',
                url: 'https://eur-prod-seismic-render-api.geopostenergy.com/seismic/parseAccessToken?access_token=' + access_token,
                //url: 'http://localhost:5000/seismic/parseAccessToken?access_token=' + access_token,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return jwtFromAccessToken;
        }
    }, {
        enabled: access_token !== '', //&& !jwtToken,
    });

    const [hasError, setHasError] = useState(false);
    const cookies = new Cookies();

    useEffect(() => {
        if (data) {
            if (data instanceof Error) {
                setHasError(true);
            }
            else {
                const tenantConfig = data as TenantConfig;
                setTenantConfig(tenantConfig);
                const jwt = getParamsFromUrl().jwtToken;
                if (jwt && !access_token_result) {
                    setJwtToken(jwt);
                } else {
                    if (access_token_result) {
                        const tenantToken = getParamsFromUrl().tenantToken;
                        const jwtFromCache = access_token_result.data.Result;
                        if (jwtFromCache && jwtFromCache !== 'undefined' && jwtFromCache !== 'null') {
                            setJwtToken(jwtFromCache);
                            cookies.set('auth_spa_' + tenantToken, jwtFromCache, { path: '/', expires: new Date(Date.now() + 7200000)});
                        }
                    }
                }

                const newTheme = createTheme(tenantConfig.main);
                setTheme(newTheme);
            }
        }
    }, [data, access_token_result]);

    const router = createBrowserRouter([
        {
            element: <ViewerSecureRoute />,
            children: [
                {
                    element: <RestrictedRoute />,
                    children: [
                        {
                            path: '/geopost/seismic',
                            caseSensitive: false,
                            element: <SeismicViewer />
                        },
                        {
                            path: '/seismic',
                            caseSensitive: false,
                            element: <SeismicViewer></SeismicViewer>,
                        },
                        {
                            path: '/app-viewer/seismic',
                            caseSensitive: false,
                            element: <SeismicViewer></SeismicViewer>,
                        },
                        {
                            path: '/app-viewer',
                            caseSensitive: false,
                            element: <SeismicViewer></SeismicViewer>,
                        },
                        {
                            path: '/',
                            caseSensitive: false,
                            element: <SeismicViewer></SeismicViewer>,
                        },
                        {
                            path: '/CrossSection',
                            caseSensitive: false,
                            element: <CrossSectionViewer></CrossSectionViewer>,
                        },
                        {
                            path: '/Well/CrossSection',
                            caseSensitive: false,
                            element: <CrossSectionViewer></CrossSectionViewer>,
                        },
                        {
                            path: '/app-viewer/CrossSection',
                            caseSensitive: false,
                            element: <CrossSectionViewer></CrossSectionViewer>,
                        },
                        {
                            path: '/app-viewer/Well/CrossSection',
                            caseSensitive: false,
                            element: <CrossSectionViewer></CrossSectionViewer>,
                        },
                        {
                            path: '/Indexing',
                            caseSensitive: false,
                            element: <IndexViewer></IndexViewer>,
                        },
                        {
                            path: '/Well/',
                            caseSensitive: false,
                            element: <CrossSectionViewer></CrossSectionViewer>,
                        },
                        {
                            path: '/Well',
                            caseSensitive: false,
                            element: <CrossSectionViewer></CrossSectionViewer>,
                        },
                        {
                            path: '/geopost/Well',
                            caseSensitive: false,
                            element: <CrossSectionViewer></CrossSectionViewer>,
                        },
                        {
                            path: '/app-viewer/3DViewer',
                            caseSensitive: false,
                            element: <FeatureViewer3D></FeatureViewer3D>
                        },
                        {
                            path: '/3DViewer',
                            caseSensitive: false,
                            element: <FeatureViewer3D></FeatureViewer3D>
                        },
                        {
                            path: '/app-viewer/files',
                            caseSensitive: false,
                            element: <DocumentViewer></DocumentViewer>,
                        },
                        {
                            path: '/files',
                            caseSensitive: false,
                            element: <DocumentViewer></DocumentViewer>,
                        },
                        {
                            path: '/wellviewer',
                            element: <WellViewer></WellViewer>,
                        },
                        {
                            path: '/catalog',
                            element: <WellCatalog></WellCatalog>,
                        },
                    ]
                },
                {
                    path: '/logout',
                    element: <LogoutPanel/>
                },
                {
                    path: '/logincallback',
                    element: <LoginLoader />
                },
                {
                    path: '/test',
                    element: <SeismicViewer />
                },
                {
                    path: '/FileIndexing/institutions',
                    element: <InstitutionMainPage />,
                },
                {
                    path: '/FileIndexing/contracts',
                    element: <ContractsMainPage />
                },
                {
                    path: '/FileIndexing/dataSources',
                    element: <DataSourceMainPage />
                },
                {
                    path: '/FileIndexing/taxonomy',
                    element: <TaxonomyMainPage />
                },
                {
                    path: '/FileIndexing/tags',
                    element: <TagMainPage />
                },
                {
                    path: '/FileIndexing/entities',
                    element: <EntitiesMainPage />
                },
                {
                    path: '/FileIndexing/files',
                    element: <FilesMainPage />
                },
            ]
        }
    ]);

    if (hasError) {
        const url = getUrlParams();

        let message = 'An error ocurred to start viewer';

        if (url.development) {
            const errorData = data as Error;
            message += '\n' + errorData.message;
            message += '\n' + errorData.stack;
        }

        return <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            {message.split('\n').map((i, key) => {
                return <p style={{ textAlign: 'center', fontSize: 18, margin: 0 }} key={key}>{i}</p>;
            })}
        </div>;
    }

    return (
        <Fragment>
            {tenantConfig && !isFetching && theme ?
                <ThemeProvider theme={theme}>
                    <RouterProvider fallbackElement={<CrossSectionViewer />} router={router} />
                </ThemeProvider>
                : <div style={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress></CircularProgress>
                </div>
            }
        </Fragment>
    );
}
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false, // default: 3
            refetchOnReconnect: false, // default: true
            refetchOnWindowFocus: false, // default: true
        },
    },
});

export function App() {
    return (
        <Fragment>
            {
                <QueryClientProvider client={queryClient}>
                    <ErrorBoundary>
                        <Main />
                    </ErrorBoundary>
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </QueryClientProvider>
            }
        </Fragment>
    );
}