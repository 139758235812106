import * as THREE from 'three';
import { DoubleSide } from 'three';

import { ISeismicViewer3DMap } from 'features/seismic-3d/models/interfaces/ISeismicViewer3DMap';
import { GeopostGridGeometry } from '../geometry/GeopostGridGeometry';
import { GeopostGridMaterial } from '../material/GeopostGridMaterial';
import { GeopostGridMesh } from '../mesh/GeopostGridMesh';
import { GeopostScene } from '../scene/GeopostScene';
import { GeopostThreeJsConstants } from './GeopostThreeJsConstants';

const getGridGroupName = (token: string) => {
    return `grid-${token}`;
};

export const getGridGroup = async (grid: ISeismicViewer3DMap, wmsDisplacement: File, wmsColor: File, countTimeDepth: number, surveyHeightPixel: number, surveyCentroidX: number, surveyCentroidY: number, opacity = 1) => {
    const displacementScale = getDisplacementScale(grid.Min, grid.Max, countTimeDepth, surveyHeightPixel);

    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');

    const coloredLayerMaterial = await GeopostGridMaterial.buildFromWms(grid,wmsDisplacement, wmsColor, true, displacementScale);

    coloredLayerMaterial.opacity = 0.6;
    coloredLayerMaterial.alphaTest = 0.01;
    coloredLayerMaterial.transparent = true;
    coloredLayerMaterial.polygonOffset = true;
    coloredLayerMaterial.depthTest = true;
    coloredLayerMaterial.polygonOffsetFactor = -0.1;
    coloredLayerMaterial.polygonOffsetUnits = 0.1;
    //coloredLayerMaterial.blending = THREE.MultiplyBlending;
    const shadedLayerMaterial = await GeopostGridMaterial.buildFromWms(grid, wmsDisplacement, wmsColor, false, displacementScale);
    shadedLayerMaterial.alphaTest = 0.1;
    shadedLayerMaterial.opacity = 1;
    shadedLayerMaterial.transparent = true;
    shadedLayerMaterial.depthTest = true;

    //shadedLayerMaterial.blending = THREE.MultiplyBlending;

    const layersGeometry = new GeopostGridGeometry(grid, surveyCentroidX, surveyCentroidY);

    const coloredLayerMesh = new GeopostGridMesh(layersGeometry, coloredLayerMaterial);
    coloredLayerMesh.adjustPosition(grid.Max, countTimeDepth, surveyHeightPixel);
    coloredLayerMesh.name = 'colored';
    const shadedLayerMesh = new GeopostGridMesh(layersGeometry, shadedLayerMaterial);
    shadedLayerMesh.adjustPosition(grid.Max, countTimeDepth, surveyHeightPixel);
    shadedLayerMesh.name = 'uncolored';
    //coloredLayerMesh.position.setY(shadedLayerMesh.position.y + 0.01);
    const group = new THREE.Group();
    group.name = getGridGroupName(grid.Token);
    group.add(coloredLayerMesh, shadedLayerMesh);
    coloredLayerMesh.renderOrder = -1;
    shadedLayerMesh.renderOrder = -2;

    return group;
};

export const getDisplacementScale = (gridMin : number, gridMax: number, countTimeDepth: number, heightPixel: number) => {
    const heightFactor = countTimeDepth / heightPixel;

    const depthToPixel = Math.abs(gridMax) / heightFactor;
    const yPosition = -(depthToPixel / GeopostThreeJsConstants.yDivisionFactor);

    const minDepthToPixel = Math.abs(gridMin) / heightFactor;
    const minYPosition = -(minDepthToPixel / GeopostThreeJsConstants.yDivisionFactor);
    const displacementScale = Math.abs(minYPosition - yPosition);

    return displacementScale;
};

export const getSLDMainGrayScale = (min: number, max: number, workspace: string, layerName: string) => {
    let sld = '<?xml version=\'1.0\' encoding=\'ISO-8859-1\'?>' +
        '<StyledLayerDescriptor version=\'1.0.0\' ' +
        'xsi:schemaLocation=\'http://www.opengis.net/sld StyledLayerDescriptor.xsd\' ' +
        'xmlns=\'http://www.opengis.net/sld\' ' +
        'xmlns:ogc=\'http://www.opengis.net/ogc\' ' +
        'xmlns:xlink=\'http://www.w3.org/1999/xlink\' ' +
        'xmlns:xsi=\'http://www.w3.org/2001/XMLSchema-instance\'>' +
        '<NamedLayer>' +
        '<Name>' + workspace + ':' + layerName + '</Name>' +
        '<UserStyle>' +
        '<FeatureTypeStyle>' +
        '<Rule>' +
        '<RasterSymbolizer>' +
        '<ColorMap extended=\'true\'>';

    sld += '<ColorMapEntry label=\'' + (Number(min) - 1) + '\' color=\'#FFFFFF\' quantity=\'' + (Number(min) - 1) + '\' opacity= \'0\'/>';
    sld += '<ColorMapEntry label=\'' + (Number(min) - 0) + '\' color=\'#FFFFFF\' quantity=\'' + (Number(min) - 0) + '\' opacity= \'1\'/>';
    sld += '<ColorMapEntry label=\'' + (Number(max) + 0) + '\' color=\'#000000\' quantity=\'' + (Number(max) + 0) + '\' opacity= \'1\'/>';
    sld += '<ColorMapEntry label=\'' + (Number(max) + 1) + '\' color=\'#000000\' quantity=\'' + (Number(max) + 1) + '\' opacity= \'0\'/>';

    sld += '</ColorMap>' +
        '</RasterSymbolizer>' +
        '</Rule>' +
        '</FeatureTypeStyle>' +
        '</UserStyle>' +
        '</NamedLayer>' +
        '</StyledLayerDescriptor>';

    return sld;
};

export const getSLDAlpha = (min: number, max: number, workspace: string, layerName: string, simplifiedGeomWKT: string) => {
    let sld = '<?xml version=\'1.0\' encoding=\'ISO-8859-1\'?>' +
        '<StyledLayerDescriptor version=\'1.0.0\' ' +
        'xsi:schemaLocation=\'http://www.opengis.net/sld StyledLayerDescriptor.xsd\' ' +
        'xmlns=\'http://www.opengis.net/sld\' ' +
        'xmlns:ogc=\'http://www.opengis.net/ogc\' ' +
        'xmlns:xlink=\'http://www.w3.org/1999/xlink\' ' +
        'xmlns:xsi=\'http://www.w3.org/2001/XMLSchema-instance\'>' +
        '<NamedLayer>' +
        '<Name>' + workspace + ':' + layerName + '</Name>' +
        '<UserStyle>' +
        '<FeatureTypeStyle>' +

        `<Transformation>
<ogc:Function name="gs:CropCoverage">
    <ogc:Function name="parameter">
        <ogc:Literal>coverage</ogc:Literal>
    </ogc:Function>
    <ogc:Function name="parameter">
        <ogc:Literal>cropShape</ogc:Literal>
        <ogc:Literal>` + simplifiedGeomWKT + `</ogc:Literal>
    </ogc:Function>
</ogc:Function>
</Transformation>` +
        '<Rule>' +
        '<RasterSymbolizer>' +
        '<ColorMap extended=\'true\'>';

    sld += '<ColorMapEntry label=\'' + (Number(min) - 1) + '\' color=\'#FFFFFF\' quantity=\'' + (Number(min) - 1) + '\' opacity= \'0\'/>';
    sld += '<ColorMapEntry label=\'' + (Number(min) - 0) + '\' color=\'#FFFFFF\' quantity=\'' + (Number(min) - 0) + '\' opacity= \'1\'/>';
    sld += '<ColorMapEntry label=\'' + (Number(max) + 0) + '\' color=\'#FFFFFF\' quantity=\'' + (Number(max) + 0) + '\' opacity= \'1\'/>';
    sld += '<ColorMapEntry label=\'' + (Number(max) + 1) + '\' color=\'#FFFFFF\' quantity=\'' + (Number(max) + 1) + '\' opacity= \'0\'/>';

    sld += '</ColorMap>' +
        '</RasterSymbolizer>' +
        '</Rule>' +
        '</FeatureTypeStyle>' +
        '</UserStyle>' +
        '</NamedLayer>' +
        '</StyledLayerDescriptor>';

    return sld;
};

export const getUrlWMS = (sldHost : string, geoServerProxyHost: string, hostServer: string, workspace: string, layerName: string, srid: number, width: number, height: number, sldToken: string, extent: number[], backgroundColor: string = '', imageFormat : string = 'image/png') => {
    const urlHost = `host=${hostServer}/wms`;
    const definitionService = `&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=${imageFormat}`;
    const layer = `&LAYERS=${workspace}:${layerName}`;
    const definitionPicture = '&TRANSPARENT=true&FORMAT_OPTIONS=antialias:none&STYLES';
    const crs = `&CRS=${srid}`;
    const size = `&WIDTH=${width}&HEIGHT=${height}`;
    const sld = !!sldToken ? `&SLD=${sldHost}/GetSLD?token=${sldToken}` : '';
    const bbox = `&BBOX=${extent[0]},${extent[1]},${extent[2]},${extent[3]}`;
    const background = `&bgColor=${backgroundColor}`;
    const wmsUrl = urlHost + definitionService + layer + definitionPicture + crs + size + sld + bbox + background;
    return wmsUrl;
};

export const checkIfGridHasMesh = (token: string, scene: GeopostScene) => {
    const gridGroupName = getGridGroupName(token);
    const gridGroup = scene.getObjectByName(gridGroupName);
    return !!gridGroup;
};

export const removeGrid = (token: string, scene: GeopostScene) => {
    const gridGroupName = getGridGroupName(token);
    const gridGroup = scene.getObjectByName(gridGroupName);
    if (!!gridGroup) {
        scene.remove(gridGroup);
    }
};

export const checkGridVisibility = (token: string, scene: GeopostScene) => {
    const gridGroupName = getGridGroupName(token);
    const gridGroup = scene.getObjectByName(gridGroupName);
    if (!gridGroup) {
        return false;
    } else {
        return gridGroup.visible;
    }
};

export const changeGridOpacity = (token: string, scene: GeopostScene, opacity: number) => {
    const gridGroupName = getGridGroupName(token);
    const gridGroup = scene.getObjectByName(gridGroupName);
    if (gridGroup) {
        const coloredLayer = gridGroup.getObjectByName('colored');
        if (coloredLayer) {
            ((coloredLayer as THREE.Mesh).material as THREE.Material).opacity = 0.6 * opacity;
        }
        const unColoredLayer = gridGroup.getObjectByName('uncolored');
        if (unColoredLayer) {
            ((unColoredLayer as THREE.Mesh).material as THREE.Material).opacity = opacity;
        }
    }
};

export const changeGridMeshVisbility = (token: string, scene: GeopostScene, visibility: boolean) => {
    const gridGroupName = getGridGroupName(token);
    const gridGroup = scene.getObjectByName(gridGroupName);
    if (gridGroup) {
        gridGroup.visible = visibility;
    }
};